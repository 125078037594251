// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';

import PaystackButton from 'react-paystack';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayAfrica extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_pspKeyPublic : '',
					_pspKeySecret : '',
					_shopName: 'Vadoo vShop',		// Default
					_locale: 'auto',
					_currency: 'NGN',
					_payment: false							// Trigger
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data to retrieve Stripe settings
		if (this._isMounted) {

			// Determine if PSP is Live or using Sandbox
			var APIUrl = process.env.API + '/setting/' + 'INTERNATIONAL-PSP-LIVE';
			MyFetchGet( APIUrl, 10)
					.then( response => response.json() )
					.then( apiDataValue => {
						// console.log('PSP Live ?: ' + apiDataValue);
						if ( apiDataValue == -1 )
							return false;
						else if ( apiDataValue == 1 ) {
							var APIUrl = process.env.API + '/setting/' + 'INTERNATIONAL-PSP-KEY-LIVE';
						}	else {
							var APIUrl = process.env.API + '/setting/' + 'INTERNATIONAL-PSP-KEY-TEST';
						}
						// console.log('PSP Key URL: ' + APIUrl);

						MyFetchGet( APIUrl, 10)
								.then( response => response.json() )
								.then( apiDataValue => {
										// console.log(apiDataValue);
										if (this._isMounted)
											this.setState( { _pspKeyPublic: apiDataValue } );
								})
								.catch( /* Should try again */ );

					})
					.catch( /* Should try again */ );

			// Shop Name -> Email name || running this aSync
			var APIUrl2 = process.env.API + '/setting/' + 'EMAIL-NAME';
			MyFetchGet( APIUrl2, 10)
					.then( response => response.json() )
					.then( apiDataValue => {
							if (this._isMounted)
									this.setState( { _shopName: apiDataValue } );
					})
					.catch( /* Should try again */ );

		}
	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	onPayment (_payment) {

		var _data = {
			payment: _payment,
			email: this.props.userEmail,
			cartID: this.props.cartID,
			amount: parseInt(this.props.cartValue * 500 + this.props.deliveryCharge * 500 + this.props.VATCharge * 500),
			currency: this.state._currency
		};

		MyFetchPost( process.env.API + '/payments/paystack', _data, 1)
			.then( response => response.json() )
			.then( paystack => {
					if ( paystack.response == 1 ) {
						this.setState( { _payment: true } );
					}
	    });
	}

	onClose (_paymentCancelled) {
		// console.log('Cancelled');
	}

	render () {

		{/* _payment is a state flag which triggers <Redirect to PaymentReceived ../>. This is
			set when the user has paid and the onToken() shows a payment status of 1 */}

		if (this.state._payment) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-received/${_amount}`} push />);
		}

		{/* We must have a PSP Key and an eMail address to process */}
		if ( _.isEmpty(this.state._pspKeyPublic) ) return (<div></div>);

		return (
			<div>

				{/* For now convert GBP to Nigerian Naira */}
				<PaystackButton
					text="Pay | Africa"
          className="button large no-select hollow warning large-mobile-button strong-button"
          callback={this.onPayment.bind(this)}
          close={this.onClose.bind(this)}
          disabled={false}
          embed={false}
          reference={this.props.cartID.toString() + '--' + Math.random().toString(36).substring(7)}
          email={this.props.userEmail}
          amount={parseInt(this.props.cartValue * 50000 + this.props.deliveryCharge * 50000 + this.props.VATCharge * 50000)}
          paystackkey={this.state._pspKeyPublic}
					currency={this.state._currency}
          tag="button"
				/>

			</div>
		);
	}

}

// Set the default values
PayAfrica.defaultProps = {
	userEmail: reduxStore.getState().userEmail,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userEmail: state.userEmail,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

export default connect(mapStateToProps)(PayAfrica);
