// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';

import { Link, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


/*
  --- A product box or grid-display item ---
  Props are:
	media			The media object

*/

class MediaDisplay extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_validatedImage: this.props.media.IMAGE || '',
					_clickPlay: false
        };
	}

	componentDidMount () {

		this._isMounted = true;

		//
		//	Quick Validate Primary Image as a loadable image
		//

		var img = new Image();

		if ( !_.isEmpty(this.props.media.IMAGE) ) {

					var scriptPromise = new Promise((resolve, reject) => {

							img.src = this.props.media.IMAGE;
							img.onload = resolve;
							img.onerror = reject;

					});

					scriptPromise.then(() => {
							if (this._isMounted)
								this.setState( { _validatedImage : this.props.media.IMAGE } );
					})
					.catch( () => {
							if (this._isMounted)
								this.setState( { _validatedImage : 'images/no-image.png' } );
					})

		} else {
					if (this._isMounted)
						this.setState( { _validatedImage : 'images/no-image.png' } );
		}

	}

	componentWillUnmount () {
	    // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	playMedia () {
			this.setState( { _clickPlay : true } );
	}

	// -----------------------------------------------------------------------

	render () {

		if (this.state._clickPlay) {
			{/* This is just a page refresh - React JS style */}
			var _playProduct = this.props.media.product_id;
			return (<Redirect to={`/media-play/${_playProduct}`} push />);
		}

		// Oddly keep this condition to avoid any issues
		if ( _.isEmpty(this.props.media) ) return (<div></div>);

		return (

			<div className="grid-x grid-margin-x">

					<div className="cell small-10 small-offset-1 center-image-container">
								<img src={this.state._validatedImage}	className="pointer"
										onClick={evt => this.playMedia(evt)} />
					</div>

					<div className="cell small-12 center">
								<div className="product-name highlight-colour">
									<Translate text={this.props.media.NAME} />
								</div>
					</div>

			</div>
		);
	}

}

// Set the default values
MediaDisplay.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(MediaDisplay);
