// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Allow a user o submit a review ---
	--- For now we are allowing FREE/ALL reviews ---
	user Data from R E D U X
	prop is PRODUCT_ID
*/

class WriteReview extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_stars: 0,
					_review: '',
					_openReview: true
        };
	}

	setStars (rating) {
		this.setState( {_stars : rating} );
	}

	setReview (e) {
		this.setState( {_review : e.target.value} );
	}

	closeReview (e) {
		this.setState( {_openReview : false} );
	}

	postReview () {
		// Up to you, but don't allow empty reviews and no stars
		if ( _.isEmpty(this.state._review) || this.state._stars < 1 ) return false;
		// OK, so post this review and close

		var _data = {
			product_id: this.props.PRODUCT_ID,
			user_id: this.props.userID,
			review: this.state._review,
			stars: this.state._stars
		};
		MyFetchPost( process.env.API + '/product/review/new', _data, 3)
				.then( response => response.json() )
				.then( review => {
					// console.log(review);
					// Close Submit Review
					this.setState( {_openReview : false} );
				});

	}

	render () {

			// Used to close this component
			if (!this.state._openReview) return (<div></div>);

			return(
				<div className="grid-x grid-margin-x">

					<div className="cell small-8 medium-6 large-3">
						<Rating onClick={ (rating) => this.setStars(rating) }
							initialRating={this.state._stars}
							emptySymbol={<img src="images/icon-empty-star.png" />}
						  fullSymbol={<img src="images/icon-gold-star.png" />}
						/>
						<br />
						{this.props.userName}
						<br />
						<em>{this.props.userEmail}</em>
					</div>

					<div className="cell small-12 medium-6 large-9">
						<div className="mobile-dispay"><br /></div>
						<textarea onChange={ evt => this.setReview(evt) }
							placeholder="Please write your review here...">
						</textarea>
						<span className="centered small-text">
							<Translate text="Reviews are respectfully moderated before they are made public" />
						</span>
					</div>

					<div className="cell small-12 text-center">
						<br />
						<button className="button large rounded bordered warning warning-colour"
							onClick={evt => this.postReview(evt)}>
							<Translate text="POST" />
						</button>
						&nbsp;&nbsp;&nbsp;
						<button className="button large rounded bordered secondary"
							onClick={evt => this.closeReview(evt)}>
							<Translate text="CLOSE" />
						</button>
					</div>

				</div>
			)
	}

}

// Set the default values
WriteReview.defaultProps = {
	userID: reduxStore.getState().userID,
  userEmail: reduxStore.getState().userEmail,
	userName: reduxStore.getState().userName,
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userID: state.userID,
			userEmail: state.userEmail,
			userName: state.userName,
    }
}

export default connect(mapStateToProps)(WriteReview);
