// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';
import { translateTooltips } from 'Useful';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';
import PleaseWait from 'PleaseWait';
import IPLog from 'IPLog';

import CartLineDisplay from 'CartLineDisplay';
import ClearCurrentCart from 'ClearCurrentCart';
import PromotionPopup from 'PromotionPopup';
import CouponInfo from 'CouponInfo';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Cart extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	cartData : null,										// Set to null to prevent React Empty message
					_callClearCurrentCart: false,
					_refreshID: this.props.REFRESH_ID,
					_couponCode: '',										// actual validated Coupon
					_tryCoupon: '',											// attempted input
					_promotionPopup: false,							// trigger for Coupon validated Popup
					_popupWindow: 0,										// 1 - Coupon Info, 2 - Coupon Popup
					_pleaseWait: true
        };
				this.getCoupon = this.getCoupon.bind(this);
				this.couponInfo = this.couponInfo.bind(this);
				this.acceptCouponPopup = this.acceptCouponPopup.bind(this);
				this.clearCurrentCart = this.clearCurrentCart.bind(this);
	}

	//
	//	In line with new React V17 and stability, we now use getDerivedStateFromProps () using a state flag
	//	This is a very complex and fiddly example because we have a page which needs to be triggered for
	//	an update but the API Data won't change so in effect we need to force a refresh. Now React
	//	doesn't do that well unless we have a trigger or refresh state element which we can use.
	//	Ah HA ! but how do we do that for a page like this which could be refreshed X number of times
	//	Answer : bring in a random number generator as ID so when this changes, i.e forces a refresh
	// 	we can then initialise or clear our Data and reload it from the API. This works very well
	//


	// Note that this must be static
	static getDerivedStateFromProps(props, state) {
	    // Clear out previously-loaded data (so we don't render stale stuff).
	    if (props.REFRESH_ID !== state._refreshID) {
					return {
		        cartData: null,
		        _refreshID: props.REFRESH_ID,
						_tryCoupon: '',
						_couponCode: ''
		      };
	    }

	    // No state update necessary
	    return null;
	}


	loadAPIData () {

		// Load initial API Data
		if (this._isMounted) {
				var APIUrl = process.env.API + '/cart/get/' + this.props.cartID;
				MyFetchGet(APIUrl, 3)
				    .then( response => response.json() )
				    .then( apiCartData => {
								if (this._isMounted) {
										this.setState( {cartData : apiCartData} );
										// If the cart already has a Coupon then set this flag to Block
										if ( !_.isEmpty(apiCartData.header.coupon) ) {
												this.setState( {_couponCode : apiCartData.header.coupon} );
										}
								}
				    })
						.then( () => {

								// Smooth the flow: If a UN-registered customer views their non-empty cart we will ask them
								// to register and then call settings. We want them to then -> checkout after that...

								if ( this.props.userID <= 0 && !_.isEmpty(this.state.cartData) && this.state.cartData.items.length > 0 ) {
										setReduxValue(reduxStore, 'SET_REDIRECT_TO_CHECKOUT', true);
								    var action1 = {
								        type: 'SET_REDIRECT_TO_CHECKOUT',
								        redirectToCheckout: true
								    };
								    this.props.dispatch(action1);
								}

								// Scroll Top
								window.scrollTo(0, 0);
						})
						.then( () => {
							if ( this._isMounted )
								this.setState( { _pleaseWait: false } );

							this._isMounted = false;		// IMPORTANT !!
						})
						.catch( error => {
							if ( this._isMounted )
								this.setState( { _pleaseWait: false } );
						});

		}

	}

	//
	// 	DidMount() and DidUpdate()
	//	Unfortunately there is no way around this duplication as we need to catch memory leaks
	//


	componentDidMount () {

		// This is for any slider
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		this._isMounted = true;

		this.loadAPIData();

		//
		//		Tooltip Translation
		//

		translateTooltips(this.props.locale);

	}

	//
	//	Yes, I know, a duplicate of the above
	//

	componentDidUpdate () {

		// This is for any slider
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

		this._isMounted = true;

		this.loadAPIData();

		//
		//		Tooltip Translation
		//

		translateTooltips(this.props.locale);

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	clearCurrentCart (evt) {
		this.setState( {_callClearCurrentCart : true} );
	}

	// React style input for an <input.....
	getCoupon (evt) {

		this.setState( {_tryCoupon : evt.target.value} );

		// Test on the fly if this is valid and if so allow the user to confirm
		MyFetchGet(process.env.API + '/coupon/validate/' + evt.target.value, 3)
				.then( response => response.json() )
				.then( coupon => {

					if ( !_.isEmpty(coupon) ) {

							$('#Coupon').addClass('green').removeClass('red');

							// As valid ask User to confirm coupon ?
					    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );

					    var action1 = {
					        type: 'SET_SLIDE_WINDOW',
					        slideWindow: true
					    };

					    // Note that we apply the dispatch to the props: mapStateToProps(state)
							this.props.dispatch(action1);

							this.setState( {_popupWindow : 2 } );

					} else {

							$('#Coupon').addClass('red').removeClass('green');

					}

				})
				.catch( error => {
						$('#Coupon').addClass('red').removeClass('green');
				})

	}

	// Call back from Popup which says an accepted coupon so now Block
	acceptCouponPopup(coupon) {

		console.log( 'Coupon Popup was accepted so now Block' );
		this.setState( {_couponCode : coupon} );

		var APIUrl = process.env.API + '/cart/get/' + this.props.cartID;
		MyFetchGet(APIUrl, 3)
				.then( response => response.json() )
				.then( cart => {

					// Items in cart
					setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', (cart.items).length );
					var action1 = {
							type: 'SET_ITEMS_IN_CART',
							itemsInCart: (cart.items).length
					};
					// Value of cart
					var _cartTotal = 0.00;

					{cart.items.map( (item, key) => {
							_cartTotal += (item.quantity_ordered) * (item.unit_price);
					})}

					setReduxValue(reduxStore, 'SET_CART_VALUE', _cartTotal );
					var action2 = {
							type: 'SET_CART_VALUE',
							cartValue: _cartTotal
					};
					// Note that we apply the dispatch to the props: mapStateToProps(state)
					this.props.dispatch(action1);
					this.props.dispatch(action2);

				})

	}

	couponInfo(evt) {

		// Activate an Coupon Info
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: true
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		this.setState( {_popupWindow : 1 } );

	}

	render () {

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		return (
			<div className="flex-site">
				<IPLog URL="/#/cart" />

				<div className="site-content">
					<Nav />
					<MegaMenu />
					<ProductSearch />

					<div className="page-title text-center no-select">
						<FontAwesomeIcon icon="cart-arrow-down" size="1x" color="grey" /> &nbsp;
						<Translate text="Shopping Cart" />
					</div>

					{/* Show current shopping cart */}
					<div className="grid-x grid-margin-x">

						{/* Valid cart with some products */}

						{ !_.isEmpty(this.state.cartData) && this.state.cartData.items.map( (item, key) => {

							if ( _.isEmpty(item) ) return (null);

							return(
								<div key={key} className="cell small-12">
					    		<CartLineDisplay lineItem={item} />;
					    	</div>
							)

						})}

						{/* No cart in play so prompt user how to BUY !! */}

						{ this.props.cartID == 0 &&
							<div className="cell small-10 small-offset-1 center message-alert">
								<Translate text="Click a product" />
								&nbsp;&nbsp;&nbsp;
								<button className="button large rounded bordered warning warning-colour large-mobile-button">
									<Translate text="BUY" />
								</button>
								&nbsp;&nbsp;&nbsp; button
								<div className="grey center">
									<Translate text="Hover your mouse over - or tap - the top product categories and select one" />
								</div>
							</div> }

						{/* Valid cart, but empty which can happen - just say so... */}

						{ this.props.cartID > 0 && this.state.cartData !== null && _.isEmpty(this.state.cartData.items) &&
							<div className="cell small-10 small-offset-1 center message-alert">
								<div className="red center">
										<Translate text="OK. Active shopping cart, but you have emptied it" />
								</div>
							</div> }

					</div>

				</div>

				{/* Total and Options */}

				{/* Creates a small line break [ Mobile UI ] */}
				<div className="cell small-12 small-text mobile-display">&nbsp;</div>

				<div className="grid-x grid-margin-x">

					{/*

							C O U P O N S  which was a major UI/UX project for our beloved web designers !

					*/}

					<div className="cell small-7 small-offset-1 medium-4 medium-offset-1 large-3 large-offset-1">

								{/* Valid Cart - no formal Coupon applied or established */}

								{ this.props.__allowCoupons == 1
									&& _.isEmpty(this.state._couponCode)
									&& !_.isEmpty(this.state.cartData)
									&& this.state.cartData.items.length > 0 &&

												<div className="input-group">

													<FontAwesomeIcon icon="question-circle" size="2x" color="grey" className="protip pointer"
														data-pt-position="left"	data-pt-scheme="black" data-pt-classes="my-tooltip"
														data-pt-title="Coupon Information" data-pt-auto-hide="2000"
														onClick={ evt => this.couponInfo(evt) }
													/> &nbsp;

													<span className="input-group-label normal-text red bold">
														<Translate text="Coupon ?" />
													</span>

													<input id="Coupon" className="input-group-field cart-line cart-border"
														style={{ textTransform: 'uppercase' }}
														onChange={ evt => this.getCoupon(evt) }
														placeholder="...will popup"
														defaultValue={this.state._tryCoupon}
													 />

												</div>

								 }

									{/* Valid Cart - Coupon already applied so now Blocked - one coupon per cart */}

									{ this.props.__allowCoupons == 1
										&& !_.isEmpty(this.state._couponCode)
										&& !_.isEmpty(this.state.cartData)
										&& this.state.cartData.items.length > 0 &&

													<div className="input-group">

														<span className="input-group-label white-background">
															<Translate text="Coupon" />
														</span>

														<input id="Coupon" className="input-group-field cart-line cart-border"
															style={{ textTransform: 'uppercase' }}
															defaultValue={this.state._couponCode}
															readOnly
															/>

														&nbsp; &nbsp;
														<FontAwesomeIcon icon="check" size="2x" color="lime" />

													</div>

										}

							</div>

							{/* Empty Cart ! */}

							<div className="cell small-4 medium-2 large-2 text-center">
								{ !_.isEmpty(this.state.cartData) && this.state.cartData.items.length > 0 &&
								<button className="no-select hollow button secondary"
									onClick={evt => this.clearCurrentCart(evt)}>
									<Translate text="Empty Cart" />
								</button> }
							</div>

							<div className="mobile-display cell small-12">&nbsp;</div>

							<div className="cell small-5 small-offset-1 medium-2 medium-offset-0 large-2 large-offset-0 text-center">

							{/* Checkout - Conditional on active User */}

							{ this.props.userID > 0 && !_.isEmpty(this.state.cartData) && this.state.cartData.items.length > 0 &&
								<Link to="/checkout">
									<button className="no-select hollow button large large-mobile-button strong-button alert">
										<Translate text="Check Out" />
									</button>
								</Link> }

							{/* Checkout -> Register as NO active User*/}

							{ this.props.userID <= 0 && !_.isEmpty(this.state.cartData) && this.state.cartData.items.length > 0 &&
								<Link to="/register">
									<button className="no-select hollow button large large-mobile-button strong-button alert">
										<Translate text="Check Out" />
									</button>
									<div className="small-text black bold">
										<Translate text="Register | Login" />
									</div>
								</Link> }

					</div>

					{ !_.isEmpty(this.state.cartData) && this.state.cartData.items.length > 0 &&
							<div className="desktop-display cell medium-1 large-1 text-center">
								<div className="cart-line">
									<Translate text="TOTAL" />
								</div>
							</div> }

					{ !_.isEmpty(this.state.cartData) && this.state.cartData.items.length > 0 &&
							<div className="cell small-5 medium-2 large-2">

								<div className="larger-text cart-line cart-border highlight-colour no-wrap">
									{ this.props.__currencySymbol + ' ' +  Intl.NumberFormat('en-US', { minimumFractionDigits:2 }).format(this.props.cartValue) }
								</div>

							</div> }

				</div>

				{/* _callAddToCart is a state flag which triggers <ClearCart ../>. This is
					set when the user clicks the above clearCurrentCart() onClick */}

				{this.state._callClearCurrentCart && <ClearCurrentCart />}

				<div id="CouponInfo" className="grid-x grid-margin-x">
						<div className="cell small-10 small-offset-1">
		            {/* Conditional on active SlideWindow */}
		            {this.props.slideWindow && this.state._popupWindow == 1 && <CouponInfo />}
          	</div>
				</div>

				<div id="CouponApply" className="grid-x grid-margin-x">
						<div className="cell small-10 small-offset-1">
								{ this.props.slideWindow && this.state._popupWindow == 2 &&
									<PromotionPopup
										coupon={this.state._tryCoupon}
										onAccept={this.acceptCouponPopup}
									/> }

						</div>
				</div>

				<br /><br />

				<Footer />
				<ScrollTop />
			</div>
		);
	}

}

// Set the default values
Cart.defaultProps = {
	__currencySymbol: reduxStore.getState().__currencySymbol,
	__allowCoupons: reduxStore.getState().__allowCoupons,
	locale: reduxStore.getState().locale,
	userID: reduxStore.getState().userID,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue,
	itemsInCart: reduxStore.getState().itemsInCart,
	redirectToCheckout: reduxStore.getState().redirectToCheckout,
  slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			__currencySymbol: state.__currencySymbol,
			__allowCoupons: state.__allowCoupons,
			locale: state.locale,
			userID: state.userID,
			cartID: state.cartID,
			cartValue: state.cartValue,
			itemsInCart: state.itemsInCart,
			redirectToCheckout: state.redirectToCheckout,
			slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(Cart);
