// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import ReactGA from 'react-ga';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*

	This Component called in app.jsx will always be loaded on a vShop load - as obviously
	app.jsx is always loaded in any init - regardless of the URL used.

	This is a good place to establish any environment and R E D U X is the winner here.

*/

class VShopBootStrap extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
						// None required so far....
        };
	}

	componentDidMount() {

		this._isMounted = true;

		// I'm loading these aSync...
		// 1.
		MyFetchGet(process.env.API + '/setting/' + 'FRONT-END-DISPLAY-IMAGE-ABOVE', 5)
        .then( response => response.json() )
        .then( apiDataValue => {

						// Establish this FRONT-END-DISPLAY-IMAGE-ABOVE in R E D U X
						setReduxValue(reduxStore, 'INIT_FRONT-END-DISPLAY-IMAGE-ABOVE', apiDataValue );
						var action1 = {
				        type: 'INIT_FRONT-END-DISPLAY-IMAGE-ABOVE',
				        __preferProductImagesAbove: apiDataValue
				    };

						this.props.dispatch(action1);

        })
        .catch( /* It should retry !! */ )

			// 2.
			MyFetchGet(process.env.API + '/setting/' + 'NET-VAT', 5)
	        .then( response => response.json() )
	        .then( apiDataValue => {

							if ( apiDataValue == 0 ) {

									// No VAT in R E D U X
									setReduxValue(reduxStore, 'INIT_VAT-RATE', 0 );
									var action2 = {
							        type: 'INIT_VAT-RATE',
							        __VATrate: 0
							    };

									this.props.dispatch(action2);

							} else {

									MyFetchGet(process.env.API + '/setting/' + 'VAT-RATE', 5)
							        .then( response => response.json() )
							        .then( apiDataValue => {

														// Yes VAT in R E D U X
														setReduxValue(reduxStore, 'INIT_VAT-RATE', apiDataValue );
														var action2 = {
												        type: 'INIT_VAT-RATE',
												        __VATrate: apiDataValue
												    };

														this.props.dispatch(action2);

											})
											.catch( /* It should retry !! */ )
							}

	        })
	        .catch( /* It should retry !! */ )

			// 3. The Delivery System
			MyFetchGet(process.env.API + '/setting/' + 'PRICE-DELIVERY-MATRIX', 5)
	        .then( response => response.json() );


			// 4a. Currency Code
			MyFetchGet(process.env.API + '/setting/' + 'CURRENCY-CODE', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

								// Yes Currency Code in R E D U X
								setReduxValue(reduxStore, 'SET_CURRENCY-CODE', apiDataValue );
								var action4a = {
										type: 'SET_CURRENCY-CODE',
										__currencyCode: apiDataValue
								};

								this.props.dispatch(action4a);

					})
					.catch( /* It should retry !! */ )

			// 4b. Currency Symbol
			MyFetchGet(process.env.API + '/setting/' + 'CURRENCY-SYMBOL', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

								// Yes Currency Symbol in R E D U X
								setReduxValue(reduxStore, 'SET_CURRENCY-SYMBOL', apiDataValue );
								var action4b = {
										type: 'SET_CURRENCY-SYMBOL',
										__currencySymbol: apiDataValue
								};

								this.props.dispatch(action4b);

					})
					.catch( /* It should retry !! */ )

			// 5. Media Store
			MyFetchGet(process.env.API + '/setting/' + 'MEDIA-STORE-ENABLE', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

								// Yes Currency Symbol in R E D U X
								setReduxValue(reduxStore, 'SET_MEDIA-STORE', apiDataValue );
								var action5 = {
										type: 'SET_MEDIA-STORE',
										__mediaStore: apiDataValue
								};

								this.props.dispatch(action5);

					})
					.catch( /* It should retry !! */ )

			// 6a. Multi Language
			MyFetchGet(process.env.API + '/setting/' + 'YANDEX-TRANSLATE', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

								// Yes Translate in R E D U X
								setReduxValue(reduxStore, 'SET_MULTI-LANGUAGE', apiDataValue );
								var action6a = {
										type: 'SET_MULTI-LANGUAGE',
										__multiLanguage: apiDataValue
								};

								this.props.dispatch(action6a);

					})
					.catch( /* It should retry !! */ )

			// 6b. Translate API KEY
			MyFetchGet(process.env.API + '/setting/' + 'YANDEX-TRANSLATE-KEY', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

								// Yes API Key in R E D U X
								setReduxValue(reduxStore, 'SET_TRANSLATE-API-KEY', apiDataValue );
								var action6b = {
										type: 'SET_TRANSLATE-API-KEY',
										__translateAPIKey: apiDataValue
								};

								this.props.dispatch(action6b);

					})
					.catch( /* It should retry !! */ )

			// 7. Check user locale
			if ( _.isEmpty(this.props.locale) ) {

				// console.log( 'Locale is Empty - fix !');
				setReduxValue(reduxStore, 'SET_LOCALE', 'en' );
				var action7 = {
						type: 'SET_LOCALE',
						locale: 'en'
				};

				this.props.dispatch(action7);

			}

			// 8. Popups
			MyFetchGet(process.env.API + '/setting/' + 'HOME-PAGE-POPUP', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

							// Check that the user has NOT alredy switched off
							// console.log( 'Site popups set to ' + apiDataValue );
							// console.log( 'REDUX popups set to ' + this.props.showPopup );
							if ( this.props.showPopup != 0 ) {
								// console.log( 'Change REDUX value for Popup to ' + apiDataValue );
								// Yes Popup in R E D U X
								setReduxValue(reduxStore, 'SET_POPUP', apiDataValue );
								var action8 = {
										type: 'SET_POPUP',
										showPopup: apiDataValue
								};

								this.props.dispatch(action8);
							} else {
								// console.log( 'Popups have already been disabled' );
							}

					})
					.catch( /* It should retry !! */ )

			// 10. Allow Coupons
			MyFetchGet(process.env.API + '/setting/' + 'ALLOW-COUPONS', 5)
	        .then( response => response.json() )
					.then( apiDataValue => {

								// Yes to Coupons in R E D U X
								setReduxValue(reduxStore, 'SET_ALLOW-COUPONS', apiDataValue );
								var action10 = {
										type: 'SET_ALLOW-COUPONS',
										__allowCoupons: apiDataValue
								};

								this.props.dispatch(action10);

					})
					.catch( /* It should retry !! */ )

				// 11. Error Redux mismatch
				// a. Check User ID is valid - a user can be deleted at the Dashboard level
				if ( this.props.userID > 0 ) {
					// console.log( 'Test for valid User ' + this.props.userID );
					MyFetchGet(process.env.API + '/customer/get/' + this.props.userID, 5)
			        .then( response => response.json() )
							.then( apiDataValue => {

										if ( _.isEmpty(apiDataValue) ) {
												// We have a data mismatch !
												setReduxValue(reduxStore, 'SET_USER_ID', 0);
												setReduxValue(reduxStore, 'SET_USER_EMAIL', '');
												setReduxValue(reduxStore, 'SET_USER_NAME', '');
												setReduxValue(reduxStore, 'SET_USER_APPROVED', false);

												var action11a = {
														type: 'SET_USER_ID',
														userID: 0
												};
												var action11b = {
														type: 'SET_USER_NAME',
														userName: ''
												};
												var action11c = {
														type: 'SET_USER_EMAIL',
														userEmail: ''
												};
												var action11d = {
														type: 'SET_USER_APPROVED',
														userApproved: false
												};

												this.props.dispatch(action11a);
												this.props.dispatch(action11b);
												this.props.dispatch(action11c);
												this.props.dispatch(action11d);
										}

							})
							.catch( /* It should retry !! */ )
				}

				// b. Check User Cart is valid - a cart can be removed at the Dashboard level
				if ( this.props.cartID > 0 ) {
					// console.log( 'Test for valid Cart ' + this.props.cartID );
					MyFetchGet(process.env.API + '/cart/get/' + this.props.cartID, 5)
			        .then( response => response.json() )
							.then( apiDataValue => {

										if ( _.isEmpty(apiDataValue) ) {
												// We have a data mismatch !
												setReduxValue(reduxStore, 'SET_CART_ID', 0);
												setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', 0);
												setReduxValue(reduxStore, 'SET_CART_VALUE', 0.00);

												var action11d = {
														type: 'SET_CART_ID',
														cartID: 0
												};
												var action11e = {
														type: 'SET_ITEMS_IN_CART',
														itemsInCart: 0
												};
												var action11f = {
														type: 'SET_CART_VALUE',
														cartValue: 0.00
												};

												this.props.dispatch(action11d);
												this.props.dispatch(action11e);
												this.props.dispatch(action11f);
										} else {

												// Test that items and Cart Total Match in case of Dashboard adjustment
												// or possible Redux Local Storage errors - can happen

												var APIUrl = process.env.API + '/cart/get/' + this.props.cartID;
												MyFetchGet(APIUrl, 3)
												    .then( response => response.json() )
												    .then( cart => {

																	// console.log( 'Validate Existing Cart on bootstrap...' );
																	// console.log(cart);

																	if ( !_.isEmpty(cart.items) ) {

																			// Items in cart
																			setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', (cart.items).length );
																			var action11g = {
																					type: 'SET_ITEMS_IN_CART',
																					itemsInCart: (cart.items).length
																			};
																			this.props.dispatch(action11g);

																			var _cartTotal = 0.00;

																			{cart.items.map( (item, key) => {
																					_cartTotal += (item.quantity_ordered) * (item.unit_price);
																			})}

																			setReduxValue(reduxStore, 'SET_CART_VALUE', _cartTotal );
																			var action11h = {
																					type: 'SET_CART_VALUE',
																					cartValue: _cartTotal
																			};
																			this.props.dispatch(action11h);

																	}

												    })

										}

							})
							.catch( /* It should retry !! */ )
				}

				// 12. Show Product Matches by Default
				MyFetchGet(process.env.API + '/setting/' + 'SHOW-MATCHED-PRODUCTS', 5)
		        .then( response => response.json() )
						.then( apiDataValue => {

									// Yes to Coupons in R E D U X
									setReduxValue(reduxStore, 'SET_SHOW-PRODUCT-MATCHES', apiDataValue );
									var action12 = {
											type: 'SET_SHOW-PRODUCT-MATCHES',
											__showProductMatches: apiDataValue
									};

									this.props.dispatch(action12);

						})
						.catch( /* It should retry !! */ )


				// 13. Optional Google Analytics for home page landing
				MyFetchGet(process.env.API + '/setting/' + 'GOOGLE-ANALYTICS-UA', 5)
		        .then( response => response.json() )
						.then( ua_init_code => {

								if ( !_.isEmpty(ua_init_code) ) {
										ReactGA.initialize(ua_init_code, {debug: true});
										ReactGA.pageview( '/#/' );
										/*
												GA isn't great with React because of its local DOM router although
												there are solutions to this, but as there is an internal IP log
												system, I have put this on hold until a user really demands this
												system above and beyond the IPLog which works pretty well.

												IPLog exists on all the important pages, so there is a full history
												on the backend dashboard if you really want to explore what a user
												has been looking at and frankly a bit easier than GA - in my opinion.

												In summary, explore react-ga and its use with ReactRouter v4 if you want this.

												I am just using the basic show the pageview on the root, which is weak,
												but only a GA fan or a real analytics freak would want this and if they do
												then that could/would merit a custom job as somewhat esoteric for the average
												vShop user = NO demand.
										*/
								}

						})
						.catch( /* It should retry !! */ )

				// 14. Invitation ONLY Shop i.e. no prices until approved. This is a bit secret shop
				// in my humble opinion, but has its place for sister TRADE-ONLY or `wholesale` sites

				MyFetchGet(process.env.API + '/setting/' + 'INVITATION-ONLY-SHOP', 5)
		        .then( response => response.json() )
						.then( apiDataValue => {

									// Yes to Invitation Only in R E D U X
									setReduxValue(reduxStore, 'SET_INVITATION-ONLY', apiDataValue );
									var action14 = {
											type: 'SET_INVITATION-ONLY',
											__invitationOnly: apiDataValue
									};

									this.props.dispatch(action14);

						})
						.catch( /* It should retry !! */ )

				// 15. Useful and Optimal to know the System Product Price Key; This
				// almost never changes after an install

				MyFetchGet(process.env.API + '/setting/' + 'PRODUCT-PRICE-KEY', 5)
		        .then( response => response.json() )
						.then( apiDataValue => {

									// Product Price Key in R E D U X
									setReduxValue(reduxStore, 'SET_PRODUCT-PRICE-KEY', apiDataValue );
									var action15 = {
											type: 'SET_PRODUCT-PRICE-KEY',
											__productPriceKey: apiDataValue
									};

									this.props.dispatch(action15);

						})
						.catch( /* It should retry !! */ )


	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {
		return(<div></div>);
	}

}

// Set the default values
VShopBootStrap.defaultProps = {
	  locale: reduxStore.getState().locale,
		localeAlt: reduxStore.getState().localeAlt,
		userID: reduxStore.getState().userID,
	  cartID: reduxStore.getState().cartID,
		cartValue: reduxStore.getState().cartValue,
		itemsInCart: reduxStore.getState().itemsInCart,
		showPopup: reduxStore.getState().showPopup
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        locale: state.locale,
				localeAlt: state.localeAlt,
				userID: state.userID,
				cartID: state.cartID,
				cartValue: state.cartValue,
				itemsInCart: state.itemsInCart,
        showPopup: state.showPopup
    }
}

export default connect(mapStateToProps)(VShopBootStrap);
