// Use ES6 Class

import React from 'react';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class IPLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount () {

    this._isMounted = true;

		if (this._isMounted) {

      var _data = {
        URL: this.props.URL,
        customer_id: this.props.userID
      };
  		MyFetchPost( process.env.API + '/live/postclientip', _data, 3)
  		    .then( response => response.json() )
  		    .then( ipData => {
              // console.log(ipData);
  		    })
          .then( () => {
              this._isMounted = false;
          })
  				.catch( /* It should retry !! */ )

		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }



  render() {
      return (<div></div>);
  }
}

// Set the default values
IPLog.defaultProps = {
  userID: reduxStore.getState().userID
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        userID: state.userID
    }
}

export default connect(mapStateToProps)(IPLog);
