import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';
import { translateTooltips } from 'Useful';
import SelectLocale from 'SelectLocale';
import CMSImage from 'CMSImage';

import NavMenu from 'NavMenu';
import BelowNav from 'BelowNav';
import NavMenuMobile from 'NavMenuMobile';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Nav extends React.Component {

  constructor(props, defaultProps) {
    super(props, defaultProps);
    this.state = {
        NavBarLogoMediaURL: null,
        _logoClickCMS: '/',
        _hamburgerClicked: false,
        _selectLocale: false,
        _redirectToCart: false
    };
	}

  componentDidMount () {

    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

    this._isMounted = true;

    // Optional Nav Bar Logo

    MyFetchGet( process.env.API + '/setting/' + 'NAV-BAR-LOGO', 3)
        .then( response => response.json() )
        .then( apiDataValue => {
          if (this._isMounted)
            this.setState( { NavBarLogoMediaURL: apiDataValue } );
        })
        .then( () => {

          MyFetchGet( process.env.API + '/setting/' + 'NAV-BAR-LOGO-GOES-TO', 3)
              .then( response => response.json() )
              .then( apiDataValue => {
                if (this._isMounted && !_.isEmpty(apiDataValue) )
                  this.setState( { _logoClickCMS: apiDataValue } );
              })
              .catch( )

        })
        .catch( );

    //
    //    aSync Tooltip translation
    //

    translateTooltips(this.props.locale);

  }

  componentWillUnmount () {

    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

  hamburgerClicked (evt) {
    this.setState( { _hamburgerClicked: true } );
  }

  hamburgerMenuClose (evt) {
    this.setState( { _hamburgerClicked: false } );
  }

  selectLocale ( evt ) {

		setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );
		var action1 = {
				type: 'SET_SLIDE_WINDOW',
				slideWindow: true
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

    if (this._isMounted)
      this.setState( { _selectLocale: true } );

	}

  goToCart (evt) {
    console.log('Redirect to cart...');
    this.setState( { _redirectToCart: true } );
  }


	render () {

    if (this.state._redirectToCart) {
			return (<Redirect to={`/cart`} push />);
		}

		return (
      <div>

        <BelowNav />

  		  <div className="top-bar">

  				<div className="top-bar-left">

            {/* Mobile Hamburger */}

            <FontAwesomeIcon icon="bars" size="2x" color="grey"
              className="mobile-display mobile-hamburger" onClick={evt => this.hamburgerClicked(evt)} />

            {/*
              -----------------------------------------
              Left Hand Side of Menu or Top Row Mobile
              -----------------------------------------
            */}

  					<ul id="LHSMenu" className="menu">

              {/* Company Logo Button | Icon -> URL as defined in API settings */}
  						{ !_.isEmpty(this.state.NavBarLogoMediaURL) && <li>
  							<a href={this.state._logoClickCMS} target="_self">

                  <CMSImage imageName={this.state.NavBarLogoMediaURL}
                    className="mobile-display tablet-display desktop-display corner-left-logo protip"
                    data-pt-position="right" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="About Us" />

                </a>
  						</li> }

              {/* Store Home Button */}
  						<li>
  							<Link to="/home">
                  <button className="not-tablet not-mobile no-select hollow button secondary">
                    <Translate text="Home" />
                  </button>
                  <FontAwesomeIcon icon="home" size="2x" color="black"
                    className="mobile-display small-tablet-display tablet-display nudge-down-1 protip"
                    data-pt-position="right" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="Home page" />
                </Link>
  						</li>

              {/* Media Store Play Icon - Conditional on active User */}
              { this.props.userID > 0 && this.props.__mediaStore == 1 &&
                <li>
    							<Link to="/media-store">

    								<FontAwesomeIcon icon="play-circle" size="2x" color="red"
    								className="nudge-down-1 no-select protip" data-pt-position="bottom"
                    data-pt-scheme="black" data-pt-classes="my-tooltip"  data-pt-auto-hide="2000"
                    data-pt-title="Media Store" />

      						</Link>
    						</li> }

  					</ul>

  				</div>

  				<div className="top-bar-right">

            {/*
              -----------------------------------------
              Right Hand Side of Menu or Lower Row Mobile
              -----------------------------------------
            */}

  					<ul id="RHSMenu" className="menu">

              {/* User Settings - Conditional on active User */}
              { this.props.userID > 0 &&
              <li className="nav-adjust-mobile">
  							<Link to="/mysettings">
                  <button className="desktop-display not-tablet hollow button secondary no-select protip"
                    data-pt-position="left" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="Update Settings">
                    { _.isEmpty(this.props.userName) && 'Your Profile' }
                    { !_.isEmpty(this.props.userName) && this.props.userName.length < 20 && this.props.userName }
                    { this.props.userName.length >= 20 && this.props.userName.substring(0,20) + '...' }
                  </button>
                  <FontAwesomeIcon icon="user-cog" size="2x" color="deeppink"
                    className="tablet-display small-tablet-display mobile-display nudge-down-1 protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="Your Profile" />
                </Link>
  						</li> }


              {/* Order History - Conditional on active User */}
              { this.props.userID > 0 &&
              <li className="nav-adjust-mobile">
  							<Link to="/myorders">
                  <button className="desktop-display not-tablet hollow button secondary no-select protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="Show List of Orders">
                    <Translate text="History" />
                  </button>
                  <FontAwesomeIcon icon="folder-open" size="2x" color="darkorange"
                    className="tablet-display small-tablet-display mobile-display nudge-down-1 protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="Order History" />
                </Link>
  						</li> }


              {/* Logout Button - Conditional on active User can use nav-adjust-mobile */}
              { this.props.userID > 0 &&
              <li className="nav-adjust-mobile">
  							<Link to="/logout">
                  <button className="desktop-display not-tablet hollow button secondary no-select">
                    <Translate text="Log Out" />
                  </button>
                  <FontAwesomeIcon icon="sign-out-alt" size="2x" color="black"
                    className="tablet-display small-tablet-display mobile-display nudge-down-1 protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-auto-hide="2000" data-pt-title="Sign Out" />
                </Link>
  						</li> }


              {/* Register Button - Conditional on NO active User can use nav-adjust-mobile */}
              { this.props.userID <= 0 &&
  						<li className="">
  							<Link to="/register">
                  <button className="hollow button secondary no-select">
                    <Translate text="Register" />
                  </button>
                  {/* <FontAwesomeIcon icon="user-plus" size="2x" color="darkorange"
                    className="not-desktop nudge-down-1 protip" data-pt-position="bottom"
                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Register" /> */}
                </Link>
  						</li> }


              {/* Login Button - Conditional on NO active User can use nav-adjust-mobile */}
              { this.props.userID <= 0 &&
    						<li className="">
    							<Link to="/login">
                    <button className="hollow button secondary no-select">
                      <Translate text="Login" />
                    </button>
                    {/* <FontAwesomeIcon icon="sign-in-alt" size="2x" color="black"
                      className="not-desktop nudge-down-1 protip" data-pt-position="bottom"
                      data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Login" /> */}
                  </Link>
    						</li> }


              {/* Favourites - Conditional on active User */}
              { this.props.userID > 0 &&
                <li>
    							<Link to="/favourites">

    								<FontAwesomeIcon icon="heart" size="2x" color="darkred"
    								className="nudge-down-1 no-select protip " data-pt-position="bottom"
                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-auto-hide="2000"
                    data-pt-title="My Favourties" />

      						</Link>
    						</li> }


              {/* Cart Icon -> Shopping Cart List */}
  						<li className="icon-list-item">
  							<Link to="/cart">
    								<FontAwesomeIcon icon="shopping-cart" size="2x"
    								className="nudge-down-1 no-select protip highlight-colour" data-pt-position="bottom"
                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-auto-hide="2000"
                    data-pt-title="View shopping cart" />
  							</Link>
  						</li>


              {/* Desktop Only -> No of items in Shopping Cart */}
              <li className="desktop-display not-tablet no-select protip"
                  onClick={evt => this.goToCart(evt)}
                  data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                  data-pt-auto-hide="2000" data-pt-title="Product lines in cart">
                    <span className="badge secondary">{this.props.itemsInCart}</span>
              </li>


              {/* Desktop Only -> Value of Shopping Cart */}
  						<li className="nav-bar-cart-total no-select desktop-display not-tablet"
                onClick={evt => this.goToCart(evt)}>
                &nbsp;&nbsp;
                { !_.isEmpty(this.props.__currencySymbol) && this.props.__currencySymbol }
                {Intl.NumberFormat('en-US', { minimumFractionDigits:2 }).format(this.props.cartValue)}
                &nbsp;&nbsp;
              </li>


              {/* Multi Language Only -> locale code as a badge */}
              { this.props.__multiLanguage == 1 && <li onClick={evt => this.selectLocale(evt)}>
                <span className="mobile-display">&nbsp;&nbsp;</span>
                <span className="badge pointer protip" data-pt-position="left" data-pt-scheme="black"
                  data-pt-classes="my-tooltip" data-pt-auto-hide="2000" data-pt-title="Language">
                  { this.props.locale }
                </span>
              </li> }

  					</ul>

  				</div>

  			</div>

        <NavMenu />

        { this.state._hamburgerClicked &&
          <NavMenuMobile onClose={this.hamburgerMenuClose.bind(this)} /> }

        { this.state._selectLocale && this.props.slideWindow && <SelectLocale />}

      </div>

		);
	}

}

// Set the default values = current Redux state values
Nav.defaultProps = {
  __multiLanguage: reduxStore.getState().__multiLanguage,
  __currencySymbol: reduxStore.getState().__currencySymbol,
  __mediaStore: reduxStore.getState().__mediaStore,
  slideWindow: reduxStore.getState().slideWindow,
  locale: reduxStore.getState().locale,
	itemsInCart: reduxStore.getState().itemsInCart,
	cartValue: reduxStore.getState().cartValue,
  userID: reduxStore.getState().userID,
  userName: reduxStore.getState().userName,
  cartID: reduxStore.getState().cartID
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
  			__multiLanguage: state.__multiLanguage,
  			__currencySymbol: state.__currencySymbol,
  			__mediaStore: state.__mediaStore,
        slideWindow: state.slideWindow,
        locale: state.locale,
        itemsInCart: state.itemsInCart,
        cartValue: state.cartValue,
        userID: state.userID,
        userName: state.userName,
        cartID: state.cartID
    }
}

export default connect(mapStateToProps)(Nav);
