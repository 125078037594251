// Use ES6 Class

import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';

import FeaturedProducts from 'FeaturedProducts';
import ProductList from 'ProductList';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';

import MySelect from 'MySelect';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class CustomPageBlocks extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {

      };

  }

	componentDidMount() {

		// Scroll Top after render()
	  window.scrollTo(0, 0)

	}

	render () {
		return (
			<div className="flex-site">
        <div className="site-content">

						<Nav />
						<MegaMenu />
						<ProductSearch />

							{/* Bespoke eBooks Page */}

						    		{ this.props.pagename == 'Ebooks' &&
												(
													<div className="grid-x grid-margin-x" >

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														<div className="cell small-12">
																<CMSBlock blockTitle="Ebooks-Text" className="flow-text" />
														</div>

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														{/* Maiden */}
														<div className="cell small-8 small-offset-2 medium-3 medium-offset-2">
															<a target="_blank" href="https://www.amazon.co.uk/Maiden-Tracy-Edwards-ebook/dp/B01AHOFWQK/ref=sr_1_3?dchild=1&keywords=tracy+edwards+maiden&qid=1592472790&sr=8-3">
																<CMSImage imageName="Products/Historic/EBOOKS/maiden-ebook-cover.jpg"
																	className="expand-hover"/>
															</a>

																<br />
																<CMSBlock blockTitle="Maiden-Ebook" className="" />

														</div>

														{/* Force Blank lines Mobile Only */}
														<div className="cell small-12 mobile-display"><br />&nbsp;</div>

														{/* Maiden Over */}
														<div className="cell small-8 small-offset-2 medium-3 medium-offset-2">
															<a target="_blank" href="https://www.amazon.co.uk/Maiden-Over-Tracy-Edwards-ebook/dp/B01IHVRFJC/ref=sr_1_2?dchild=1&keywords=tracy+edwards+maiden&qid=1592472829&sr=8-2">
															<CMSImage imageName="Products/Historic/EBOOKS/maiden-over-ebook-cover.jpg"
																className="expand-hover"/>
															</a>
																<br />
																<CMSBlock blockTitle="Maiden-Over-Ebook" className="flow-text" />
														</div>

														{/* Force Blank lines */}
														<div className="cell small-12"><br />&nbsp;</div>

															{/* Film */}

															<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-6 large-offset-3">
																	<CMSBlock blockTitle="Maiden-Film-Info" className="" />
															</div>

															<div className="cell small-8 small-offset-2 medium-3 medium-offset-2">
																<a target="_blank" href="https://bit.ly/MaidenSPC">
																	<CMSImage imageName="Home/maiden-film-us.png"
																		className="expand-hover"/>
																</a>

																	<br />
																	<h4 className="center">USA</h4>

															</div>

															{/* Force Blank lines Mobile Only */}
															<div className="cell small-12 mobile-display"><br />&nbsp;</div>


																<div className="cell small-8 small-offset-2 medium-3 medium-offset-2">
																	<a target="_blank" href="https://www.maiden.film/">
																	<CMSImage imageName="Home/maiden-film-uk.png"
																		className="expand-hover"/>
																	</a>
																		<br />
																		<h4 className="center">UK</h4>
																</div>

															<div className="cell small-12"><br />&nbsp;</div>

													</div>
												)
										}


							{/* Bespoke Auction Page */}

						    		{ this.props.pagename == 'Auction' &&
												(
													<div className="grid-x grid-margin-x" >

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														<div className="cell small-12">
																<CMSBlock blockTitle="Auction-Text" className="flow-text" />
														</div>

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														{/* Auction Products - see above */}
														<div className="cell small-10 small-offset-1">
															<ProductList selectedProductType="AUCTION" displayGrid={0} />
														</div>

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														<div className="cell small-10 small-offset-1">
															<CMSVideo videoName="Video/tracy-auction.mp4" loop={false} play={false} />
															<div className="text-center bold">
																<Translate text="Click" />&nbsp;
																<FontAwesomeIcon icon="play" size="1x" color="black" />&nbsp;
																<Translate text="to start this video" />
															</div>
														</div>
														<div className="cell small-12"><br /><br />&nbsp;</div>

													</div>
												)
										}

							{/* Bespoke Raffle Page */}

						    		{ this.props.pagename == 'Raffle' &&
												(
													<div className="grid-x grid-margin-x" >

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														<div className="cell small-12">
																<CMSBlock blockTitle="Raffle-Text" className="flow-text" />
														</div>

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														{/* Auction Products - see above */}
														<div className="cell small-10 small-offset-1">
															<ProductList selectedProductType="RAFFLE" displayGrid={0} />
														</div>

														<div className="cell small-12"><br /><br />&nbsp;</div>

													</div>
												)
										}

							{/* Bespoke For Sale Page */}

						    		{ this.props.pagename == 'ForSale' &&
												(
													<div className="grid-x grid-margin-x" >

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														<div className="cell small-12">
																<CMSBlock blockTitle="ForSale-Text" className="flow-text" />
														</div>

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														{/* Auction Products - see above */}
														<div className="cell small-10 small-offset-1">
															<ProductList selectedProductType="FOR-SALE" displayGrid={0} />
														</div>

														<div className="cell small-12"><br /><br />&nbsp;</div>

													</div>
												)
										}

							{/* Team Carousel */}

						    		{ this.props.pagename == 'Team' &&
												(
													<div className="grid-x grid-margin-x" >
														<div className="cell small-12 medium-12 large-12">
																<SlickCarousel carousel="Our Team" />
														</div>
													</div>
												)
										}

							{/* Video Appeal */}

							{ this.props.pagename == 'Appeal' &&
								(
									<div className="grid-x grid-margin-x" >
										<div className="cell small-10 small-offset-1">
											<CMSVideo videoName="Video/tracy-appeal.mp4" loop={false} play={true} />
											<div className="text-center bold">
												Click <FontAwesomeIcon icon="play" size="1x" color="black" /> to start this video
											</div>
										</div>
										<div className="cell small-12"><br /><br />&nbsp;</div>
									</div>
								)
							}

							{/* Change site */}

							{
								this.props.pagename == 'CharityHome'
									&& window.open('https://www.themaidenfactor.org/the-maiden-factor-foundation', '_self')
							}


							{/* D O N A T E */}

							{
								this.props.pagename == 'Donate'
									&& window.open('https://www.themaidenfactor.org/donate', '_self')
							}

							{/* ....another */}

				</div>
				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values
CustomPageBlocks.defaultProps = {
  selectedProductType: reduxStore.getState().selectedProductType
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        selectedProductType: state.selectedProductType
    }
}

export default connect(mapStateToProps)(CustomPageBlocks);
