// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import CMSBlock from 'CMSBlock';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- Make a bid for an Auction Item ---
  Props are:
	PRODUCT		    The product object
*/


class MakeAuctionBid extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
        _bid: 0,
        _miniumBid: 0,
        _bidMade: false,            // Bid...`in progress`
        _showTick: false,						// indicates that the Bid was accepted
        _showCross: false,					// Bid failed [normally outbid]
        _processing: false
      };

  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
    if (this._isMounted) {
      MyFetchGet( process.env.API + '/auction/getprice/' + this.props.PRODUCT.header.id, 3)
					.then( response => response.json() )
					.then( price => {
            if ( this._isMounted ) {
              this.setState( {_bid : price} );
              this.setState( {_miniumBid : price} );
            }
					});

    }
	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

  closeBid () {

        setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
        var action1 = {
            type: 'SET_SLIDE_WINDOW',
            slideWindow: false
        };
        // Note that we apply the dispatch to the props: mapStateToProps(state)
		    this.props.dispatch(action1);

        // Old Skool - Page refresh to update any bid values
        window.location.reload(false);
  }

  setBidValue (e) {
			this.setState( {_bid : e.target.value} );
	}

  // -----------------------------------------------------------------------

  makeBid (e) {

    this.setState( {_bidMade : true} );
    this.setState( {_processing : true} );

    var _data = {
      customer_id:  this.props.userID,
      product_id:   this.props.PRODUCT.header.id,
      amount:       this.state._bid
    };

    MyFetchPost( process.env.API + '/auction/bid', _data, 3)
      .then( response => response.json() )
      .then( response => {

          this.setState( {_processing : false} );

          if ( !_.isEmpty(response) ) {
              // Bid OK
              this.showBidSuccess();
          } else {
              // Bid Failed....
              this.showBidFail();
          }

      })
      .catch( /* It should retry !! */ )

	}

	showBidSuccess () {
		this.setState( {_showCross : false} );
		this.setState( {_showTick : true} );
	}

	showBidFail () {
		this.setState( {_showTick : false} );
		this.setState( {_showCross : true} );
	}

	// -----------------------------------------------------------------------

	render () {

    if ( !this.props.slideWindow ) return(<div></div>);

    // Wait for product's min/bid price
    if ( this.state._bid == 0 ) return(<div></div>);

    return (
        <div className="slide-in-page-from-right">

            <div className="right-hand-side-close">
              <FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
              data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
                  onClick={evt => this.closeBid(evt)} />
            </div>

            <div className="page-title black"><Translate text="Make a bid" /></div>
            <div className="product-name highlight-colour">
              <Translate text={this.props.PRODUCT.header.name} />
            </div>

            <div className="grid-x grid-margin-x">

                <div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-8 large-offset-2 center">
                    <br />
                    <div className="input-group">
                      <span className="input-group-label no-select larger-text bold">
                        Minimum Bid {this.props.__currencySymbol}
                      </span>
                      <input className="input-group-field larger-text red" type="number" min={this.state._miniumBid}
                          defaultValue={this.state._bid} onChange={evt => this.setBidValue(evt)} />
                    </div>
                </div>

                {/* _please wait while processing... */}
                { this.state._processing &&
                  <div className="cell small-12 center"><img src="images/authorising.gif" /></div> }

                {/* Various button options */}

                { !this.state._bidMade && <div className="cell small-12 center">
                    <button className="no-select hollow button large large-mobile-button strong-button alert"
                      onClick={evt => this.makeBid(evt)} >
                      <Translate text="Confirm Bid" />
                    </button>
                    <br /><br />
                </div> }

                { this.state._bidMade && this.state._showTick &&<div className="cell small-12 center">
                    <button className="no-select hollow button large large-mobile-button strong-button secondary"
                      onClick={evt => this.closeBid(evt)} >
                      <Translate text="Accepted - Close" />
                    </button>&nbsp; &nbsp;
                    <FontAwesomeIcon icon="check" size="2x" color="green" />
                    <br /><br />
                </div> }

                { this.state._bidMade && this.state._showCross &&<div className="cell small-12 center">
                    <button className="no-select hollow button large large-mobile-button strong-button secondary"
                      onClick={evt => this.closeBid(evt)} >
                      <Translate text="Out Bid - Review" />
                    </button>&nbsp; &nbsp;
                    <FontAwesomeIcon icon="times" size="2x" color="red" />
                    <br /><br />
                </div> }

                {/* Product Image and some auction info */}

                <div className="cell small-6 medium-6 large-4">
                  <img src={this.props.DISPLAY} className="image-responsive" />
                </div>
                <div className="cell small-6 medium-6 large-8">
                    <CMSBlock blockTitle="Auction" />
                </div>

            </div>

        </div>
      );

	}

}

// Set the default values
MakeAuctionBid.defaultProps = {
  	userID: reduxStore.getState().userID,
  	__currencySymbol: reduxStore.getState().__currencySymbol,
    slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
      	userID: state.userID,
      	__currencySymbol: state.__currencySymbol,
        slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(MakeAuctionBid);
