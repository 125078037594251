// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import Select from 'react-select';

//
//	Special Select where the option text is preceded with an icon [image]
//
//	<MySelect
// 		attributeOptions={attribute.key_value}
// 		keyName={attribute.key_name}
//		onChangeValue={ callbackFunction() }  ** this is optional for a bespoke callback />
//	/>
//

class MySelect extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	_selectedOption: [],								// has to be an Array !!
					_options: []
        };
	}

	componentDidMount () {

    this._isMounted = true;

		// Build _options from props.attributeOptions

		var _tempArray = [];
		this.props.attributeOptions.map( (attribute, i) => {

				var _this = {
					value: attribute.key_name,
					label: <div dangerouslySetInnerHTML={{	__html: this.iconwithLabel(attribute.icon_image, attribute.name) 	}} />
				}
				_tempArray.push(_this);
		});

		if (this._isMounted && _tempArray.length ) {

			this.setState( { _options : _tempArray } );

			//
			// Set the Default Value for the drop-down; this is frankly a JOKE as in all other Dropdowns
			// you just set the value = Index not the whole bloody record !!
			//
			this.setState( { _selectedOption : _tempArray[0] } );

		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	iconwithLabel ( _imageURL, _text ) {

		var _html = "<img src='" +
				_imageURL + "' class='select-box-icon' /> &nbsp;&nbsp; " +  _text;

		return _html;

	}

	selectValue (e) {

		if ( _.isFunction(this.props.onChangeValue) ) {
			// We have a custom callback...
			this.props.onChangeValue( this.props.keyName, e.value);
		} else {
			return e.value;
		}

	}

	render () {

		if ( _.isEmpty( this.state._selectedOption ) ) return (<div></div>);

		//
		//	There is a small problem here if/when the id has spaces, as we use this for the DIV name
		//	An example could be HAIR LENGTH, because that is not a valid DIV name, we convert
		// 	to underscores. e.g. HAIR LENGTH becomes HAIR_LENGTH. This however then needs to be refactored
		//	on the other side. It is just a shame that DIV IDs can't have spaces ?
		//

		var keyNameWithUnderscores = (this.props.keyName).replace(/ /g,"_");
		// console.log( keyNameWithUnderscores );

		return(
			<div className="select-box-container">
				<Select
					id={keyNameWithUnderscores}
					defaultValue={this.state._selectedOption}
					options={this.state._options}
					onChange={this.selectValue.bind(this)}
					placeholder='Select a Colour'
					blur
					/>
			</div>

		);
	}

}

export default MySelect;
