// Use ES6 Class

import React from 'react';
import Lightbox from 'react-image-lightbox';

class MyLightbox extends React.Component {

	render () {

		return(
			<Lightbox
				mainSrc={this.props.src}
				onCloseRequest={this.props.onClose}
			/>
		);
	}

}

export default MyLightbox;
