// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import Nav from 'Nav';
import MegaMenu from 'MegaMenu';

import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CustomPageBlocks from 'CustomPageBlocks';

import IPLog from 'IPLog';


class CMSWebPage extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_content: '',
					_customComponent: null,
					_refresh: ''
        };
	}

	// This to catch a reload of the component [a bit like Cart]
	static getDerivedStateFromProps(props, state) {

	    if (props.title !== state._refresh) {
					return {
						_content : '',
		        _refresh: props.title
		      };
	    }

	    // No state update necessary
	    return null;
	}

	// For a New Load which is always fine
	componentDidMount() {

		this._isMounted = true;

		if ( _.isEmpty(this.props.title) ) return false;

		// Load initial API Data
		var APIUrl = process.env.API + '/cms/getblock/' + this.props.title;
    MyFetchGet( APIUrl, 3)
		    .then( response => response.json() )
		    .then( apiBlockData => {
						if (this._isMounted) {
							this.setState( {_customComponent: apiBlockData.custom_component} );
							this.setState( {_content : apiBlockData} );
						}
		    })
				.catch( /* It should retry !! */ )

	}

	//
	// ..and a repeat of this IF the user uses the same component AGAIN and immediately
	//

	//
	//	Yes, I know, a duplicate of the above
	//

	componentDidUpdate () {

		this._isMounted = true;

		if ( _.isEmpty(this.props.title) ) return false;

		// Load initial API Data
		var APIUrl = process.env.API + '/cms/getblock/' + this.props.title;
    MyFetchGet( APIUrl, 3)
		    .then( response => response.json() )
		    .then( apiBlockData => {
							if (this._isMounted) {
								this.setState( {_customComponent: apiBlockData.custom_component} );
								this.setState( {_content : apiBlockData} );
							}
		    })
				.then( () => {
						// Scroll Top
						window.scrollTo(0, 0);
						this._isMounted = false;
				})
				.catch( /* Should try again */ )

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		// Is this CMS Block a custom component ?
		if ( !_.isEmpty(this.state._customComponent) )
			return(
				<CustomPageBlocks pagename={this.state._customComponent} />
			);

		// If _content is '' then that just means we haven't yet got the MyFetch
		// but if null then it means that the page could not be found. In this
		// case we proceed, but of course the content will be `null` or blank

		if ( _.isEmpty(this.state._refresh) || this.state._content == '' )
				return (<div></div>);

		return (
			<div className="flex-site">
				<IPLog URL={"/#/"+this.props.title} />
				<div className="site-content">

						<Nav />
						<MegaMenu />
						<ProductSearch />

							<div className="grid-x grid-margin-x" >

								<div className="cell small-12 medium-12 large-12">
										<div className="flow-text" dangerouslySetInnerHTML={{__html: this.state._content.content}} />
						    </div>

							</div>

				</div>
				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

export default CMSWebPage;
