// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class RegistrationMessages extends React.Component {

	render () {

		// Nothing so far
		if ( this.props.userID == 0 ) return (<div className="message-alert"></div>);

		// Whoops | duplicate
		if ( this.props.userID < 0 )
			return (
				<div className="message-alert">
					<FontAwesomeIcon icon="times" size="2x" color="red" />&nbsp;&nbsp; 
						<Translate text="Sorry, e-Mail already in use" />
				</div>
			);

		// All Good; this has been removed, as we redirect to Settings, so no need for this
		if ( this.props.userID > 0 )
			return (
				<div className="message-alert">
					{/* <FontAwesomeIcon icon="check" size="2x" color="lime" />
					&nbsp;&nbsp; Welcome <em>..redirecting you to Settings</em> */}
				</div>
			);
	}

}

// Set the default values = current Redux state values
RegistrationMessages.defaultProps = {
  userID: reduxStore.getState().userID,
  userName: reduxStore.getState().userName,
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        userID: state.userID,
        userName: state.userName,
    }
}

export default connect(mapStateToProps)(RegistrationMessages);
