// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost, MyFetchPostWithHeader } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';

import NummusCheckout from 'NummusCheckout';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayAdult extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_processingReceipt: false,
					_clicked: false,
					_publicKey: '',							// All PSPs operate with a Public Key for authorisation
					_subDomain: '', 						// Nummuspay
					_shopName: 'Vadoo vShop',		// Default
					_payment: false,						// Trigger
					_locale: 'auto',
					_testCardNumber: '',
					_testExpiryDate: '',
					_testCVC: '',
					_isLive: false,
					_name: '',
					_zip: -1,
					_address: -1,								// Used as am awaiting value
					addressKeys: [],          	// Array of ALL allowed address Keys
					addressData: []           	// Array of THIS users addresses
        };
	}

	componentDidMount () {

		this._isMounted = true;
		var getPublicKeyURL;	// Live or Sandbox

		// Load initial API Data to retrieve NUMMUS settings
		if (this._isMounted) {

			// 1. Determine if PSP is Live or using Sandbox
			MyFetchGet( process.env.API + '/setting/' + 'NUMMUS-PSP-LIVE', 1)
					.then( response => response.json() )
					.then( apiDataValue => {

								// console.log('NUMMUS PSP Live ?: ' + apiDataValue);
								if ( apiDataValue == -1 )
									return false;
								else if ( apiDataValue == 1 ) {
										getPublicKeyURL = process.env.API + '/setting/' + 'NUMMUS-PSP-PUBLIC-KEY-LIVE';
										if (this._isMounted) {
											this.setState( { _isLive: true } );
										}
								}	else {
										getPublicKeyURL = process.env.API + '/setting/' + 'NUMMUS-PSP-PUBLIC-KEY-TEST';
										if (this._isMounted) {
											this.setState( { _testCardNumber: '5555555555554444' } );
											this.setState( { _testExpiryDate: '12/25' } );
											this.setState( { _testCVC: '987' } );
										}
								}

								// 2. Get Nummus Public Key either -LIVE or -TEST
								MyFetchGet( getPublicKeyURL, 1)
										.then( response => response.json() )
										.then( apiKeyValue => {
												// console.log('NUMMUS Public Key: ' + apiKeyValue);
												if (this._isMounted)
													this.setState( { _publicKey: apiKeyValue } );

										})
										.then( () => {

											// 3. Get Customer Default Address | Billing Address
											MyFetchGet( process.env.API + '/customer/addresses', 1)
													.then( response => response.json() )
													.then( apiAddressKeysData => {

															if (this._isMounted)
									                this.setState( {addressKeys : apiAddressKeysData} );

							                // 4. Now get the data for this user
							                MyFetchGet(process.env.API + '/customer/addresses/get/' + this.props.userID, 1)
															.then( response => response.json() )
															.then( apiAddressData => {

																	// console.log( apiAddressData );

																	if (this._isMounted && apiAddressData.length ) {
																		this.setState( {addressData : apiAddressData } );

																		var address_key_name = apiAddressKeysData[0].address_key;
																		var postcode_key_name = apiAddressKeysData[0].postcode_key;

																		this.setState( {_address : apiAddressData[0][address_key_name] } );
																		this.setState( {_zip : apiAddressData[0][postcode_key_name] } );

																	}

															})
															.catch( /* Should try again */ )

													}) /* 3 */
													.catch( /* Should try again */ );

										}) /* 2 */
										.catch( /* Should try again */ );

						}) /* 1 */
						.catch( /* Should try again */ );


			} /* _isMounted */


			//
			//	Move to R E D U X
			//

			// Shop Name -> Email name || running this aSync
			var APIUrl2 = process.env.API + '/setting/' + 'EMAIL-NAME';
			MyFetchGet( APIUrl2, 10)
					.then( response => response.json() )
					.then( apiDataValue => {
							if (this._isMounted)
									this.setState( { _shopName: apiDataValue } );
					})
					.catch( /* Should try again */ );

	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	// Open Payment Window
	clickPayButton ( evt ) {
		this.setState( { _clicked: true } );
	}

	// Close Payment Window
	closeCheckoutBox ( evt ) {
		this.setState( { _clicked: false } );
	}


	nummusPaymentReceived( _token ) {

		// console.log( 'Pay Adult received Token: ' + _token)

		//
		//		Charge A P I
		//
		this.setState( { _processingReceipt: true } );

		// JSON format
		var _data = {
			"PaymentToken": _token,
			"cartID": this.props.cartID,
			"Amount": (this.props.cartValue * 1.00 +	this.props.deliveryCharge * 1.00 + this.props.VATCharge * 1.00).toFixed(2),
			"Currency": "GBP"
		};

		//
		var _userEmail, _APIPassword, _hmacKey, _subDomain;

		// A. User Login
		MyFetchGet(process.env.API + '/setting/' + 'NUMMUS-PSP-LOGIN-EMAIL', 1)
			.then( response => response.json() )
			.then( email => {
					// console.log( 'NUMMUS-PSP-LOGIN-EMAIL: ' + email);
					_userEmail = email;
			})
			.then( () => {

				// B. API Password
				MyFetchGet(process.env.API + '/setting/' + 'NUMMUS-PSP-API-PASSWORD', 1)
					.then( response => response.json() )
					.then( apiPassword => {
							// console.log( 'NUMMUS-PSP-API-PASSWORD: ' + apiPassword);
							_APIPassword = apiPassword;
					})
					.then( () => {

						// C. HMAC-KEY
						MyFetchGet(process.env.API + '/setting/' + 'NUMMUS-PSP-HMAC', 1)
							.then( response => response.json() )
							.then( hMACkey => {
									// console.log( 'NUMMUS-PSP-HMAC: ' + hMACkey);
									_hmacKey = hMACkey;
							})

							.then( () => {
									// D. SUB-DOMAIN | no longer required
									MyFetchGet(process.env.API + '/setting/' + 'NUMMUS-PSP-SUB-DOMAIN', 1)
										.then( response => response.json() )
										.then( subDomain => {
												// console.log( 'NUMMUS-PSP-SUB-DOMAIN: ' + subDomain);
												_subDomain = subDomain;
										})
										.then( () => {

												//
												//	Bloody Finally...
												//

												// 1. hMac the _data | using my PHP API as CryptoJS.() gives a different result ?!
												var _toHash = { key: _hmacKey, data: _data };
												MyFetchPost( process.env.API + '/payments/HMAC', _toHash, 1)
													.then( response => response.json() )
													.then( _hmacHashedPHP => {

														// console.log( 'NUMMUS HMAC Hashed: ' + _hmacHashedPHP);

														// 2. Get the Bearer Token
														var _bearerData = { username: _userEmail, password: _APIPassword };
														MyFetchPost( 'https://api.nummuspay.com/v2/Token', _bearerData, 1)
															.then( response => response.json() )
															.then( bearerToken => {

																	// console.log( 'NUMMUS bearerToken  ' + bearerToken);
																	// 3. Create the Header to send to the Charge
																	var _header = {
																		"Cache-Control": "no-cache",
																		"Content-Type": "application/json",
																		"Accept": "application/json",
																		"HMAC": _hmacHashedPHP,
																		"Authorization": "Bearer " + bearerToken
																	};

																	// 4. Create the CHARGE with Nummus Charge
																	MyFetchPostWithHeader( 'https://api.nummuspay.com/v2/Charge', _data, _header, 5)
																		.then( response => response.json() )
																		.then( response => {

																			// console.log(response);

																			if ( parseInt(response.ID) > 1 ) {

																				// 5. Assuming Good Payment, so housekeeping...
																				// console.log('Payment Recorded OK');

																				// console.log('Calling Backend for housekeeping...');

																				MyFetchPost( process.env.API + '/payments/nummus', _data, 1)
																					.then( response => response.json() )
																					.then( nummus => {

																							// console.log(nummus);
																							this.setState( { _processingReceipt: false } );
																							this.closeCheckoutBox();

																							if ( nummus.response == 1 ) {
																										this.setState( { _payment: true } );
																							} else {
																										// Bad situation as Payment is OK, but the backend has gone South
																										// invoke <FrontEndSystemError.... />
																							}
																					})
																					.catch( /* Should retry */);

																			} else {

																				// Failed System Error - This should NOT happen !!
																				// console.log('Payment could NOT be Recorded');
																				this.closeCheckoutBox();
																				this.setState( { _processingReceipt: false } );

																			}

																}) /* 3 + 4 */


													}) /* 2 */

											}) /* 1 */

								}) /* D */

							}) /* C */

					}) /* B */

			}) /* A */
			.catch( () => {
				this.setState( { _processingReceipt: false } );
			});

	}

	render () {

		{/* _payment is a state flag which triggers <Redirect to PaymentReceived ../>. This is
			set when the user has paid and the onSuccess() shows a payment status of 1/true */}

		if (this.state._payment) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-received/${_amount}`} push />);
		}

		// Ensure we have collected any MyFetch data [ note the use of -1 for _address ]
		if ( _.isEmpty(this.state._publicKey) || this.state._address == -1 )
			return (<div></div>);

		return (
			<div>

				<button className="button large no-select hollow alert large-mobile-button strong-button"
					onClick={evt => this.clickPayButton(evt)}>
					Pay | Card
				</button>

				{ this.state._clicked &&

					<NummusCheckout
						publicKey={this.state._publicKey}
						subDomain={this.state._subDomain}
						email={this.props.userEmail}
						name={this.state._name}
						address={this.state._address}
						zip={this.state._zip}
						amount={this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1}
						currency={this.props.currency}
						cardNumber={this.state._testCardNumber}
						expiryDate={this.state._testExpiryDate}
						CVC={this.state._testCVC}
						onSuccess={this.nummusPaymentReceived.bind(this)}
						onClose={this.closeCheckoutBox.bind(this)}
						sandbox={!this.state._isLive}
					/> }

				{ this.state._processingReceipt &&
						<div className="fixed-whole-page">
								<img src="images/money-bag.gif" />
						</div>
				}

			</div>
		);
	}

}

// Set the default values
PayAdult.defaultProps = {
	userEmail: reduxStore.getState().userEmail,
	userID: reduxStore.getState().userID,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userEmail: state.userEmail,
			userID: state.userID,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

export default connect(mapStateToProps)(PayAdult);
