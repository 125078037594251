// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';				// Required by some PSPs
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from 'ErrorBoundary';
import Translate from 'Translate';

import PleaseWait from 'PleaseWait';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayKlarna extends React.Component {

	constructor(props, defaultProps) {
		    super(props, defaultProps);
        this.state = {
						_javascript: false,
						_pleaseWait: false,
						_moneyBag: false,
						_openKlarnaCheckout: false,
						_klarnaSessionID: '',
						_klarnaClientToken: '',
						_klarnaPaymentOptions: [],					// Only seems to return one option ?
						_payment: false,
						_paymentAuthorising: false,
						_paymentToken: '',

						/* Address Details */
						_title: '',
						_givenName: -1,
						_familyName: -1,
						_address: -1,
						_city: '',
						_county: '',
						_postCode: -1,
						_phone: -1,

						addressKeys: [],          	// Array of ALL allowed address Keys
						addressData: []           	// Array of THIS users addresses

        };
				this.authorise = this.authorise.bind(this);
				this.onPayment = this.onPayment.bind(this);
	}

	componentDidMount () {

		this._isMounted = true;

		// Establish if Klarna is being used...
		if (this._isMounted) {

				// Determine if Klarna is installed
				MyFetchGet( process.env.API + '/setting/' + 'KLARNA-LIVE', 3 )
		        .then( response => response.json() )
		        .then( setting => {

								if ( setting != -1  ) {			// Klarna not installed

													const script = document.createElement("script");
											    script.src = "https://x.klarnacdn.net/kp/lib/v1/api.js";
											    document.getElementsByTagName("head")[0].appendChild(script);

											    if (script.readyState) {
													      // IE
													      script.onreadystatechange = () => {
															        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
																          	script.onreadystatechange = null;
																						if (this._isMounted) {
																	          	this.setState( { _javascript: true } );
																					}
															        }
													      };
											    } else {
													      // Chrome etc...
													      script.onload = () => {
																			if (this._isMounted) {
															        	this.setState( { _javascript: true } );
																			}
													      };
											    }
										}

		        })
		        .catch( /* Should try again */ );

				//
				// aSync for Speed Get Customer Default Address | Billing Address
				//

				MyFetchGet( process.env.API + '/customer/addresses', 1)
						.then( response => response.json() )
						.then( apiAddressKeysData => {

								if (this._isMounted)
										this.setState( {addressKeys : apiAddressKeysData} );

								// 4. Now get the data for this user
								MyFetchGet(process.env.API + '/customer/addresses/get/' + this.props.userID, 1)
								.then( response => response.json() )
								.then( apiAddressData => {

										if (this._isMounted && apiAddressData.length ) {
											this.setState( {addressData : apiAddressData } );

											var address_key_name = apiAddressKeysData[0].address_key;
											var postcode_key_name = apiAddressKeysData[0].postcode_key;

											this.setState( {_address : apiAddressData[0][address_key_name] } );
											this.setState( {_postCode : apiAddressData[0][postcode_key_name] } );

										}

								})
								.catch( /* Should try again */ )

						}) /* 3 */
						.catch( /* Should try again */ );

				//
				// aSync for Speed Get Customer name & phone
				//

				MyFetchGet(process.env.API + '/customer/get/' + this.props.userID, 3)
				    .then( response => response.json() )
						.then( customer => {
							if ( !_.isEmpty(customer) && this._isMounted ) {

									//
									//	H A R D   C o d e
									//

									// Create the first and last names from a validated name
									var _words = customer.attributes.FULL_NAME.value.split(' ');
									if ( _words.length < 2 ) {			// Should NOT happen if validated
										var _firstName = '';
										var _lastName = '';
									} else {
										var _firstName = _words[0];
										var _lastName = _words[_words.length - 1];
									}

									this.setState( { _givenName: _firstName} );
									this.setState( { _familyName: _lastName} );
									this.setState( { _phone: customer.attributes.MOBILE.value } );

							}
						})
						.catch( error => {

						});

		}

	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	openKlarnaCheckout () {

		// First off Create Klarna Session
		var _data = {
					cartID: this.props.cartID,
					cartValue: this.props.cartValue,
					delivery: this.props.deliveryCharge,
					tax: this.props.VATCharge,
					currency: this.props.currency
				};
		MyFetchPost( process.env.API + '/payments/klarna/session', _data, 1)
				.then( response => response.json() )
				.then( reply => {

					if ( !_.isEmpty(reply) ) {

						this.setState( { _klarnaSessionID: reply.session_id } );
						this.setState( { _klarnaClientToken: reply.client_token } );
						this.setState( { _klarnaPaymentOptions: reply.payment_method_categories } );
						this.setState( { _openKlarnaCheckout: true } );
						return reply;
					} else {
						console.log( 'Klarna failed to get a Session ID !');
					}

				})
				.then ( reply => {

					// Allowed Payment method(s)
					// console.log( this.state._klarnaPaymentOptions[0].identifier );

					// Initialise Klarna
					Klarna.Payments.init({
						client_token: reply.client_token
					});

					// Show Klarna Widget
					if ( !_.isEmpty(this.state._klarnaPaymentOptions) ) {
							Klarna.Payments.load({
								container: '#klarna-payments-container',
								payment_method_category: this.state._klarnaPaymentOptions[0].identifier
							}, function (response) {
								if ( !response.show_form ) {
									$('#klarna-payments-container').html('<h4 class="center">Sorry, Klarna widget failed</h4>')
								}
							});
					} else {
						$('#klarna-payments-container').html('<h4 class="center">Sorry, no options available</h4>')
					}

				});

	}

	authorise ( e ) {

		if ( _.isEmpty(this.state._givenName) ) {
			$('#BillingGivenName').focus().addClass('missing-data-field');
			return false;
		}

		if ( _.isEmpty(this.state._familyName) ) {
			$('#BillingFamilyName').focus().addClass('missing-data-field');
			return false;
		}

		if ( _.isEmpty(this.state._address) ) {
			$('#BillingAddress').focus().addClass('missing-data-field');
			return false;
		}

		if ( _.isEmpty(this.state._city) ) {
			$('#BillingCity').focus().addClass('missing-data-field');
			return false;
		}

		if ( _.isEmpty(this.state._postCode) ) {
			$('#BillingPostCode').focus().addClass('missing-data-field');
			return false;
		}

		if ( _.isEmpty(this.state._phone) ) {
			$('#BillingPhone').focus().addClass('missing-data-field');
			return false;
		}

		//
		// Klarna recommends a spinner here, so we will oblige, but their shitty f() will alert if there
		// is a problem and there is no way of closing this during this interruption ?
		//

		this.setState( { _paymentAuthorising: true } );

		//
		// When these shitty legacy callbacks are not well written we have to adapt with out own promise()
		//

		var promise = new Promise( (resolve, reject) => Klarna.Payments.authorize({
										payment_method_category: this.state._klarnaPaymentOptions[0].identifier
								},
								{
										/* Billing Address */
										billing_address: {
													given_name: this.state._givenName,
											    family_name: this.state._familyName,
											    email: this.props.userEmail,
											    title: this.state._title,
											    street_address: this.state._address,							// Note no 1 !!
											    street_address2: '',
											    postal_code: this.state._postCode,
											    city: this.state._city,
											    region: this.state._county,
											    phone: this.state._phone,
													/* Klarna only valid in the UK */
											    country: "GB"
										}

								},
								function(response) {

										if ( response.approved ) {
												resolve(response.authorization_token);
										} else {
												console.log('Token Failed');
										}

								}
					));

					promise.then( token => {
								this.setState( { _paymentAuthorising: false } );
								this.onPayment(token);
					}).catch( error => {
								this.setState( { _paymentAuthorising: false } );
					})

	}

	onPayment ( _token ) {

		// Use the money bag instead of the normal spinner
		this.setState( { _moneyBag: true } );

		// There is a little duplication below to fit with Klarna
		var _data = {
			token: _token,
			cartID: this.props.cartID,
			amount: this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1,
			// ...as above for Klarna
			cartValue: this.props.cartValue,
			delivery: this.props.deliveryCharge,
			tax: this.props.VATCharge,
			// ...........
			currency: this.props.currency,
			billing_address: {
						given_name: this.state._givenName,
						family_name: this.state._familyName,
						email: this.props.userEmail,
						title: this.state._title,
						street_address: this.state._address,							// Note no 1 !!
						street_address2: '',
						postal_code: this.state._postCode,
						city: this.state._city,
						region: this.state._county,
						phone: this.state._phone,
						/* Klarna only valid in the UK */
						country: "GB"
			}
		};

		MyFetchPost( process.env.API + '/payments/klarna', _data, 1)
			.then( response => response.json() )
			.then( klarna => {
					if ( klarna.response == 1 ) {
						// Close spinner
						this.setState( { _moneyBag: false } );
						// Redirect to payment page
						this.setState( { _payment: true } );
					}
			})
			.catch( error => {
					// Close spinner
					this.setState( { _moneyBag: false } );
			});

	}


	onClose () {
			this.setState( { _openKlarnaCheckout: false } );
	}

	onError () {
			this.setState( { _openKlarnaCheckout: false } );
	}

	//
	// ---------------------------------------------------------------------------
	//

	updateTitle(evt) {
		$('#BillingTitle').removeClass('missing-data-field');
	  this.setState( { _title: evt.target.value } );
	}

	updateGivenName(evt) {
		$('#BillingGivenName').removeClass('missing-data-field');
	  this.setState( { _givenName: evt.target.value } );
	}

	updateFamilyName(evt) {
		$('#BillingFamilyName').removeClass('missing-data-field');
	  this.setState( { _familyName: evt.target.value } );
	}

	updateAddress(evt) {
		$('#BillingAddress').removeClass('missing-data-field');
	  this.setState( { _address: evt.target.value } );
	}

	updateCity(evt) {
		$('#BillingCity').removeClass('missing-data-field');
	  this.setState( { _city: evt.target.value } );
	}

	updateCounty(evt) {
		$('#BillingCounty').removeClass('missing-data-field');
	  this.setState( { _county: evt.target.value } );
	}

	updatePostCode(evt) {
		$('#BillingPostCode').removeClass('missing-data-field');
	  this.setState( { _postCode: evt.target.value } );
	}

	updatePhone(evt) {
		$('#BillingPhone').removeClass('missing-data-field');
	  this.setState( { _phone: evt.target.value } );
	}


	//
	// ---------------------------------------------------------------------------
	//

	render () {

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		if ( this.state._moneyBag ) return(
				<div className="fixed-whole-page">
						<img src="images/money-bag.gif" />
				</div>);

		// Ensure we have collected any MyFetch data [ note the use of -1 for vital data ]
		// If the data is empty [ my settings ] then this will pass as just '' and the user must enter
		// or depending on what Klarna insists upon
		if ( this.state._address == -1 || this.state._postCode == -1 || this.state._givenName == -1 ||
					this.state._familyName == -1 || this.state._phone == -1 )
			return (<div></div>);

		{/* _payment is a state flag which triggers <Redirect to PaymentReceived ../>. This is
			set when the user has paid and the onSuccess() shows a payment status of 1 */}

		if (this.state._payment) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-received/${_amount}`} push />);
		}

		if ( this.state._openKlarnaCheckout )
				return (
					<div className="checkout-box-page-from-right">

							<div className="checkout-box-right-hand-side-close">
								<FontAwesomeIcon icon="window-close" size="3x" color="grey"
										onClick={evt => this.onClose(evt)} />
							</div>

							<br /><br />
							<h3 className="pink center">Klarna | Finance</h3>

							<div id="klarna-payments-container" />

							<br />

							<div className="grid-x">

											<div className="cell small-3">
												Scroll<br />
												<FontAwesomeIcon icon="arrow-down" size="2x" />
												<br /><br />
											</div>

											<div className="cell small-6">
												<p className="small-text">Please check below and then Authorise</p>
											</div>

											<div className="cell small-3">
												Scroll<br />
												<FontAwesomeIcon icon="arrow-down" size="2x" />
												<br /><br />
											</div>

											<div className="cell small-2">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="heading" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingTitle" value={this.state._title} onChange={evt => this.updateTitle(evt)}
																className="input-group-field" type="text" placeholder="" />
														</div>

											</div>

											<div className="cell small-5">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="pencil-alt" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingGivenName" value={this.state._givenName} onChange={evt => this.updateGivenName(evt)}
																className="input-group-field" type="text" placeholder="Given name" />
														</div>

											</div>

											<div className="cell small-5">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="user-edit" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingFamilyName" value={this.state._familyName} onChange={evt => this.updateFamilyName(evt)}
																className="input-group-field" type="text" placeholder="Family name" />
														</div>

											</div>

											<div className="cell small-12">
													<div className="input-group">
														<span className="input-group-label"><FontAwesomeIcon icon="street-view" size="1x"
														className="checkout-box-icon-color" /></span>
													<input id="BillingAddress" value={this.state._address} onChange={evt => this.updateAddress(evt)}
														className="input-group-field"	type="text" placeholder="Street address" />
													</div>

											</div>

											<div className="cell small-4">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="city" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingCity" value={this.state._city} onChange={evt => this.updateCity(evt)}
																className="input-group-field" type="text" placeholder="City" />
														</div>

											</div>

											<div className="cell small-4">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="map-marker-alt" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingCounty" value={this.state._county} onChange={evt => this.updateCounty(evt)}
																className="input-group-field" type="text" placeholder="County" />
														</div>

											</div>

											<div className="cell small-4">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="mail-bulk" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingPostCode" value={this.state._postCode} onChange={evt => this.updatePostCode(evt)}
																className="input-group-field cc-cvc" type="text" placeholder="Post code" />
														</div>

											</div>

											<div className="cell small-12">

														<div className="input-group">
															<span className="input-group-label"><FontAwesomeIcon icon="phone" size="1x"
															className="checkout-box-icon-color" /></span>
														<input id="BillingPhone" value={this.state._phone} onChange={evt => this.updatePhone(evt)}
																className="input-group-field cc-cvc" type="text" placeholder="Phone" />
														</div>

											</div>

							</div>

							<br />
							{ this.state._paymentAuthorising && <div><img src="images/authorising.gif" /></div> }
							<br />
							<button className="button large no-select hollow green large-mobile-button strong-button"
								onClick={evt => this.authorise(null)}>
								<Translate text="Authorise" />
							</button>
							<br /><br />

						</div>
				);


		if ( this.state._javascript )			// When JS script Loaded = Good to Process
				return (
					<button className="button large no-select hollow pink large-mobile-button strong-button"
						onClick={evt => this.openKlarnaCheckout(evt)}>
						<Translate text="Pay | Klarna" />
					</button>
				);
		else
				return (
					<div>
						{ !this.state._javascript && '...' }
					</div>
				);
	}

}

// Set the default values
PayKlarna.defaultProps = {
	userID: reduxStore.getState().userID,
	userEmail: reduxStore.getState().userEmail,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userID: state.userID,
			userEmail: state.userEmail,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

export default connect(mapStateToProps)(PayKlarna);
