// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Remove the current Cart - Extreme, but useful a.k.a. Cart Logout ---
	user/cart Data from R E D U X
*/

class ClearCurrentCart extends React.Component {

	componentDidMount () {

			// Remove all the cart entries .... cart + items
			MyFetchGet(process.env.API + '/cart/delete/' + this.props.cartID, 3)
			    // .then( response => response.json() )
			    .then( response => {

								//
								// R E D U X   update
								//

								// Cart ID
								setReduxValue(reduxStore, 'SET_CART_ID', 0 );
								var action1 = {
										type: 'SET_CART_ID',
										cartID: 0
								};

								// Items in cart
								setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', 0 );
								var action2 = {
										type: 'SET_ITEMS_IN_CART',
										itemsInCart: 0
								};

								// Value of cart
								setReduxValue(reduxStore, 'SET_CART_VALUE', 0.00 );
								var action3 = {
										type: 'SET_CART_VALUE',
										cartValue: 0.00
								};
								// Note that we apply the dispatch to the props: mapStateToProps(state)
								this.props.dispatch(action1);
								this.props.dispatch(action2);
								this.props.dispatch(action3);

								// Redirect to Home Page
								// React's DOM router doesn't seem to handle this very well, so vanilla JS
								window.location.href = '/#/home';

			    });

	}

	//
	//		This is a hidden <div> component
	//

	render () {
		return(<div></div>)
	}

}

// Set the default values
ClearCurrentCart.defaultProps = {
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue,
	itemsInCart: reduxStore.getState().itemsInCart,
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			cartID: state.cartID,
			cartValue: state.cartValue,
			itemsInCart: state.itemsInCart
    }
}

export default connect(mapStateToProps)(ClearCurrentCart);
