// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Review from 'Review';
import WriteReview from 'WriteReview';

import Translate from 'Translate';

/*
  --- Product Reviews ---
  Props are:
	PRODUCT_ID		The ID of the core-product
*/


class ProductReviews extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
        reviewsData: [],
        _submitReview: ''
      };

  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
		var APIUrl = process.env.API + '/product/reviews/get/' + this.props.PRODUCT_ID;
		MyFetchGet(APIUrl, 3)
		    .then( response => response.json() )
		    .then( apiReviewsData => {
          if (this._isMounted)
					     this.setState( {reviewsData : apiReviewsData} );
		    })

	}

  componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

  writeReview (evt) {
		this.setState( { _submitReview: <WriteReview PRODUCT_ID={this.props.PRODUCT_ID} /> } );
	}

	render () {

		return (
			<div>

        <div className="page-title black">
          <Translate text="Product Reviews" />&nbsp;&nbsp;

          <button className="desktop-display button no-select hollow secondary nudge-down-1"
            onClick={evt => this.writeReview(evt)} >
            <Translate text="Write a review" />
          </button>

          <FontAwesomeIcon icon="comment" size="2x" color="goldenrod"
              className="not-desktop nudge-down-1 protip" data-pt-position="right"
              data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Write a review"
              onClick={evt => this.writeReview(evt)} />

        </div>

        <div id="SubmitReview" className="grid-x grid-margin-x">
							<div className="cell small-12">
                {this.state._submitReview}
				    	</div>
  			</div>


        <div className="grid-x grid-margin-x">

  				{this.state.reviewsData.map( (review, key) => {
  							return(
  								<div key={key} className="cell small-12">
  					    		<Review review={review} />
  					    	</div>)
  				})}

  			</div>

			</div>
		);
	}

}

export default ProductReviews;
