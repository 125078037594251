// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import ProductSearch from 'ProductSearch';
import ProductList from 'ProductList';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*

  --- CORE PRODUCTS PAGE [BY Category or Type]---
  Props are:
	CATEGORY_KEY		The product category KEY
  PRICE_ORDER     Blank/null - uses system order or `ASC`, `DESC`
  REFRESH_ID      #hash random number used for refresh

*/


class Products extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
        displayGrid: localStorage.getItem('displayGrid') || 1,
        _stateCategoryKey: props.CATEGORY_KEY,
        _refreshNewOrder: false,
        _orderBy: props.orderBy
      };

      // Bind the listeners of my f()s to this class....
      this.displayGrid = this.selectDisplayGrid.bind(this);
  }

  //
	//	In line with new React V17 and stability, we now use getDerivedStateFromProps () using a state flag
	//	as above and componentDidUpdate () replaces componentDidMount () - this is much better
	//	because it stops the duplication of the API load or code in the old componentWillReceiveProps ()
	//

  // Note that this must be static
	static getDerivedStateFromProps(props, state) {
	    if (props.CATEGORY_KEY !== state._stateCategoryKey) {

					return {
		        _stateCategoryKey: props.CATEGORY_KEY
		      };
	    }

	    // No state update necessary
	    return null;
	}

  // Used as a handler for the `Display Grid` option
	selectDisplayGrid(e, _grid) {
			this.setState( { displayGrid: _grid } );
	}

  // Used as a handler for the `Display Order` option
	selectDisplayOrder(e, _order) {

    //
    //  ..pending v1.4
    //

    switch(_order) {

      // System Order
      case 0:
              // console.log('System Order');
              this.setState( { _orderBy: '' } );
              break;

      // Ascending, i.e. low to high
      case 1:
              // console.log('Ascending, i.e. low to high');
              this.setState( { _orderBy: 'ASC' } );
              break;

      // Descending, i.e. high to low
      case 2:
              // console.log('Descending, i.e. high to low');
              this.setState( { _orderBy: 'DESC' } );
              break;

    }
    // Trigger refresh of page with new display order
    this.setState( { _refreshNewOrder: true } );

	}

  // Scroll Top after render()
	componentDidMount () {

    this._isMounted = true;

	  window.scrollTo(0, 0);

    // Non dynamically-responsive by design, but default to a full-desktop layout if width() >= 1024px
    if ( $(window).width() >= 1024 ) {
        if (this._isMounted)
          this.setState( { displayGrid: 0 } );
    } else {
      if (this._isMounted)
        this.setState( { displayGrid: 1 } );
    }

    // Ditto for mobile
    if ( $(window).width() <= 640 ) {
        if (this._isMounted)
          this.setState( { displayGrid: 2 } );
    }

	}

  componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

    // Catch a page re-order request...
    if (this.state._refreshNewOrder ) {
      this.setState( { _refreshNewOrder: false } );   // !! React doesn't like this !!
      if ( _.isEmpty(this.state._orderBy) )
          // Back to default `system` display order
          return (<Redirect to={`/products/` + this.state._stateCategoryKey} push />);
      else {
          var _random = Math.random();
          // User has requested a price-based display order
          return (<Redirect to={`/products/` + this.state._stateCategoryKey + `/`
            + this.state._orderBy + `/${_random}`} push />);
      }
		}

		return (
			<div className="flex-site">
        <div className="site-content">
    				<Nav />
            <MegaMenu />
            <ProductSearch />

              {/*

                Tablet and Desktop options to alter layout up or drop
                + Sort by normal or price orders
                [not mobile though as too cluttered]

                */}

              <div className="grid-x center not-mobile-flex">
      					<div className="cell medium-6 medium-offset-3">

                  <FontAwesomeIcon icon="th" size="2x" color="grey" className="pointer protip"
                    data-pt-position="left" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Desktop grid"
                    onClick={ evt => this.selectDisplayGrid(evt,0) } />

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="th-large" size="2x" color="grey" className="pointer protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Block grid"
                    onClick={ evt => this.selectDisplayGrid(evt,1) } />

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="grip-lines" size="2x" color="grey" className="pointer protip"
                    data-pt-position="right" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="List"
                    onClick={ evt => this.selectDisplayGrid(evt,2) } />

                </div>

                <div className="cell medium-3">

                  <FontAwesomeIcon icon="arrows-alt-v" size="1x" className="pointer highlight-colour protip"
                    data-pt-position="left" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Website order"
                    onClick={ evt => this.selectDisplayOrder(evt,0) } />

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="sort-amount-down-alt" size="1x" className="pointer highlight-colour protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Price ascending"
                    onClick={ evt => this.selectDisplayOrder(evt,1) } />

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="sort-amount-down" size="1x" className="pointer highlight-colour protip"
                    data-pt-position="right" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Price descending"
                    onClick={ evt => this.selectDisplayOrder(evt,2) } />

                </div>

                <div className="cell small-12">&nbsp;</div>
      				</div>


              {/* Display all the products with the selected Type or Category */}
      				<div className="grid-x grid-margin-x">
      					<div className="cell small-1"></div>
      					<div className="cell auto">
                  {/* List all the products matching the Category state with the dynamic displayGrid */}
      						<ProductList
                      selectedProductType={this.state._stateCategoryKey}
                      displayGrid={this.state.displayGrid}
                      orderBy={this.props.PRICE_ORDER}
                      refreshID={this.props.REFRESH_ID}
                  />
      					</div>
      					<div className="cell small-1"></div>
      				</div>

        </div>

				<Footer />
        <ScrollTop />
			</div>
		);
	}

}

// Set the default values
Products.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(Products);
