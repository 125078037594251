// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from 'Translate';
import * as EmailValidator from 'email-validator';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


class LoginMessages extends React.Component {

	render () {

		// Double check the eMail first
		if ( !EmailValidator.validate(this.props.email) ) {
			return (
				<div className="message-alert">
					<FontAwesomeIcon icon="times" size="2x" color="red" />&nbsp;&nbsp;
						<Translate text="Sorry, e-Mail must be valid" />
				</div>
			);
		}

		// Nothing so far
		if ( this.props.userID == 0 ) return (<div></div>);

		// Whoops
		if ( this.props.userID < 0 ) {
			return (
				<div className="message-alert">
					<FontAwesomeIcon icon="times" size="2x" color="red" />&nbsp;&nbsp;
						<Translate text="Sorry, e-Mail and password match not found" />
				</div>
			);
		}

		// All Good
		if ( this.props.userID > 0 ) {
			return (
				<div className="message-alert">
					<FontAwesomeIcon icon="check" size="2x" color="lime" />&nbsp;&nbsp;
						<Translate text="Logged in as" />&nbsp;
						<em>{this.props.userName}</em>
				</div>
			);
		}
		
	}

}

// Set the default values = current Redux state values
LoginMessages.defaultProps = {
  userID: reduxStore.getState().userID,
  userName: reduxStore.getState().userName,
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        userID: state.userID,
        userName: state.userName,
    }
}

export default connect(mapStateToProps)(LoginMessages);
