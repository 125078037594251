// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- Select Address ---
  Props are:
	CUSTOMER_ID		The ID of the user/customer
*/


class SelectAddressOnCheckout extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
          currentAddressIndex: 0,   // Default for drop-down list of Addresses
          addressKeys: [],          // Array of ALL allowed address Keys
          addressData: []           // Array of THIS users addresses
      };

  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
    if (this._isMounted) {

      // First off get the Keys
      var APIUrl = process.env.API + '/customer/addresses';
  		MyFetchGet(APIUrl, 3)
  		    .then( response => response.json() )
  		    .then( apiAddressKeysData => {

            if (this._isMounted) {
                this.setState( {addressKeys : apiAddressKeysData} );

                // Now get the data for this user
                var APIUrl = process.env.API + '/customer/addresses/get/' + this.props.CUSTOMER_ID;
                MyFetchGet(APIUrl, 3)
                  .then( response => response.json() )
                  .then( apiAddressData => {

                     if (this._isMounted) {
                    	     this.setState( {addressData : apiAddressData} );

                           // We also need to set the state.currentAddressIndex to the current address
                           // This, by my omission, isn't very elegant !
                           apiAddressData.map( (address, key) => {

                             var address_key_name = apiAddressKeysData[key].address_key;
                             var address_key_value = address[address_key_name];

                             if ( address_key_value == this.props.deliveryAddress ) {
                               console.log('Ah Ha - Address found as ' + key);
                               this.setState( {currentAddressIndex : key} );
                             }
                           });

                     }
                  })
                  .then( () => {
                     this._isMounted = false;
                  })

            }
  		    })

    }
	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

  closeSelectAddress () {

        setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
        var action1 = {
            type: 'SET_SLIDE_WINDOW',
            slideWindow: false
        };
        // Note that we apply the dispatch to the props: mapStateToProps(state)
		    this.props.dispatch(action1);

  }

  onChangeAddress (e) {

      // Which address have we selected - update key-index to reflect selection in drop-down
      // console.log('Just selected Address ' + e.target.value);
      this.setState( {currentAddressIndex : e.target.value} );

      // Change the delivery Address and Postcode Keys for this Cart
      var _newAddressKey = this.state.addressKeys[e.target.value].address_key;
      var _newPostcodeKey = this.state.addressKeys[e.target.value].postcode_key;

      var _newAddress = this.state.addressData[e.target.value][_newAddressKey];
      var _newPostcode = this.state.addressData[e.target.value][_newPostcodeKey];

      var _data = {
  			id: this.props.cartID,
  			address_key: _newAddressKey,
  			postcode_key: _newPostcodeKey
  		};

  		MyFetchPost( process.env.API + '/cart/updateshipto', _data, 1)
  				.then( response => response.json() )
  				.then( cart => {

              // Update REDUX with the new hard-data values
              setReduxValue(reduxStore, 'SET_DELIVERY_ADDRESS', _newAddress );
              var action1 = {
                  type: 'SET_DELIVERY_ADDRESS',
                  deliveryAddress: _newAddress
              };
              setReduxValue(reduxStore, 'SET_DELIVERY_POSTCODE', _newPostcode );
              var action2 = {
                  type: 'SET_DELIVERY_POSTCODE',
                  deliveryPostcode: _newPostcode
              };
              // Note that we apply the dispatch to the props: mapStateToProps(state)
              this.props.dispatch(action1);
              this.props.dispatch(action2);

          });
  }

	render () {

        return (
          <div className="slide-in-page-from-right">

              <div className="right-hand-side-close">
                <FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
                data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
                    onClick={evt => this.closeSelectAddress(evt)} />
              </div>

            <div className="page-title black">
              <Translate text="Available Addresses" />
            </div>

            <select value={this.state.currentAddressIndex} onChange={ evt => this.onChangeAddress(evt) }>

                    {this.state.addressData.map( (address, key) => {

                    var address_key_name = this.state.addressKeys[key].address_key;
                    var address_key_value = address[address_key_name];

                    var postcode_key_name = this.state.addressKeys[key].postcode_key;
                    var postcode_key_value = address[postcode_key_name];

                    return <option key={key} value={key} >
                      { address_key_value }, { postcode_key_value }
                    </option>;
                    })}

            </select>

            <div className="page-title text-center no-select">

              <Link to="/mysettings">
                <button className="no-select hollow button large secondary large-mobile-button">
                  <Translate text="Register New Address" />
                </button>
              </Link>

            </div>

          </div>
        );

	}

}

// Set the default values
SelectAddressOnCheckout.defaultProps = {
    cartID: reduxStore.getState().cartID,
    slideWindow: reduxStore.getState().slideWindow,
    deliveryAddress: reduxStore.getState().deliveryAddress,
    deliveryPostcode: reduxStore.getState().deliveryPostcode
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        cartID: state.cartID,
        slideWindow: state.slideWindow,
        deliveryAddress: state.deliveryAddress,
        deliveryPostcode: state.deliveryPostcode
    }
}

export default connect(mapStateToProps)(SelectAddressOnCheckout);
