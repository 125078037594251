// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';				// Required by Nummus JS Lib !!

import _ from 'lodash';


//
//  PayNummus is a wrapper for Nummuspay.CreateToken
//  It requires your own checkout popup...
//

class PayNummus extends React.Component {

	constructor(props, defaultProps) {
		    super(props, defaultProps);
        this.state = {
						_javascript: false,
        };
	}

	componentDidMount () {

		this._isMounted = true;

		const script = document.createElement("script");
    script.src = "https://api.nummuspay.com/Content/js/v1/nummuspay.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    if (script.readyState) {
		      // IE
		      script.onreadystatechange = () => {
				        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
					          script.onreadystatechange = null;
										if (this._isMounted)
					          	this.setState( { _javascript: true } );
				        }
		      };
    } else {
		      // Chrome etc...
		      script.onload = () => {
						if (this._isMounted)
		        	this.setState( { _javascript: true } );
		      };
    }

	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	paymentToken () {

		Nummuspay.SetPublicKey(this.props.publicKey);
		
		console.log( 'NUMMUS PUBLIC API-KEY: ' + this.props.publicKey );
		console.log( 'NUMMUS SUB-DOMAIN: ' + this.props.subDomain );

		// Create month and year from 'expiry'
		var _month = this.props.expiry.substring(0, 2);
		// Numus is good with the year `20` for `2020`
		var _year = this.props.expiry.substring(3, 5);

		// Create the first and last names from a validated name
		var _words = this.props.name.split(' ');
		if ( _words.length < 2 ) {			// Should NOT happen if validated
			var _firstName = 'John';
			var _lastName = 'Doe';
		} else {
			var _firstName = _words[0];
			var _lastName = _words[_words.length - 1];
		}

		// Create data object to send to Nummuspay.CreateToken

		var _data = {
			 // subdomain: this.props.subDomain,												// String
			 email: this.props.email,																		// as is String
			 amount: (this.props.amount *1.0).toFixed(2),								// Decimal to 2-places
			 currency: this.props.currency,															// as is String Code: e.g GBP
			 firstName: _firstName,																			// as is String: Parsed from Name
			 lastName: _lastName,																				// as is String: ....ditto
			 billingAddress: this.props.address,												// as is String
			 zip: this.props.zip,																				// as is String
			 number: this.props.cardNumber,															// as is String
			 month: _month,																							// Integer
			 year: _year,																								// Integer
			 cvv: this.props.cvc																				// Integer
		};

		console.log( 'Submitting Data for Payment as...');
		console.log( _data );

		//
		// Still in debug=mode...
		//

		Nummuspay.CreateToken(_data)
				 .done( function(token) { console.log('Payment OK') } )
				 .then( token => {
					 	console.log('PayNummus.jsx Token: ' + token);
						this.props.onSuccess(token);	/* Callback */
						throw 'Success'; /* to stop the promise chain */
		 })
		 // .fail( declined => { console.log('.fail() in createToken()') } )
		 .fail( function(declined) {} )
		 .then( declined => { console.log('Payment Failed')	})
		 .catch( /* hopefully from the above throw */
			 		(end) => {
						// console.log('End of Promise chain with.. ' + end);
						if ( end != 'Success' ) {
							console.log(end);
							console.log('Payment was rejected');
							this.props.onFail(end); 	/* Callback */
						}
					}
		 );

	}

	//
	// ---------------------------------------------------------------------------
	//

	render () {

		if ( this.state._javascript )			// When JS script Loaded = Good to Process
			this.paymentToken();

		return (
			<div>
				{ !this.state._javascript && '...' }
			</div>
		);
	}

}

export default PayNummus
