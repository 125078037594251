// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Translate extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_translation: this.props.text
        };
	}

	componentDidMount () {

    this._isMounted = true;

		// Don't bother with translation if not enabled
		if ( this.props.__multiLanguage == 1 ) {

				var _data = {
					text: this.props.text,
					locale: this.props.locale,
					key: this.props.__translateAPIKey
				};
				MyFetchPost( process.env.API + '/vtranslate', _data, 1)
						.then( response => response.json() )
						.then( translation => {
							// console.log(translation);
							if (this._isMounted)
								this.setState( {_translation : translation} );
						});

		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		return (
			<span>{this.state._translation}</span>
		);
	}

}

// Set the default values
Translate.defaultProps = {
  __multiLanguage: reduxStore.getState().__multiLanguage,
	__translateAPIKey: reduxStore.getState().__translateAPIKey,
	locale: reduxStore.getState().locale
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
  			__multiLanguage: state.__multiLanguage,
				__translateAPIKey: state.__translateAPIKey,
				locale: state.locale
    }
}

export default connect(mapStateToProps)(Translate);
