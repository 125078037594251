// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PleaseWait from 'PleaseWait';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Add a product [inc bundle] to the cart ---
	user/cart Data from R E D U X
	props are PRODUCT_ID, QUANTITY, BUNDLE
*/

class AddToCart extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					_pleaseWait: true
      };
  }

	componentDidMount () {

		//
		//		Check the cart situation
		//

		if ( this.props.cartID == 0 ) {
			// No cart in play so create one
			MyFetchGet( process.env.API + '/cart/new/' + this.props.userID, 3)
					.then( response => response.json() )
					.then( cart => {
						// Establish this Cart in R E D U X
						setReduxValue(reduxStore, 'SET_CART_ID', cart.id );
						var action = {
				        type: 'SET_CART_ID',
				        cartID: cart.id
				    };
						// Note that we apply the dispatch to the props: mapStateToProps(state)
						this.props.dispatch(action);
						this.addProductToCurrentCart();
					})

		} else {
			this.addProductToCurrentCart();
		}

	}

	addProductToCurrentCart () {

		//
		//		Add this product to the Cart
		//

		var _data = {
			product_id: this.props.PRODUCT_ID,
			cart_id: this.props.cartID,
			quantity: this.props.QUANTITY,
			options: this.props.OPTIONS,
			bundle: 0
		};

		MyFetchPost( process.env.API + '/cart/add', _data, 3)
				.then( response => response.json() )
				.then( cart => {

					//
					//		Update R E D U X values
					//

					// Items in cart
					setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', (cart.items).length );
					var action1 = {
							type: 'SET_ITEMS_IN_CART',
							itemsInCart: (cart.items).length
					};

					// Value of cart
					var _previousValue = this.props.cartValue;
					var _cartTotal = 0.00;

					{cart.items.map( (item, key) => {
							_cartTotal += (item.quantity_ordered) * (item.unit_price);
					})}

					if ( _cartTotal == _previousValue ) {
						// console.log('Cart Value has not changed = FAIL');
						this.props.onFail();
					} else {
						// console.log('Cart Value HAS changed = OK');
						this.props.onSuccess();
					}

					setReduxValue(reduxStore, 'SET_CART_VALUE', _cartTotal );
					var action2 = {
							type: 'SET_CART_VALUE',
							cartValue: _cartTotal
					};

					// Note that we apply the dispatch to the props: mapStateToProps(state)
					this.props.dispatch(action1);
					this.props.dispatch(action2);

				})
				.then( cart => {
						this.setState( { _pleaseWait: false } );
				})
				.catch( error => {
						this.setState( { _pleaseWait: false } );
				});

	}

	//
	//		This is a hidden <div> component when done...
	//

	render () {

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		return(<div></div>)
	}

}

// Set the default values
AddToCart.defaultProps = {
	userID: reduxStore.getState().userID,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue,
	itemsInCart: reduxStore.getState().itemsInCart,
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userID: state.userID,
			cartID: state.cartID,
			cartValue: state.cartValue,
			itemsInCart: state.itemsInCart
    }
}

export default connect(mapStateToProps)(AddToCart);
