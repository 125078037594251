// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import Slider from "react-slick";

import CMSImage from 'CMSImage';

// Carousel CSS
require('style-loader!css-loader!slick-carousel/slick/slick.css');

class ProductImageCarousel extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        };
	}

	// Callback
	onClickImage ( e, _url ) {
		this.props.onClickImage( _url );
	}

	render () {

		var settings = {
			slidesToShow: 3,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 1500,
			responsive: [
			        {
			          breakpoint: 1024,
			          settings: {
			            slidesToShow: 2
			          }
			        },
			        {
			          breakpoint: 640,
			          settings: {
			            slidesToShow: 2
			          }
			        }
			      ]
		}

		if ( this.props.images.length < 3 )		return <div className="hero-text center">...</div>;

		return (

				<Slider {...settings} className={this.props.className}>

						{ this.props.images.map( (url, key) => {

							return (
								<div key={key}>
									<img className="image-responsive pointer" 
										src={url} onClick={ evt => this.onClickImage(evt, url) } />
								</div>
							)

						})}

				</Slider>

		);
	}

}

export default ProductImageCarousel;
