// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import PleaseWait from 'PleaseWait';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


/*
  --- A cart or product-line item ---
  Props are:
	lineItem			The order-line object
*/

class CartLineDisplay extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_quantity: this.props.lineItem.quantity_ordered,
					_refreshPage: false,
					_pleaseWait: false
        };
	}

	componentDidMount () {
		this._isMounted = true;
	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	reduxUpdate(cart) {

		//
		// R E D U X   update
		//

		// Items in cart
		setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', (cart.items).length );
		var action1 = {
				type: 'SET_ITEMS_IN_CART',
				itemsInCart: (cart.items).length
		};
		// Value of cart
		var _cartTotal = 0.00;

		{cart.items.map( (item, key) => {
				_cartTotal += (item.quantity_ordered) * (item.unit_price);
		})}

		setReduxValue(reduxStore, 'SET_CART_VALUE', _cartTotal );
		var action2 = {
				type: 'SET_CART_VALUE',
				cartValue: _cartTotal
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
		this.props.dispatch(action2);

	}

	setQuantity (e) {

		// Catch the weird input error which allows the user to delete the number which they shouldn't
		// really be able to do but they can ?
		if ( e.target.value < 1 ) return false;

		this.setState( { _pleaseWait: true } );

		this.setState( {_quantity : e.target.value} );

		// Update this order-line [real DB value] react Style
		var _data = {
			id: this.props.lineItem.id,
			key: 'quantity_ordered',
			value: e.target.value
		};

		MyFetchPost(process.env.API + '/cart/updateItem', _data, 3)
				.then( response => response.json() )
				.then( cart => {
						this.reduxUpdate( cart );
				})
				.then( () => {
						// Refresh Cart Page
						if ( this._isMounted )
							this.setState( {_refreshPage : true} );
				})
				.then( () => {
					if ( this._isMounted )
						this.setState( { _pleaseWait: false } );
				})
				.catch( error => {
					if ( this._isMounted )
						this.setState( { _pleaseWait: false } );
				});
	}

	deleteItemLine (evt) {

			this.setState( { _pleaseWait: true } );

			// Remove this order-line
			MyFetchGet(process.env.API + '/cart/deleteItem/' + this.props.lineItem.id, 3)
			    .then( response => response.json() )
			    .then( response => {

						var APIUrl = process.env.API + '/cart/get/' + this.props.cartID;
						MyFetchGet(APIUrl, 3)
						    .then( response => response.json() )
						    .then( cart => {
										this.reduxUpdate( cart );
						    })
								.then( () => {
										// Refresh Cart Page
										if ( this._isMounted )
											this.setState( {_refreshPage : true} );
								})
			    })
					.then( () => {
						if ( this._isMounted )
							this.setState( { _pleaseWait: false } );
					})
					.catch( error => {
						if ( this._isMounted )
							this.setState( { _pleaseWait: false } );
					});

	}

	render () {

		var lineItem = this.props.lineItem;
		// Oddly keep this condition to avoid any issues ????
		if ( _.isEmpty(lineItem) ) return (null);

		// State based redirect, see above, where we set the state trigger _refreshPage to true
		// which in effect then causes the below Redirect. This is one of the designs I find rather
		// cumbersome in React JS - takes getting used to....
		if (this.state._refreshPage) {
			{/* This is just a page refresh - React JS style */}
			var _random = Math.random();
			return (<Redirect to={`/cart/${_random}`} push />);
		}

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		return (
			<div className="grid-x">

				<div className="cell small-4 small-offset-1 large-1 large-offset-1">

						<div className="input-group input-group-cart-line">
							<span className="input-group-label">X</span>
							<input className="input-group-field input-group-cart-line" type="number" min="1" defaultValue={lineItem.quantity_ordered}
								onChange={evt => this.setQuantity(evt)}/>
						</div>

				</div>

				<div className="cell small-6 large-4">
					<div className="cart-line cart-border">

						{lineItem.product_name}

						{lineItem.options.map( (item, key) => {
							return( <span key={key}>{' -- ' + item.product_attribute_value + ' '}</span> )
						})}

					</div>
				</div>

				<div className="cell small-1 small-offset-1 large-1 large-offset-0">
					<div className="cart-line text-center">@</div>
				</div>

				<div className="cell small-3 large-1">
					<div className="cart-line cart-border">{lineItem.unit_price}</div>
				</div>

				<div className="cell small-1 large-1">
					<div className="cart-line text-center">=</div>
				</div>

				<div className="cell small-4 large-1">
					<div className="cart-line cart-border no-wrap">
						{ this.props.__currencySymbol + ' ' + Intl.NumberFormat('en-US', { minimumFractionDigits:2 }).format(lineItem.unit_price * lineItem.quantity_ordered) }
					</div>
				</div>

				<div className="cell small-2 large-1 text-center">
					<FontAwesomeIcon icon="trash-alt" size="2x" color="red"
						className="nudge-down-1 pointer protip" data-pt-position="right" data-pt-auto-hide="2000"
						data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Clear"
						onClick={evt => this.deleteItemLine(evt)} />
				</div>

			</div>
		);
	}

}

// Set the default values
CartLineDisplay.defaultProps = {
	__currencySymbol: reduxStore.getState().__currencySymbol,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			__currencySymbol: state.__currencySymbol,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}


export default connect(mapStateToProps)(CartLineDisplay);
