// Use ES6 Class

import React from 'react';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import MyLightbox from 'MyLightbox';
import FlipPhoto from 'FlipPhoto';

import { ReactDOM, Link, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatEAV, translateTooltips } from 'Useful';
import Translate from 'Translate';

import AddToCart from 'AddToCart';
import MakeAuctionBid from 'MakeAuctionBid';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


/*
  --- A product box or grid-display item ---
  Props are:
	product			The product object
	truncate 		An option to reduce the display items
*/

class ProductDisplay extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_quantity: 1,
					_auction: false,
					_makeBid: false,
					_showTick: false,								// indicates that added to Cart
					_showCross: false,							// add-to-cart failed [normally no stock]
					_callAddToCart: false,
					_redirectToFullPage: false,
					_addAsFavourite: true,
					_clickLightbox: false,
					_validatedImage: this.props.product.IMAGE.PRIMARY_PHOTO || '',
					_bought: false
        };
	}

	componentDidMount () {

			setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

	    var action1 = {
	        type: 'SET_SLIDE_WINDOW',
	        slideWindow: false
	    };

	    // Note that we apply the dispatch to the props: mapStateToProps(state)
			// this.props.dispatch(action1);

			this._isMounted = true;
			// console.log( this.props.product );

			// This is an odd one in that we need a `state` here rather than just testing
			// this.props.product.header.auction in the render() or we get a `0` displayed ?
			if ( this._isMounted && this.props.product.header.auction) {
					this.setState( { _auction : true } );
			}

			//
			//	Quick Validate Primary Image as a loadable image
			//

			var img = new Image();

			if ( !_.isEmpty(this.props.product.IMAGE.PRIMARY_PHOTO) ) {

						var scriptPromise = new Promise((resolve, reject) => {

								img.src = this.props.product.IMAGE.PRIMARY_PHOTO;
								img.onload = resolve;
								img.onerror = reject;

						});

						scriptPromise.then(() => {
								if (this._isMounted)
									this.setState( { _validatedImage : this.props.product.IMAGE.PRIMARY_PHOTO } );
						})
						.catch( () => {
								if (this._isMounted)
									this.setState( { _validatedImage : 'images/no-image.png' } );
						})

			} else {
						if (this._isMounted)
							this.setState( { _validatedImage : 'images/no-image.png' } );
			}

			//
			//		Tooltip Translation
			//

			translateTooltips(this.props.locale);


			//
			// Optional product favourite check for this user
			//

			if ( this.props.userID > 0 ) {

				var _data = {
		      customer_id:  this.props.userID,
		      product_id:   this.props.product.header.id
		    };

		    MyFetchPost( process.env.API + '/product/isfavourite', _data, 3)
		      .then( response => response.json() )
		      .then( response => {

							if ( this._isMounted && response )
		          	this.setState( {_addAsFavourite: false} );

					});

			}

	}

	componentWillUnmount () {
	    // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	// -----------------------------------------------------------------------

	//
	// Simple `trigger` flags to open Lightbox of Primary Image
	//

	clickLightBox (e) {
			this.setState( {_clickLightbox : true} );
	}

	closeLightBox (e) {
			this.setState( {_clickLightbox : false} );
	}

	// -----------------------------------------------------------------------

	setQuantity (e) {

			// Catch the weird input error which allows the user to delete the number which they shouldn't
			// really be able to do but they can ?
			if ( e.target.value < 1 ) return false;

			this.setState( {_quantity : e.target.value} );
	}

	redirectToFullPage (e) {
		this.setState( {_redirectToFullPage: true} );
	}

	addStandardProductToCart (evt) {

		if ( this.props.product.header.has_options ) {

			// console.log('Has selectable options .. Full display BUY required');
			this.redirectToFullPage();

		} else {

			// Add this `standard` no-select options product x Quantity to the cart
			// console.log( this.state._quantity, 'x', this.props.product.header.name );
			this.setState( {_callAddToCart : true} );

		}

	}

	makeStandardProductBid ( evt ) {

		setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );
		var action1 = {
				type: 'SET_SLIDE_WINDOW',
				slideWindow: true
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		if (this._isMounted)
			this.setState( {_makeBid : true} );

	}

	// -----------------------------------------------------------------------

	removeAsFavourite (e, product_id) {

			var _data = { customer_id: this.props.userID };
			MyFetchPost( process.env.API + '/product/favourites/delete/' + product_id, _data, 3)
				.then( response => response.json() )
				.then( response => {
						this.setState( {_addAsFavourite: true} );
				})
				.catch( /* It should retry !! */ )

	}

	addAsFavourite (e, product_id) {

		var _data = { customer_id: this.props.userID };
		MyFetchPost( process.env.API + '/product/favourites/new/' + product_id, _data, 3)
			.then( response => response.json() )
			.then( response => {
					this.setState( {_addAsFavourite: false} );
			})
			.catch( /* It should retry !! */ )

	}

	// -----------------------------------------------------------------------

	showBuySuccess () {
		this.setState( {_showCross : false} );
		this.setState( {_showTick : true} );
		this.setState( {_bought : true} );
	}

	showBuyFail () {
		this.setState( {_showTick : false} );
		this.setState( {_showCross : true} );
	}

	// -----------------------------------------------------------------------

	render () {

		var product = this.props.product;

		// Oddly keep this condition to avoid any issues
		if ( _.isEmpty(product) ) return (<div></div>);

		// truncate is a useful property on condensed grid displays
		var _truncate = this.props.truncate;

		// React will not allow JSX compilation with a partial conditional DIV so....
		if ( this.props.__preferProductImagesAbove == 1 ) {
				var _conditionalLayout = "cell small-12";
		} else {
				var _conditionalLayout = "cell small-12 medium-6 large-6";
		}

		return (

			<div className="grid-x grid-margin-x product-container">

				{/*
						------------------------------------------------------------------
						Some product displays require a full page to BUY, e.g with options
					 	------------------------------------------------------------------
				*/}

				{this.state._redirectToFullPage && <Redirect to={`/product/${product.header.name}`} push /> }

				{/*
						-------------------------------------
						Photo to the left or `full screen-ish`
					 	-------------------------------------
				*/}

				{ this.props.__preferProductImagesAbove == 0 &&
				<div className="force-relative cell small-8 small-offset-2 medium-6 medium-offset-0 large-6 large-offset-0 center-image-container">

					{ !_.isEmpty(product.IMAGE.PRIMARY_PHOTO) && _.isEmpty(product.IMAGE.FLIP_PHOTO) &&
						<LazyLoad><img src={this.state._validatedImage}
							className="pointer"
							onClick={evt => this.redirectToFullPage(evt)}
							/></LazyLoad> }

					{ !_.isEmpty(product.IMAGE.PRIMARY_PHOTO) && !_.isEmpty(product.IMAGE.FLIP_PHOTO) &&
						<FlipPhoto
							id={product.header.id}
							front={this.state._validatedImage}
							back={product.IMAGE.FLIP_PHOTO}
							onClick={evt => this.redirectToFullPage(evt)}
							/> }

					{/* SOLD Sign - the product has been marked as SOLD or `Sold out` which is a nice option
						rather than just disabling the product. The user should set the stock to zero, but
						we don't check for that... */}

					{ product.header.sold_out_sign == 1 && <img src="images/sold.png" className="sold-out-overlay" /> }

					{/* If the product is being run as an auction product it may have a reserve price and if so, we
						need to say if the reserve has NOT been met. This is similiar to the above SOLD sign and we use the
						same CSS class to overlay this image. It's a slightly lazy test, but optimal */}

					{ product.header.auction_reserve > product.header.price
						&& <img src="images/reserve-not-met.png" className="sold-out-overlay" /> }

					{/* Revised style design - have the full image and fav icons top right corner of the image */}

					<FontAwesomeIcon icon="image" size="2x"
						className="highlight-colour protip full-image-product-icon glass" data-pt-position="top"
						data-pt-scheme="black" data-pt-classes="my-tooltip"
						data-pt-title="Full Image"
						onClick={evt => this.clickLightBox(evt)} />

					{/* Favourite Options with a tooltip */}

					{ this.props.userID > 0 && !this.state._addAsFavourite &&
						<FontAwesomeIcon icon="heart-broken" size="2x" color="darkgrey"
						className="protip favourite-product-icon cursor-cell" data-pt-position="left"
						data-pt-scheme="black" data-pt-classes="my-tooltip"
						data-pt-title="Remove as Favourite"
						onClick={evt => this.removeAsFavourite(evt, product.header.id)} />}

					{ this.props.userID > 0 && this.state._addAsFavourite &&
						<FontAwesomeIcon icon="heart" size="2x" color="darkred"
						className="protip favourite-product-icon cursor-cell" data-pt-position="left"
						data-pt-scheme="black" data-pt-classes="my-tooltip"
						data-pt-title="Add as Favourite"
						onClick={evt => this.addAsFavourite(evt, product.header.id)} />}

				</div> }

				{/*
						------------------------------------------------
						Photo above - works well if images are landscape
						------------------------------------------------
					*/}
				{ this.props.__preferProductImagesAbove == 1 &&

				<div className="force-relative cell small-8 small-offset-2 center-image-container">

					{/* D U P L I C A T E  of above as React won't allow conditional --split-- DIVs */}

					{ !_.isEmpty(product.IMAGE.PRIMARY_PHOTO) && _.isEmpty(product.IMAGE.FLIP_PHOTO) &&
						<LazyLoad><img src={this.state._validatedImage}
							className="pointer"
							onClick={evt => this.redirectToFullPage(evt)}
							/></LazyLoad> }

					{ !_.isEmpty(product.IMAGE.PRIMARY_PHOTO) && !_.isEmpty(product.IMAGE.FLIP_PHOTO) &&
						<FlipPhoto
							id={product.header.id}
							front={this.state._validatedImage}
							back={product.IMAGE.FLIP_PHOTO}
							onClick={evt => this.redirectToFullPage(evt)}
							/> }

					{ product.header.sold_out_sign == 1 && <img src="images/sold.png" className="sold-out-overlay" /> }

					{ product.header.auction_reserve > product.header.price
						&& <img src="images/reserve-not-met.png" className="sold-out-overlay" /> }

					{/* Revised style design - have the full image and fav icons top right corner of the image */}

					<FontAwesomeIcon icon="image" size="2x"
						className="highlight-colour protip full-image-product-icon glass" data-pt-position="top"
						data-pt-scheme="black" data-pt-classes="my-tooltip"
						data-pt-title="Full Image"
						onClick={evt => this.clickLightBox(evt)} />

					{/* Favourite Options with a tooltip */}

					{ this.props.userID > 0 && !this.state._addAsFavourite &&
						<FontAwesomeIcon icon="heart-broken" size="2x" color="darkgrey"
						className="protip favourite-product-icon cursor-cell" data-pt-position="left"
						data-pt-scheme="black" data-pt-classes="my-tooltip"
						data-pt-title="Remove as Favourite"
						onClick={evt => this.removeAsFavourite(evt, product.header.id)} />}

					{ this.props.userID > 0 && this.state._addAsFavourite &&
						<FontAwesomeIcon icon="heart" size="2x" color="darkred"
						className="protip favourite-product-icon cursor-cell" data-pt-position="left"
						data-pt-scheme="black" data-pt-classes="my-tooltip"
						data-pt-title="Add as Favourite"
						onClick={evt => this.addAsFavourite(evt, product.header.id)} />}


				</div> }

				{/* Photo above - extra line break */}
				{ this.props.__preferProductImagesAbove == 1 && <div className="cell small-12"><br /></div> }

				<div className="cell small-12 mobile-display">&nbsp;</div>

				{/* Details to the right or below [small] */}
				<div className={_conditionalLayout}>

					{/*
							Standard Header Name and Description of product
					*/}

					<div className="product-name highlight-colour pointer"
							onClick={evt => this.redirectToFullPage(evt)}>
							<Translate text={product.header.name} />
					</div>

					{/* Catch empty strings for truncation */}

					{ _truncate == 'MAX' && !_.isEmpty(product.header.description) &&
						<div className="product-description-condensed pointer"
							onClick={evt => this.redirectToFullPage(evt)}>
							<div dangerouslySetInnerHTML={{__html: product.header.description.substring(0,140) }} />
							<span className="bold black">
							&nbsp;<Translate text="...more" /></span>
						</div> }

					{ _truncate == 'MIN' && !_.isEmpty(product.header.description) &&
						<div className="product-description-condensed pointer"
							onClick={evt => this.redirectToFullPage(evt)}>
							<div dangerouslySetInnerHTML={{__html: product.header.description.substring(0,400) }} />
							<span className="bold black">
							&nbsp;<Translate text="...more" /></span>
						</div> }

					{ _truncate == 'NONE' &&
						<div className="product-description pointer"
							onClick={evt => this.redirectToFullPage(evt)}>
							<div dangerouslySetInnerHTML={{__html: product.header.description }} />
						</div> }

					{/* Sub Grid - Table like....

							These are the attributes flagged as `show_in_main_display`

					*/}

					<div className="cell small-12">

						<div className="grid-x">

							{/* Loop of show_in_main_display */}

							<div className="cell small-12">
									{product.attributes.map( (attribute, key) => {

											{/* Ignore those attributes marked as not required */}
											if ( !(attribute.include_in_main_display) ) return null;

											{/* Base ClassNames appreciate fiddle with pixel-nudge-down-3px ! Zurb ?? */}
											var _nameClassName = "cell small-8 smaller-text pixel-nudge-down-3px no-select";
											var _valueClassName = "cell no-select";

											{/* Major Adjustment */}
											if ( attribute.highlight )	{
													_valueClassName += ' small-12 highlight-attribute highlight-colour';
											} else {
													_valueClassName += ' small-4 bold smaller-text';
											}

											{/* Minor adjustments */}
											if ( attribute.show_in_colour ) {
													_valueClassName += ' highlight-colour';
											}

											if ( attribute.strike_thru )	{
													_valueClassName += ' strike-thru';
													_nameClassName += ' strike-thru';
											}

											return (
												<div key={key} className="grid-x">

													{/* Small line break before a highlighted attribute - note the == true ! */}
													{attribute.highlight == true && <div className="cell small-12 smaller-text">&nbsp;</div>}

													{/* The name of the Attribute, e.g. Material */}
													{!attribute.highlight &&
														<div className={_nameClassName}>{attribute.key_name}</div>}

													{/* Open Store - The value of the Attribute, e.g. Silk, Stock/Available value */}
													{(this.props.__invitationOnly == 0
														|| this.props.userApproved == 1
														|| attribute.key_name != this.props.__productPriceKey)
														&& <div className={_valueClassName}>
														{ formatEAV(attribute.key_value, attribute.data_type, this.props.__currencySymbol) }
													</div>}

													{/* Price Key Only -- as above -- Invitation Store - but the user is NOT approved */}
													{	this.props.__invitationOnly == 1
														&& this.props.userApproved == 0
														&& attribute.key_name == this.props.__productPriceKey
														&& <div className={_valueClassName}>
														P.O.A.
													</div>}

													{/* Small line break after a highlighted attribute - you need the == true ! */}
													{attribute.highlight == true && <div className="cell small-12 smaller-text">&nbsp;</div>}

												</div>
											)

									}) }
							</div>

						</div>

					</div>

				</div>

				{/*
						Quantity and BUY | BID buttons...
				*/}

				<div className="cell small-12 bottom-of-container">
					<div className="grid-x">

						<div className="cell small-6 text-center">
							<br />
							<div className="input-group">
							  <span className="input-group-label no-select"><Translate text="No." /></span>

								{ !(this.state._auction) && <input className="input-group-field" type="number" min="1" defaultValue={this.state._quantity}
										onChange={evt => this.setQuantity(evt)} /> }

							  { this.state._auction && <input className="input-group-field" type="number" min="1" defaultValue={this.state._quantity}
										disabled /> }

							</div>

						</div>

						<div className="cell small-1 text-center">
							<br />
							{ this.state._showTick && <FontAwesomeIcon icon="check" size="1x" color="black" /> }
							{ this.state._showCross && <FontAwesomeIcon icon="times" size="1x" color="red" /> }
						</div>

						<div className="cell small-5 text-center">
							<br />

							{/* Standard B U Y button - Open Shop */}

							{ !(this.state._auction) &&
								this.props.__invitationOnly == 0 &&
								<button className="button large rounded bordered warning warning-colour large-mobile-button"
								onClick={evt => this.addStandardProductToCart(evt)}>
									{ this.state._bought == false && <Translate text="BUY" /> }
									{ this.state._bought == true && <FontAwesomeIcon icon="shopping-cart" /> }
								</button> }

							{/* Standard B U Y button - Invitation Shop with User Approved */}

								{ !(this.state._auction) &&
								this.props.__invitationOnly == 1 &&
								this.props.userApproved == 1 &&
								<button className="button large rounded bordered warning warning-colour large-mobile-button"
								onClick={evt => this.addStandardProductToCart(evt)}>
									{ this.state._bought == false && <Translate text="BUY" /> }
									{ this.state._bought == true && <FontAwesomeIcon icon="shopping-cart" /> }
								</button> }

							{ (this.state._auction) && <FontAwesomeIcon className="not-mobile" icon="gavel" size="2x" color="grey" /> }
							&nbsp; &nbsp;

							{/* User OK to make a Bid */}
							{ this.props.userID > 0 && this.state._auction && <button className="button large rounded bordered warning auction-colour large-mobile-button"
									onClick={evt => this.makeStandardProductBid(evt)}>
									<Translate text="BID" />
								</button> }

							{/* User must be active to make a Bid */}
							{ this.props.userID <= 0 && this.state._auction &&
								<Link to="/register">
									<button className="button large rounded bordered warning auction-colour large-mobile-button">
											<Translate text="BID" />
									</button>
									<div className="small-text black bold"><Translate text="Register | Login" /></div>
								</Link> }

						</div>

					</div>
				</div>

				{/* _callAddToCart is a state flag which triggers <AddToCart ../>. This is
					set when the user clicks the above addStandardProductToCart() onClick.
					This product has been checked as not having any selectable options */}

				{this.state._callAddToCart && this.state._quantity >= 1 &&
					<AddToCart
							PRODUCT_ID={product.header.id}
							QUANTITY={this.state._quantity}
							OPTIONS={null}
							BUNDLE={false}
							onSuccess={this.showBuySuccess.bind(this)}
							onFail={this.showBuyFail.bind(this)}
					/>}

				{ this.state._clickLightbox &&
					<MyLightbox
							src={product.IMAGE.PRIMARY_PHOTO}
							onClose={this.closeLightBox.bind(this)}
					/> }

				{/* ----------------------------- Slider Window ------------------------------ */}

				<div className="grid-x grid-margin-x">
						<div className="cell small-10 small-offset-1">
		            {this.state._makeBid &&
									<MakeAuctionBid
										PRODUCT={product}
										DISPLAY={this.state._validatedImage}
										/>}
          	</div>
				</div>

			</div>
		);
	}

}

// Set the default values
ProductDisplay.defaultProps = {
	__preferProductImagesAbove: reduxStore.getState().__preferProductImagesAbove,
	__currencySymbol: reduxStore.getState().__currencySymbol,
	__invitationOnly: reduxStore.getState().__invitationOnly,
	__productPriceKey: reduxStore.getState().__productPriceKey,
	locale: reduxStore.getState().locale,
	userID: reduxStore.getState().userID,
	userApproved: reduxStore.getState().userApproved,
	slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			__preferProductImagesAbove: state.__preferProductImagesAbove,
			__currencySymbol: state.__currencySymbol,
			__invitationOnly: state.__invitationOnly,
			__productPriceKey: state.__productPriceKey,
			locale: state.locale,
			userID: state.userID,
			userApproved: state.userApproved,
			slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(ProductDisplay);
