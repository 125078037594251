// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';									// Required by some|most PSPs
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import Translate from 'Translate';
import PleaseWait from 'PleaseWait';

import Popup from "reactjs-popup";
import { BackgroundImage } from "react-image-and-background-image-fade";

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayKasssh extends React.Component {

	constructor(props, defaultProps) {
		    super(props, defaultProps);
        this.state = {
						_promise: false,
						_openKassshCheckout: false,
						_confirmedKassshCheckout: false,
						_pleaseWait: false,
						_javascriptPopup: false,
						_kassshStoreID: 0,
						_kassshKey: '',
						_kassshClientToken: '',

						/* Address Details */
						_title: '',
						_givenName: '',
						_familyName: '',
						_address: '',
						_city: '',
						_county: '',
						_postCode: '',
						_phone: '',

						addressKeys: [],          	// Array of ALL allowed address Keys
						addressData: []           	// Array of THIS users addresses

        };
				// Required ?
				this.loadKassshPopup = this.loadKassshPopup.bind(this);

				// We do need to bind this to these external class f()s though
				kassshOnSuccess = kassshOnSuccess.bind(this);
				kassshOnFail = kassshOnFail.bind(this);
	}

	componentDidMount () {

		this._isMounted = true;

		this.loadKassshPopup();

		// Establish if Kasssh is being used...
		if (this._isMounted) {

				// Determine if Kasssh is installed and can be used
				MyFetchGet( process.env.API + '/setting/' + 'KASSSH-LIVE', 3 )
		        .then( response => response.json() )
		        .then( setting => {

								if ( !_.isEmpty(setting) && setting != -1  ) {			// Kasssh not installed

									MyFetchGet( process.env.API + '/setting/' + 'KASSSH-STORE-ID', 3 )
							        .then( response => response.json() )
							        .then( _id => {
														// console.log('kasssh | Store ID: ' + _id);
														this.setState( { _kassshStoreID: _id } );

														if ( setting == 0 )
																var _apiKassshHMAC = process.env.API + '/setting/' + 'KASSSH-HMAC-TEST';
														else
																var _apiKassshHMAC = process.env.API + '/setting/' + 'KASSSH-HMAC-LIVE';

														MyFetchGet( _apiKassshHMAC, 3 )
												        .then( response => response.json() )
												        .then( _id => {
																			// console.log('kasssh | Key: ' + _id);
																			this.setState( { _kassshKey: _id } );

																			MyFetchGet( process.env.API + '/customer/addresses', 3)
																					.then( response => response.json() )
																					.then( apiAddressKeysData => {

																							// console.log(apiAddressKeysData);
																							this.setState( {addressKeys : apiAddressKeysData} );

																							// Get the data for this user
																							MyFetchGet(process.env.API + '/customer/addresses/get/' + this.props.userID, 3)
																								.then( response => response.json() )
																								.then( apiAddressData => {

																									if (apiAddressData.length ) {
																										this.setState( {addressData : apiAddressData } );

																										var address_key_name = apiAddressKeysData[0].address_key;
																										var postcode_key_name = apiAddressKeysData[0].postcode_key;

																										// console.log('kasssh | Address: ' + apiAddressData[0][address_key_name]);
																										// console.log('kasssh | ZIP: ' + apiAddressData[0][postcode_key_name]);
																										this.setState( {_address : apiAddressData[0][address_key_name] } );
																										this.setState( {_postCode : apiAddressData[0][postcode_key_name] } );

																										MyFetchGet(process.env.API + '/customer/get/' + this.props.userID, 3)
																										    .then( response => response.json() )
																												.then( customer => {
																													if ( !_.isEmpty(customer) ) {

																															//
																															//	H A R D   C o d e
																															//

																															// Create the first and last names from a validated name
																															var _words = customer.attributes.FULL_NAME.value.split(' ');
																															if ( _words.length < 2 ) {			// Should NOT happen if validated
																																var _firstName = '';
																																var _lastName = '';
																															} else {
																																var _firstName = _words[0];
																																var _lastName = _words[_words.length - 1];
																															}

																															// console.log('kasssh | First Name: ' + _firstName);
																															// console.log('kasssh | Last Name: ' + _lastName);
																															// console.log('kasssh | Phone: ' + customer.attributes.MOBILE.value);
																															this.setState( { _givenName: _firstName} );
																															this.setState( { _familyName: _lastName} );
																															this.setState( { _phone: customer.attributes.MOBILE.value } );

																															// -- F I N A L L Y --
																															// kasssh is ready to go....
																															this.setState( { _openKassshCheckout: true } );

																													}
																												})
																												.catch( error => {
																														console.log( error );
																												});

																									}

																							})
																							.catch( /* Should try again */ )
																					})
																					.catch( /* Should try again */ );

																});

											});

								}

		        })
		        .catch( /* Should try again */ );

		}

	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			// this._isMounted = false;
	}

	loadKassshPopup () {

		// console.log('Load kasssh popup.js...');
		const script = document.createElement("script");
    script.src = "https://api.kasssh.com/popup/v1/popup.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    if (script.readyState) {
		      // IE
		      script.onreadystatechange = () => {
				        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
					          script.onreadystatechange = null;
										// console.log('Legacy | popup.js loaded');
					          this.setState( { _javascriptPopup: true } );
				        }
		      };
    } else {
		      // Chrome etc...
		      script.onload = () => {
							// console.log('Modern | popup.js loaded');
		        	this.setState( { _javascriptPopup: true } );
		      };
    }

	}

	openKassshCheckout ( evt ) {

		// First off get data required for the popoup
		var _data = {
					store_id: this.state._kassshStoreID,
					key: this.state._kassshKey,
					order_reference: this.props.cartID,
					amount: 100 * (this.props.cartValue*1 + this.props.deliveryCharge*1 + this.props.VATCharge*1),
					currency: this.props.currency,
					email: this.props.userEmail,
					first_name: this.state._givenName,
					last_name: this.state._familyName,
					mobile: this.state._phone,
					address: this.state._address,
					zip: this.state._postCode,
					description: 'vShop Cart'
				};

		// Open the lightbox Popup | confirm eMail send 4th parameter
		// Note that I am making On..f() external to this class for scope
		// otherwise kassshPopup won't see them !
		kassshPopup( _data, kassshOnSuccess, kassshOnFail, true );

	}

	//
	// ---------------------------------------------------------------------------
	//

	render () {

		{/* _promise is a state flag which triggers <Redirect to PaymentPromised ../>. This is
			set when the user has promised-to-pay and the kassshOnSuccess shows a promise status of 1/true */}

		if (this.state._promise) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-promised/${_amount}`} push />);
		}

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		if ( !this.state._javascriptPopup )			// Wait...debug
				return (<div></div>);

		if ( !this.state._openKassshCheckout )			// Wait...debug
				return (<div></div>);

		// Shows the button for checkout
		return (
			<div className="custom-checkout-button">
					<img src="https://api.kasssh.com/popup/buttons/pay-kasssh-checkout.png" className="pointer"
						onClick={ evt => this.openKassshCheckout( evt ) }/>
			</div>
		);

	}

}

// Set the default values
PayKasssh.defaultProps = {
	userID: reduxStore.getState().userID,
	userEmail: reduxStore.getState().userEmail,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userID: state.userID,
			userEmail: state.userEmail,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

// External visible callbacks for kassshPopup; note that we have bound (this) to this f()

function kassshOnSuccess( _reply ) {

	// We can use this as see above bind().
	var _data = { cartID: this.props.cartID };

	MyFetchPost( process.env.API + '/payments/kassshpromise', _data, 1)
		.then( response => response.json() )
		.then( reply => {
					console.log( reply );
					// and now show this as a `promise` -> redirects page in `render`
					this.setState( { _promise: true } );
		});

}

function kassshOnFail( _reply ) {
	// console.log('Arrived at kassshOnFail');
	if ( _reply )
		console.log( _reply );
	// ....
}

export default connect(mapStateToProps)(PayKasssh);
