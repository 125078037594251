// Use ES6 Class

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';
import ProductSearch from 'ProductSearch';
import SelectAddressOnCheckout from 'SelectAddressOnCheckout';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';
import PleaseWait from 'PleaseWait';
import IPLog from 'IPLog';

import PayStripe from 'PayStripe';
import PayPayPal from 'PayPayPal';
import PayAfrica from 'PayAfrica';
import PayAdult from 'PayAdult';
import PayKlarna from 'PayKlarna';
import PayKasssh from 'PayKasssh';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


class Checkout extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        		_calculatedDelivery: null,				// Set as null to show no Calc YET
						_calculatedVAT: null,							// Ditto
						_pleaseWait: true
				};
	}

	componentDidMount () {

		// This is for an Address change
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		this._isMounted = true;

		// Load initial API Data with getDeliveryCharge [also includes Address]
		if (this._isMounted) {
				var APIUrl = process.env.API + '/logistics/getdeliverycharge/' + this.props.cartID;
				MyFetchGet(APIUrl, 3)
				    .then( response => response.json() )
				    .then( delivery => {

							if ( this._isMounted ) {

									this.setState( {_calculatedDelivery : delivery.calculated_cost} );

									setReduxValue(reduxStore, 'SET_DELIVERY_ADDRESS', delivery.address );
					        var action1 = {
					            type: 'SET_DELIVERY_ADDRESS',
					            deliveryAddress: delivery.address
					        };
									setReduxValue(reduxStore, 'SET_DELIVERY_POSTCODE', delivery.postcode );
					        var action2 = {
					            type: 'SET_DELIVERY_POSTCODE',
					            deliveryPostcode: delivery.postcode
					        };
					        // Note that we apply the dispatch to the props: mapStateToProps(state)
							    this.props.dispatch(action1);
									this.props.dispatch(action2);

									// VAT in play ?
									if ( this.props.__VATrate != 0) {
											// GET tax on all cart items
											var APIUrl = process.env.API + '/cart/gettax/' + this.props.cartID;
											MyFetchGet(APIUrl, 3)
											    .then( response => response.json() )
											    .then( tax => {
															this.setState( {_calculatedVAT : tax.toFixed(2)} );
													});
									} else {
											this.setState( {_calculatedVAT : 0.00} );
									}

									// OK, so we are now good with _calculatedDelivery && _calculatedVAT

							}
				    })
				    .then( () => {
                  // Scroll Top
                  window.scrollTo(0, 0);
                  this._isMounted = false;
				    })
						.then( () => {
								this.setState( { _pleaseWait: false } );
						})
						.catch( error => {
								this.setState( { _pleaseWait: false } );
						});

		}
	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	selectAddress (evt) {

		// Activate an Address change
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: true
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
	}

	render () {

		{/* Weird catch, but it can happen */}
		if (this.props.cartID < 1) {
			return (<Redirect to={`/home`} push />);
		}

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		{/* Wait for these calcs before proceeding */}
		if ( this.state._calculatedDelivery == null || this.state._calculatedVAT == null)
			return (<div></div>)

		return (
			<div className="flex-site">
				<IPLog URL="/#/checkout" />
				<div className="site-content">
					<Nav />
					<MegaMenu />
					<ProductSearch />

					<div className="page-title text-center no-select">

						<Translate text="Checkout or" /> &nbsp;&nbsp;

						<Link to="/cart">
							<button className="no-select hollow button large secondary">
								<Translate text="Back to Cart" />
							</button>
						</Link>

					</div>

					<div className="grid-x grid-margin-x">
							<div className="cell small-10 small-offset-1 medium-4 medium-offset-1 large-4 large-offset-2">
									<span className="no-select">
										<Translate text="Deliver to" />
									</span>
									<div className="light-grey-background bold product-attribute-box">
										{ this.props.deliveryAddress }
									</div>
							</div>
							<div className="cell small-5 small-offset-1 medium-3 medium-offset-0 large-2 large-offset-1">
									<span className="no-select">
										<Translate text="Postcode" />
									</span>
									<div className="light-grey-background bold product-attribute-box">
										{ this.props.deliveryPostcode }
									</div>
							</div>

							<div className="cell small-4 medium-4 large-3 center">
									<br />
									<button className="button no-select hollow secondary large-mobile-button"
											onClick={evt => this.selectAddress(evt)}>
											<Translate text="Different Address ?" />
									</button>
							</div>
					</div>

					<br />
					<div className="grid-x grid-margin-x">

								{/* Delivery Charge */}
								<div className="cell small-12 center bold">
										<div>
												<span className="no-select">
													<FontAwesomeIcon icon="plus" size="2x" color="grey" className="nudge-down-1"/>
													&nbsp;&nbsp;
													<Translate text="Delivery" />
												</span>
												&nbsp;&nbsp;

													{this.props.__currencySymbol} {Intl.NumberFormat('en-US', { minimumFractionDigits:2 })
														.format(this.state._calculatedDelivery*1.0)}

										</div>
								</div>

								{/* ! VAT ! */}
								{ this.state._calculatedVAT && <div className="cell small-12 center bold">
										<div>
												<span className="no-select">
													<FontAwesomeIcon icon="plus" size="2x" color="grey" className="nudge-down-1"/>
													&nbsp;&nbsp;V.A.T.</span>&nbsp;&nbsp;

													{this.props.__currencySymbol} {Intl.NumberFormat('en-US', { minimumFractionDigits:2 })
														.format(this.state._calculatedVAT*1.0)}

										</div>
								</div> }

								<div className="cell small-12 center bold">
										<div>
												<span className="no-select">
													<Translate text="Payment required" />
												</span>
												&nbsp;&nbsp;
												<span className="nav-bar-cart-total red">
												{this.props.__currencySymbol} {Intl.NumberFormat('en-US', { minimumFractionDigits:2 })
													.format(this.props.cartValue*1.0 + this.state._calculatedDelivery*1.0 + this.state._calculatedVAT*1.0)}
												</span>
										</div>
										<br />
										<p className="wide-container highlight-colour">
											When you checkout or PAY, the amount will be ultimately billed at the standard card
											rate in your LOCAL currency, e.g. US Dollars
										</p>
								</div>

						</div>

						{/* The below table is actually rather smart in that it my version of `stack` - see CSS */}

						<table className="stack unstriped">

							<tbody>
								<tr>
									<td className="center">
											<PayPayPal
												deliveryCharge={this.state._calculatedDelivery}
												VATCharge={this.state._calculatedVAT}
												currency={this.props.__currencyCode} />
									</td>

									<td className="center">
											<PayKasssh
												deliveryCharge={this.state._calculatedDelivery}
												VATCharge={this.state._calculatedVAT}
												currency={this.props.__currencyCode} />
									</td>

									<td className="center">
											<PayStripe
												deliveryCharge={this.state._calculatedDelivery}
												VATCharge={this.state._calculatedVAT}
												currency={this.props.__currencyCode} />
									</td>

									<td className="center">
											<PayAdult
												deliveryCharge={this.state._calculatedDelivery}
												VATCharge={this.state._calculatedVAT}
												currency={this.props.__currencyCode} />
									</td>

									<td className="center">
											<PayAfrica
												deliveryCharge={this.state._calculatedDelivery}
												VATCharge={this.state._calculatedVAT}
												currency={this.props.__currencyCode} />
									</td>

									<td className="center">
											<PayKlarna
												deliveryCharge={this.state._calculatedDelivery}
												VATCharge={this.state._calculatedVAT}
												currency={this.props.__currencyCode} />
									</td>



								</tr>
							</tbody>

						</table>

				</div>

				<div id="SelectAddress" className="grid-x grid-margin-x">
						<div className="cell small-10 small-offset-1">
		            {/* Conditional on active SlideWindow */}
		            {this.props.slideWindow && <SelectAddressOnCheckout CUSTOMER_ID={this.props.userID} />}
          	</div>
				</div>

				<br />&nbsp;

				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values
Checkout.defaultProps = {
    cartID: reduxStore.getState().cartID,
		cartValue: reduxStore.getState().cartValue,
		userID: reduxStore.getState().userID,
    slideWindow: reduxStore.getState().slideWindow,
    deliveryAddress: reduxStore.getState().deliveryAddress,
		deliveryPostcode: reduxStore.getState().deliveryPostcode,
		__VATrate: reduxStore.getState().__VATrate,
		__currencyCode: reduxStore.getState().__currencyCode,
		__currencySymbol: reduxStore.getState().__currencySymbol
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        cartID: state.cartID,
        cartValue: state.cartValue,
        userID: state.userID,
        slideWindow: state.slideWindow,
				deliveryAddress: state.deliveryAddress,
				deliveryPostcode: state.deliveryPostcode,
				__VATrate: state.__VATrate,
				__currencyCode: state.__currencyCode,
				__currencySymbol: state.__currencySymbol
    }
}

export default connect(mapStateToProps)(Checkout);
