// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

/*
Props are:
	blockTitle:		The CMS Block identified by Name/Title
	Gets a CMS Block/Page content [Rich Text] via the block's Title or name

	V2. Allows for translation assuming text is tagged with the class `vtranslate`
*/

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


class CMSBlock extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	blockData : '',
					randomID: Math.floor((Math.random() * 1000000) + 1)		// Random no. up to 1 million
        };
	}

	componentDidMount () {

    this._isMounted = true;

		if ( _.isEmpty(this.props.blockTitle) ) return false;

		// Load initial API Data from the CMS. This has a tendency to timeout on large loads !

		var APIUrl = process.env.API + '/cms/getblock/' + this.props.blockTitle;
    MyFetchGet( APIUrl, 3)
		    .then( response => response.json() )
		    .then( apiBlockData => {
					if (this._isMounted) {
							this.setState( {blockData : apiBlockData} );
					} else {
							// console.log('CMS GetBlock has unMounted for ' + this.props.blockTitle);
					}
		    })
				.then( () => {

						// Don't bother with translation if not enabled
						if ( this.props.__multiLanguage == 1 ) {

								// We need local copies of these as not scoped in the below f()
								var _locale = this.props.locale;
								var _APIKey = this.props.__translateAPIKey;

								// Translate anything in this CMS Block DIV with a class of vtranslate
								$('#' + this.state.randomID + ' .vtranslate').each( function() {

											var _textToTranslateJQuery = $(this);
									    var _english = _textToTranslateJQuery.text();

											var _data = {
												text: _english,
												locale: _locale,
												key: _APIKey
											};

											MyFetchPost( process.env.API + '/vtranslate', _data, 1)
													.then( response => response.json() )
													.then( translation => {
														// jQuery Replace text
														// console.log( translation );
														_textToTranslateJQuery.text( translation );
													});

								});

						}
				})
				.catch(
					(error) => { console.log( error ) }
				)
	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		if ( _.isEmpty(this.state.blockData) || _.isEmpty(this.state.blockData.content) )	return (<div></div>);

		return (
			<div id={this.state.randomID} className={this.props.className}
				dangerouslySetInnerHTML={{__html: this.state.blockData.content }} />
		);
	}

}

// Set the default values
CMSBlock.defaultProps = {
  __multiLanguage: reduxStore.getState().__multiLanguage,
	__translateAPIKey: reduxStore.getState().__translateAPIKey,
	locale: reduxStore.getState().locale
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
  			__multiLanguage: state.__multiLanguage,
				__translateAPIKey: state.__translateAPIKey,
				locale: state.locale
    }
}

export default connect(mapStateToProps)(CMSBlock);
