// Use ES6 Class

import React from 'react';
import { BrowserView,  MobileView,  isBrowser,  isMobile } from "react-device-detect";

class Iframe extends React.Component {

	render () {

		return(
			<div>
				<iframe src={this.props.src} height={this.props.height} width={this.props.width} marginWidth="0" frameBorder="0"/>
			</div>
		);
	}

}

export default Iframe;
