// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import ProductDisplay from 'ProductDisplay';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Display all the products within this top level Class ---
	--- This is only used on Custom Pages ---
	Prop: selectedProductClass
*/

class ProductListByClass extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	productsData : null,
					_stateProductClass: props.selectedProductClass
        };
	}

	//
	//	In line with new React V17 and stability, we are now to use getDerivedStateFromProps ()
	//

	// Note that this must be static
	static getDerivedStateFromProps(props, state) {
	    // Clear out previously-loaded data (so we don't render stale stuff).
	    if (props.selectedProductClass !== state._stateProductClass) {
					return {
		        productsData: null,
		        _stateProductClass: props.selectedProductClass,
		      };
	    }

	    return null;
	}

	loadAPIData () {

		// Load initial API Data
		if (this._isMounted) {
			var APIUrl = process.env.API + '/product/getclass/' + this.state._stateProductClass;
			MyFetchGet(APIUrl, 3)
			    .then( response => response.json() )
			    .then( apiProductsData => {
						if ( this._isMounted) {
							// console.log( apiProductsData );
							this.setState( {productsData : apiProductsData} );
						}
			    })
					.then( () => {
							this._isMounted = false;
					})

		}

	}

	componentDidMount () {

		this._isMounted = true;
		this.loadAPIData();

	}

	// Yes ! a duplicate of the above....!

	componentDidUpdate () {

		this._isMounted = true;
		this.loadAPIData();

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		if ( _.isEmpty(this.state.productsData) ) return (<div></div>);

		return (
			<div className="grid-x grid-margin-x">

				{this.state.productsData.map( (product, key) => {

					switch(this.props.displayGrid) {

						// 3x3
						case 0:
							return(
								<div key={key} className="cell small-12 medium-6 large-4">
					    		<ProductDisplay product={product} truncate='MAX' />;
					    	</div>)

						// 2x2
						case 1:
							return(
								<div key={key} className="cell small-12 medium-6 large-6">
					    		<ProductDisplay product={product} truncate='MIN' />;
					    	</div>)

						// 1x1
				    case 2:
							return(
								<div key={key} className="cell small-12 medium-12 large-12">
					    		<ProductDisplay product={product} truncate='NONE' />;
					    	</div>)

						// unknown - should never happen ?
				    default:
							return (<div key={key}></div>)

				  }

				})}

			</div>
		);
	}

}

// Set the default values
ProductListByClass.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(ProductListByClass);
