// ES6

import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

// -------------- vShop Useful f() Library --------------------

export function formatEAV(_value, _dataType, _symbol) {

  if ( _.isArray(_value) ) return 'Select';

  if ( _.isEmpty(_value) ) return 'N/A';

  // Just a string [is Not a Number]
  if ( isNaN(_value) || _dataType == 'TEXT') {
    return _value;
  } else {
      if (_dataType == 'CURRENCY')
        var _optionalSymbol = _symbol + ' ';
      else
        var _optionalSymbol = ''

      // Manage Decimals
      if ( _value % 1 != 0 ) {
        // so for example 19.5 is shown as 19.50 [and large No commas]
        return (_optionalSymbol + Intl.NumberFormat('en-US', { minimumFractionDigits:2 }).format(_value));

      } else {
        // Integer with commas for large numbers
        return (_optionalSymbol + Intl.NumberFormat('en-US').format(_value));
      }

  }
}

//
//  System translate ALL tooltips - class has .protip - on a page
//

export function translateTooltips( _locale, _optionalKey) {

  return false;

  // Translate all pt-title data-elements for elements of class .protip
  $('.protip').each( function() {

        var _tipJQuery = $(this);
        var _english = _tipJQuery.data('pt-title');

        var _data = {
          text: _english,
          locale: _locale,
          key: _optionalKey
        };

        MyFetchPost( process.env.API + '/vtranslate', _data, 1)
            .then( response => response.json() )
            .then( translation => {
                _tipJQuery.protipSet({ title: translation });
            })


  });

}

//
//  System translate ALL input placeholder='' on a page
//

export function translatePlaceholders( _locale, _optionalKey) {

  return false;

  // Translate all attr placeholder for elements of type `input`
  $('input').each( function() {

        var _inputJQuery = $(this);
        var _english = _inputJQuery.attr('placeholder');

        var _data = {
          text: _english,
          locale: _locale,
          key: _optionalKey
        };

        MyFetchPost( process.env.API + '/vtranslate', _data, 1)
            .then( response => response.json() )
            .then( translation => {
                _inputJQuery.attr('placeholder', translation);
            })


  });

}


// -------------- more.... --------------------
