// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import ProductDisplay from 'ProductDisplay';

import Translate from 'Translate';

/*
  --- Matching Products ---
  Props are:
	PRODUCT_ID		The ID of the core-product
*/


class MatchingProducts extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
          matchedproductsData: []
      };

  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
    // console.log('API Get Matching products for ' + this.props.PRODUCT_ID);
    if (this._isMounted) {
  		var APIUrl = process.env.API + '/product/matches/' + this.props.PRODUCT_ID;
  		MyFetchGet(APIUrl, 3)
  		    .then( response => response.json() )
  		    .then( apiProductsData => {
            // console.log(apiProductsData);
            if (this._isMounted)
  					     this.setState( {matchedproductsData : apiProductsData} );
  		    })
          .then( () => {
              this._isMounted = false;
          })
    }
	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

    if ( _.isEmpty(this.state.matchedproductsData) ) return (<div></div>);


    return (
      <div>
        <div className="page-title black">
          <Translate text="Matched Products" />
        </div>
  			<div className="grid-x grid-margin-x">

  				{this.state.matchedproductsData.map( (product, key) => {
  							return(
  								<div key={key} className="cell small-12 medium-6 large-4">
  					    		<ProductDisplay product={product} truncate='MAX' />;
  					    	</div>)
  				})}

  			</div>
      </div>
		);

	}

}

export default MatchingProducts;
