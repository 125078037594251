// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import CMSBlock from 'CMSBlock';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class CouponInfo extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
      };
  		this.closeInfo = this.closeInfo.bind(this);    
  }

	componentDidMount() {
    this._isMounted = true;
	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

  closeInfo () {

        setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
        var action1 = {
            type: 'SET_SLIDE_WINDOW',
            slideWindow: false
        };
        // Note that we apply the dispatch to the props: mapStateToProps(state)
		    this.props.dispatch(action1);

  }

	render () {

        return (
          <div className="slide-in-page-from-right">

              <div className="right-hand-side-close">
                <FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
                data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
                    onClick={evt => this.closeInfo(evt)} />
              </div>

              <div className="page-title black">
                <Translate text="Coupon Information" />
              </div>

              <CMSBlock blockTitle="Coupon-Policy" className="" />

          </div>
        );

	}

}

// Set the default values
CouponInfo.defaultProps = {
    slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(CouponInfo);
