// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserView,  MobileView,  isBrowser,  isMobile} from "react-device-detect";

class ScrollTop extends React.Component {

  scrollTop () {
    // The -150 is just to ensure that we scroll above any top margins; not really required
    $('html, body').animate({scrollTop: $('body').offset().top-150}, 2000);
  }

	render () {

		return(
      <div className="">
  			<div className="fixed-bottom-right-overlay" onClick={this.scrollTop}>
          <FontAwesomeIcon icon="arrow-circle-up" size="3x" color="deeppink" />
  			</div>
      </div>
		);
	}

}

export default ScrollTop;
