// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from 'Translate';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';
import ReactPixel from 'react-facebook-pixel';

// Animations if required
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class BelowNav extends React.Component {

	componentDidMount() {

	}

	render () {

		return (
			<div className="grid-x grid-margin-x">
				<div className="cell small-8 small-offset-2 medium-6 medium-offset-3 large-4 large-offset-4 center">

				</div>
			</div>
		);
	}

}

// Set the default values
BelowNav.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(BelowNav);
