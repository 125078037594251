// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';

import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

class NoMatch extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        };
	}

	// For a New Load which is always fine
	componentDidMount() {

		this._isMounted = true;


	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		return (
			<div className="flex-site">
				<div className="site-content">

						<Nav />
						<MegaMenu />
						<ProductSearch />

							<div className="grid-x grid-margin-x" >

								<div className="cell small-12 medium-12 large-12">
										<div className="flow-text center darkred">
												<h4><Translate text="Sorry, but this page or URL was not found" /></h4>
												<br /><br />
										</div>
						    </div>

							</div>

				</div>
				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

export default NoMatch;
