// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WriteReview from 'WriteReview';
import Translate from 'Translate';

/*
  --- A product review ---
  Props are:
	review			The review object
*/

class Review extends React.Component {

	render () {

		var review = this.props.review;
		// Oddly keep this condition to avoid any issues ????
		if ( _.isEmpty(review) ) return (<div></div>);

		var _stars = [], _i;

    for (_i=0; _i<review.stars; _i++) {
      _stars.push(<FontAwesomeIcon icon="star" size="2x" color="goldenrod" key={_i} />);
    }

		return (
			<div>
				<div className="grid-x grid-margin-x review-container">

					<div className="cell small-8 medium-6 large-3">
						{review.reviewed_by}<br />
						{review.created_at}<br />
						{_stars}<br />
					</div>
					<div className="cell auto">
							<strong>{review.review}</strong>
					</div>

				</div>

				{ !_.isEmpty(review.reply) &&
				<div className="grid-x grid-margin-x review-container">
					<div className="cell small-8 medium-6 large-3">
							<Translate text="We said..." />
					</div>
					<div className="cell auto">
							<em>{review.reply}</em>
					</div>
				</div> }

			</div>
		);
	}

}

export default Review;
