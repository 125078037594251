// Use ES6 Class

import React from 'react';

class PleaseWait extends React.Component {

	render () {

		return(
  			<div className="fixed-center-overlay">
          <img src="images/please-wait.gif" />
  			</div>

		);
	}

}

export default PleaseWait;
