// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Nav from 'Nav';
import Translate from 'Translate';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class ResetPassword extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					customerEmail: '',
					customerPassword: '',
					_newPassword: '',
					_confirmedPassword: '',
					_message: '',
					_showMessage: false
      };
			// Apply the f()s which update these:
			this._newPassword = this.updateNewPasswordValue.bind(this);
			this._confirmedPassword = this.updateConfirmedPasswordValue.bind(this);
  }

	componentDidMount() {
		// Scroll Top after render()
	  window.scrollTo(0, 0);

		var split =this.props.user.split(':');
		if ( split.length != 2 ) {
			// Not a valid URL
	  	this.setState( { _message: 'URL has an invalid format' } );
			this.setState( { _showMessage: true } );
			$('#ResetButton').prop('disabled', true);
			return false;
		}

		var emailAddressFromURL = split[0];
		this.setState( { customerEmail: emailAddressFromURL } );
		var hashedPasswordFromURL = split[1];

		// Confirm this is valid
		var _data = { email: emailAddressFromURL, password: hashedPasswordFromURL };

		MyFetchPost( process.env.API + '/customer/loginhash', _data, 3)
		    .then( response => response.json() )
				.then( customer => {
					console.log( customer );
						if ( _.isEmpty(customer) ) {
							// Not a valid email and password-hash combo
					  	this.setState( { _message: 'This reset link is no longer valid' } );
							this.setState( { _showMessage: true } );
							$('#ResetButton').prop('disabled', true);
							return false;
						};
				})

	}

	updateNewPasswordValue(evt) {
	  	this.setState( { _newPassword: evt.target.value } );
	}

	updateConfirmedPasswordValue(evt) {
	  	this.setState( { _confirmedPassword: evt.target.value } );
	}


	confirmPasswordReset(evt) {

	  	if ( this.state._newPassword != this.state._confirmedPassword ) {
				this.setState( { _message: 'Passwords do not match. Please re-type' } );
				this.setState( { _showMessage: true } );
				return false;
			}

			var _data = { email: this.state.customerEmail, password: this.state._newPassword };
			MyFetchPost( process.env.API + '/customer/updatepassword', _data, 3)
			    .then( response => response.json() )
					.then( hash => {
							if ( _.isEmpty(hash) ) {
								// Failed
						  	this.setState( { _message: 'System Error. Please contact us' } );
								this.setState( { _showMessage: true } );
								return false;
							} else {
								$('#ResetButton').prop('disabled', true);
								this.setState( { _message: 'Your password has been reset. Please Login' } );
								this.setState( { _showMessage: true } );
							}
					})

	}

	render () {
		return (
			<div className="flex-site">
				<div className="site-content">
					<Nav />

					<br /><br />
					<div className="message-alert">
							<FontAwesomeIcon icon="exclamation-triangle" size="2x" color="gold" />
							&nbsp;&nbsp; <Translate text="Change Password" />
					</div>
					<div className="small-text force-center">{this.state.customerEmail}</div>
					<br /><br />

					{/* New password */}
					<div className="grid-x">
						<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

							<div className="input-group">
								<span className="input-group-label"><FontAwesomeIcon icon="key" size="1x" className="black" /></span>
								<input onChange={evt => this.updateNewPasswordValue(evt)}
									className="input-group-field" type="password" placeholder="New password" />
							</div>

						</div>
						<div className="cell small-1 medium-3 large-4"></div>
					</div>

					{/* Confirmed password */}
					<div className="grid-x">
						<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

							<div className="input-group">
								<span className="input-group-label"><FontAwesomeIcon icon="key" size="1x" className="highlight-colour" /></span>
								<input onChange={evt => this.updateConfirmedPasswordValue(evt)}
									className="input-group-field" type="password" placeholder="Confirm password" />
							</div>

						</div>
						<div className="cell small-1 medium-3 large-4"></div>
					</div>

					<br />
					{/* button */}
					<div className="grid-x">
						<div className="cell flex-container align-center auto">

							<button id="ResetButton" className="hollow button alert large-mobile-button"
								onClick={evt => this.confirmPasswordReset(evt)}>
								<Translate text="Confirm new password" />
							</button>

						</div>
					</div>

					<br />
					{ this.state._showMessage &&	<div className="message-alert">
							<FontAwesomeIcon icon="info-circle" size="2x" color="grey" />&nbsp;&nbsp; 
								<Translate text={this.state._message} />
						</div> }
					<br /><br />

				</div>

				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values = current Redux state values
ResetPassword.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(ResetPassword);
