// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { Link } from 'react-router-dom';

import { translatePlaceholders } from 'Useful';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


class ProductSearch extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					_searchButton: 'Search'
      };
  }

	componentDidMount() {

		//
		//	One off word translate !
		//

		this._isMounted = true;

		var _data = {
	    text: 'Search',
	    locale: this.props.locale,
	    key: ''
	  };

	  MyFetchPost( process.env.API + '/vtranslate', _data, 1)
			.then( response => response.json() )
			.then( translation => {
				if (this._isMounted)
					this.setState( { _searchButton: translation } );
			});

		translatePlaceholders(this.props.locale);

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	productSearch(evt) {

		// Value or data from Google Style input box
		var _searchText = $('#ThisProductSearch').val();
		// vanilla JS with a pretty URL
		if (_searchText.length) {
			window.location.href = '/#/search/' + _searchText;
		} else {
			// clear _searchText
			setReduxValue(reduxStore, 'SET_SEARCH_TEXT', '');
	    // Set or dispatch [REDUX] action for prop
	    var action = {
	        type: 'SET_SEARCH_TEXT',
	        searchText: ''
	    };
	    // Note that we apply the dispatch to the props: mapStateToProps(state)
	    this.props.dispatch(action);
		}
	}

	render () {

		return (
			<div className="grid-x product-search">
				<div className="cell small-1"></div>
				<div className="cell small-1 medium-2 large-1 text-right">
					<br />
					<FontAwesomeIcon icon="search" size="2x"
						 className="nudge-down-1 not-mobile highlight-colour" />
					<FontAwesomeIcon icon="search" size="1x"
						 className="nudge-down-2 mobile-display highlight-colour" />
					&nbsp;&nbsp;
				</div>
				<div className="cell auto">
						<br />
						<div className="input-group">
						  <input id="ThisProductSearch" className="input-group-field" type="text"
								placeholder="Product keyword..." defaultValue={this.props.searchText} />

						  <div className="input-group-button">
						    <input type="submit" className="button warning warning-colour"
									value={this.state._searchButton}
									onClick={evt => this.productSearch(evt)}/>
						  </div>
						</div>
				</div>
				<div className="cell small-1"></div>
			</div>
		);
	}

}

// Set the default values = current Redux state values
ProductSearch.defaultProps = {
	locale: reduxStore.getState().locale,
	searchText: reduxStore.getState().searchText
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
				locale: state.locale,
        searchText: state.searchText
    }
}

export default connect(mapStateToProps)(ProductSearch)
