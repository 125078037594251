// Use new ES6 className

import React from 'react';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserView,  MobileView,  isBrowser, isMobile} from "react-device-detect";
import CategoriesDropDown from 'CategoriesDropDown';
import Translate from 'Translate';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class MegaMenu extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
            megaMenuArray : []
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// ('dotenv-webpack') allows us to read the `API` var from the /.env file as below:
		MyFetchGet( process.env.API + '/category/megamenu', 3 )
		    .then( response => response.json() )
		    .then( menu => {
					if (this._isMounted)
						this.setState( {megaMenuArray : menu} );

					// Foundation JS is a bitch with React and any JS requires initialisation like this
					// just like a classic document.ready()
					$(document).foundation();

		    })

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		{/* Don't display empty menus - a user can choose not to deploy mega-menus */}
		if ( !(this.state.megaMenuArray.length) ) return(<div></div>);

		return (
			<div>
        {/* Mega Menu is fine on Tablet/Desktop */}
				<div className="center-image-container menu-block not-small-tablet-flex">

					    <ul className="dropdown menu" data-dropdown-menu>

								{this.state.megaMenuArray.map( (e, key) => {

										var dropdownMenu = e.dropdowns;

										return (
											<li key={key} className="mega-menu">
								        <a data-toggle={e.title} className="menu-text" href="#">
													<Translate text={e.title} />
												</a>

                        {/* Kitchen Sink of options.... */}
								        <div className="dropdown-pane" data-position="bottom" data-alignment="center"
                          id={e.title} data-dropdown data-close-on-click="true" data-hover="true"
                          data-hover-pane="true" data-allow-overlap="false" data-auto-focus="false"
                          data-v-offset="20">

  								          <div className="grid-x">

                                {/* Show image if there is one and add in a column for spacing */}
                                { !_.isEmpty(e.image) &&  <div className="cell small-3 medium-2"><img src={e.image} /></div> }
                                { !_.isEmpty(e.image) &&  <div className="cell small-1 medium-1"></div> }

                                {/* Menu items allowing for the possible above image with auto */}
                                <div className="cell auto">
                                    <div className="grid-x">
        																{dropdownMenu.map( (e,key) => {
        																	var _link = '/#/products/' + e.key;
        																	return (
                                            <div key={key} className="cell small-12 medium-12 large-6">
                                              <a href={_link} className="">
                                                <span className="sub-menu-name expand-hover">
																									<Translate text={e.name} />
																								</span>
                                              </a>
                                            </div>
                                        );
        																})}
                                    </div>
                                </div>

  								          </div>

								        </div>
								      </li>

										);

								})}

					    </ul>

				</div>

        {/* Switch to Dropdown for mobile */}
				<CategoriesDropDown />

			</div>
		);
	}

}

// Set the default values = current Redux state values
MegaMenu.defaultProps = {
  selectedProductType: reduxStore.getState().selectedProductType
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        selectedProductType: state.selectedProductType
    }
}

export default connect(mapStateToProps)(MegaMenu);
