// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import CMSBlock from 'CMSBlock';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PromotionPopup extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
      };
			this.closePopup = this.closePopup.bind(this);
			this.acceptCoupon = this.acceptCoupon.bind(this);
  }

	closePopup () {

		setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
		var action1 = {
				type: 'SET_SLIDE_WINDOW',
				slideWindow: false
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

	}

	acceptCoupon () {

		//
		//		Apply the Coupon
		//

		var _data = {
			cart_id : this.props.cartID,
			coupon: this.props.coupon
		};

		MyFetchPost( process.env.API + '/coupon/apply', _data, 1)
				.then( response => response.json() )
				.then( response => {

						// The API does all the hard work and applies the discounts...
						this.closePopup();

						// Callback which just sets the Coupon in stone
						this.props.onAccept( response.coupon );

				})
				.catch (
					error => {
						console.log( 'Error from API' );
						this.closePopup();
					}
				)

	}

	render () {

		return (

			<div className="slide-in-page-from-right">

					<div className="right-hand-side-close">
						<FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
						data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
								onClick={ evt => this.closePopup(evt) } />
					</div>

					<div className="page-title black">
						<Translate text='Apply Coupon' />
						&nbsp;
						<span className="uppercase bold">{this.props.coupon}</span>
						&nbsp;?
					</div>

					<br /><br />

					{/* Yes or No */}

					<div className="grid-x grid-margin-x">

								<div className="cell small-10 small-offset-1 center">

											<button className="no-select hollow button large large-mobile-button strong-button alert"
													onClick={ evt => this.acceptCoupon() }>
												<Translate text="Yes" />
											</button>

											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

											<button className="no-select hollow button large large-mobile-button strong-button secondary"
													onClick={ evt => this.closePopup() }>
												<Translate text="No" />
											</button>

								</div>

					</div>

					{/* Disclaimer - Coupons */}

					<br /><br />

					<CMSBlock blockTitle="Coupons" className="" />

			</div>

		);
	}

}

// Set the default values
PromotionPopup.defaultProps = {
	cartID: reduxStore.getState().cartID,
	slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			cartID: state.cartID,
			slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(PromotionPopup);
