// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from 'ErrorBoundary';

import PaypalBtn from 'react-paypal-checkout';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayPayPal extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_payPalAppID : {},
					_environment: '',
					_shopName: 'Vadoo vShop',		// Default
					_locale: 'en_US',
					_currency: 'GBP',
					_payment: false							// Trigger
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data to retrieve PayPal settings
		if (this._isMounted) {

				var APIUrl = process.env.API + '/setting/' + 'PAYPAL-LIVE';
				MyFetchGet( APIUrl, 1)
		        .then( response => response.json() )
		        .then( apiDataValue => {
							// console.log('PayPal Live ?: ' + apiDataValue);
							if ( apiDataValue == -1 )
								return false;
							else if ( apiDataValue == 1 ) {

								if (this._isMounted)
									this.setState( {_environment: 'production' } );

									MyFetchGet( process.env.API + '/setting/' + 'PAYPAL-ID-LIVE', 10)
							        .then( response => response.json() )
							        .then( apiIDValue => {
												//console.log('PayPal Live App ID: ' + apiIDValue);
												if (this._isMounted)
													this.setState( {_payPalAppID: { sandbox: '' , production: apiIDValue } } );
							        })
							        .catch( /* Should try again */ );


							}	else {

								if (this._isMounted)
									this.setState( {_environment: 'sandbox' } );

								MyFetchGet( process.env.API + '/setting/' + 'PAYPAL-ID-TEST', 10)
						        .then( response => response.json() )
						        .then( apiIDValue => {
											//console.log('PayPal Sandbox App ID: ' + apiIDValue);
											if (this._isMounted)
												this.setState( {_payPalAppID: { sandbox: apiIDValue, production: '' } } );
						        })
						        .catch( /* Should try again */ );

							}

						})
				    .catch( /* Should try again */ );


				// Shop Name -> Email name
				var APIUrl2 = process.env.API + '/setting/' + 'EMAIL-NAME';
				MyFetchGet( APIUrl2, 10)
				    .then( response => response.json() )
				    .then( apiDataValue => {
								if (this._isMounted)
					          this.setState( { _shopName: apiDataValue } );
					  })
				    .catch( /* Should try again */ );

		}
	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}


	onSuccess (_payment) {

		console.log(_payment);

		var _data = {
			payment: _payment,
			email: this.props.userEmail,
			cartID: this.props.cartID,
			amount: this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1,
			currency: this.state._currency
		};

		MyFetchPost( process.env.API + '/payments/paypal', _data, 1)
			.then( response => response.json() )
			.then( paypal => {
					if ( paypal.response == 1 ) {
						this.setState( { _payment: true } );
					}
	    });

	}

	onCancel (data) {
		console.log('The payment was cancelled! ', data);
	}

	onError (err) {
		console.log('PayPal Error ! ', err);
	}

	render () {

		{/* _payment is a state flag which triggers <Redirect to PaymentReceived ../>. This is
			set when the user has paid and the onToken() shows a payment status of 1 */}

		if (this.state._payment) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-received/${_amount}`} push />);
		}

		if ( _.isEmpty(this.state._environment) || _.isEmpty(this.state._payPalAppID) )
			return (<div></div>);

		{/* The component doesn't allow a custom button, but this one is OK */}
		let _style = {
				'label':'paypal',
				'tagline': false,
				'size':'medium',
				'shape':'rect',
				'color':'white'
		};

		{/* Kept this debug in here */}
		if ( _.isUndefined(this.props.cartValue) || _.isUndefined(this.props.deliveryCharge) || _.isUndefined(this.props.VATCharge) )
				return (<div></div>);

		{/* This API requires that the amount is fixed to 2 decimal places and it's very fussy about it [see above] ! */}
		let _amount_to_pay = parseFloat( (this.props.cartValue * 1
			+ this.props.deliveryCharge * 1 + this.props.VATCharge * 1).toFixed(2) );

		{/* shipping:0 - optional address, shipping:1 means NONE, shipping:2 required */}

		/* Extreme Tests */
		if (this.state._environment == 'sandbox' && _.isEmpty(this.state._payPalAppID.sandbox) )
				return (<div></div>);

		if (this.state._environment == 'production' && _.isEmpty(this.state._payPalAppID.production) )
				return (<div></div>);


		return (
				<PaypalBtn
					env={this.state._environment}
					client={this.state._payPalAppID}
					shipping={1}
					currency={this.state._currency}
					total={_amount_to_pay}
					locale={this.state._locale}
					style={_style}
					onError={this.onError.bind(this)}
					onSuccess={this.onSuccess.bind(this)}
					onCancel={this.onCancel.bind(this)}
				/>
		);
	}

}

// Set the default values
PayPayPal.defaultProps = {
	userEmail: reduxStore.getState().userEmail,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userEmail: state.userEmail,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

export default connect(mapStateToProps)(PayPayPal);
