// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- MOBILE DROP DOWN rather than mega menu ---
  Props are from REDUX:
	selectedProductType		The product category KEY
*/

class CategoriesDropDown extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
            productTypeData : [ {value: 'LOADING', name: 'Please Wait...'} ],
						_selectedProductType : ''			// For Mobile use
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// ('dotenv-webpack') allows us to read the `API` var from the /.env file as below:
		MyFetchGet(  process.env.API + '/category/headers/all', 3 )
		    .then( response => response.json() )
		    .then( apiProductTypeData => {
					// console.log(apiProductTypeData);
					if (this._isMounted) {
						this.setState( {productTypeData : apiProductTypeData} );
					}
		    })
				.then( () => {

						/* Old Code */

				})
				.catch( /* It should retry !! */ )

	}

	componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

	onChange (e) {

		// Reset to `Please Select...`` if selected so user can easily `Graze` [ Mobile Feedback ]
		if (this._isMounted)
			this.setState( {_selectedProductType : ''} );

		// React's DOM router doesn't seem to handle this very well, so vanilla JS
		window.location.href = '/#/products/' + e.target.value;
	}

	render () {

		if ( _.isEmpty(this.state.productTypeData) )
				return(<div></div>);

		return (
			<div className="small-tablet-display-flex mobile-display-flex centered wide-container">

					<label><span className="highlight-colour no-select">
						<Translate text="Product Type" />
					</span>

            {/* This component is for Mobile so we don't want to remember the last Category
							hence the value={this.state._selectedProductType} which will above be empty.
							Originally we used the Prop as we do in Mega Menu, but on Mobile this doesn't work
							as we want the user to select every time. See Henry as this is subtle, but came from
							a lot of UI testing with REAL clients ! */}


						<select value={this.state._selectedProductType} onChange={ evt => this.onChange(evt) }>
							{this.state.productTypeData.map( (e, key) => {
										return <option key={key} value={e.value}>
											{e.name}
										</option>;
							})}
						</select>

					</label>

			</div>
		);
	}

}

// Set the default values
CategoriesDropDown.defaultProps = {
	// selectedProductType : reduxStore.getState().selectedProductType
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
				// selectedProductType: state.selectedProductType
    }
}

export default connect(mapStateToProps) (CategoriesDropDown);
