// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';

import FeaturedProducts from 'FeaturedProducts';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';
import HomePopup from 'HomePopup';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';
import ReactPixel from 'react-facebook-pixel';
import MySelect from 'MySelect';

// Animations if required
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Home extends React.Component {

	componentDidMount() {

		// Scroll Top after render() - same as a jQuery Doc Ready
	  window.scrollTo(0, 0);

		setReduxValue(reduxStore, 'SET_SEARCH_TEXT', '' );
		var action1 = {
				type: 'SET_SEARCH_TEXT',
				searchText: ''
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
		// console.log('Search Box should be clear ?');
		$('#ThisProductSearch').val('');

		//
		//	Any bespoke onMount...
		//

		ReactPixel.init('1957476027602865');

	}

	render () {

		return (
			<div className="flex-site">
				<IPLog URL="/#/" />
				<HomePopup />
				<div className="site-content">
						<Nav />
						<MegaMenu />
						<ProductSearch />

						<div className="grid-x grid-margin-x">

							{/*
											*********************
													B E S P O K E
											*********************
							*/}

							{/* THREE LINK BOXES and DROPPED mega-menu -- Client Choice not mine ! -- */}

							<div className="cell small-4 small-offset-4 medium-4 medium-offset-4 large-2 large-offset-5">
									<Link to="/page/forsale-page">
										<CMSImage imageName="Home/enter-shop.gif" className="image-bottom-margin expand-hover"/>
									</Link>
							</div>
							{/* <div className="cell small-4 small-offset-0 medium-4 medium-offset-0 large-2 large-offset-0">
									<Link to="/page/ebooks-page">
										<CMSImage imageName="Home/category-ebooks.png" className="image-bottom-margin expand-hover"/>
									</Link>
							</div> */}
							{/* <div className="cell small-4 small-offset-0 medium-2 medium-offset-0 large-2 large-offset-0">
									<Link to="/page/raffle-page">
										<CMSImage imageName="Home/category-raffle.png" className="image-bottom-margin expand-hover"/>
									</Link>
							</div> */}

							{/* Force Blank line */}
							<div className="cell small-12">&nbsp;</div>

							{/* L H S */}
							<Fade left delay={1000}>
								<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-6 large-offset-1">
										<CMSBlock blockTitle="Home-Hero" className="flow-text" />
								</div>
							</Fade>

							{/* R H S */}
							<Fade right delay={1000}>
								<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-4 large-offset-0">
										<CMSImage imageName="Home/hero-photo.jpg" className="image-bottom-margin image-responsive"/>
								</div>
							</Fade>

							{/* Force Blank line */}
							<div className="cell small-12">&nbsp;</div>

							<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-6 large-offset-3">
									<CMSBlock blockTitle="Maidens-Mission" className="" />
							</div>

							{/* Video Block #1 large-5 large-offset-1 */}

							<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-6 large-offset-3">
									<CMSVideo videoName="Video/keep-maiden-moving.mp4" loop={false} play={false} />
									<div className="text-center bold">
										<Translate text="Click" />&nbsp;
										<FontAwesomeIcon icon="play" size="1x" color="black" />&nbsp;
										<Translate text="to start this video" />
									</div>
							</div>

							{/* Force Blank line */}
							<div className="cell small-12">&nbsp;<br />&nbsp;</div>

							{/* Inspire Block #1 large-6 large-offset-0 */}

							<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-6 large-offset-3">
									<CMSImage imageName="Home/3-photos-inspire-message.jpg" className="image-responsive"/>
							</div>

							{/* Force Blank lines */}
							<div className="cell small-12"><br /><br />&nbsp;</div>

						</div>

				</div>

				<Footer />
				<ScrollTop />
			</div>
		);
	}

}

// Set the default values
Home.defaultProps = {
	searchText: reduxStore.getState().searchText
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			searchText: state.searchText
    }
}

export default connect(mapStateToProps)(Home);
