// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from 'ErrorBoundary';
import Translate from 'Translate';

import StripeCheckout from 'react-stripe-checkout';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayStripe extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	_stripeKeyPublic : '',
					_stripeKeySecret : '',
					_shopName: 'Vadoo vShop',		// Default
					_locale: 'auto',
					_currency: 'GBP',
					_payment: false							// Trigger
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data to retrieve Stripe settings
		if (this._isMounted) {

				// Determine if Stripe is Live or using Sandbox
				var APIUrl = process.env.API + '/setting/' + 'STRIPE-LIVE';
				MyFetchGet( APIUrl, 5)
		        .then( response => response.json() )
		        .then( apiDataValue => {
							// console.log('Stripe Live ?: ' + apiDataValue);
							if ( apiDataValue == -1 )
								return false;
							else if ( apiDataValue == 1 ) {
								var APIUrl = process.env.API + '/setting/' + 'STRIPE-KEY-LIVE';
							}	else {
								var APIUrl = process.env.API + '/setting/' + 'STRIPE-KEY-TEST';
							}
							// console.log('Stripe Key URL: ' + APIUrl);

							MyFetchGet( APIUrl, 5)
					        .then( response => response.json() )
					        .then( apiDataValue => {
											if (this._isMounted)
					          		this.setState( { _stripeKeyPublic: apiDataValue } );
					        })
					        .catch( /* Should try again */ );

		        })
		        .catch( /* Should try again */ );

				// Shop Name -> Email name || running this aSync
				var APIUrl2 = process.env.API + '/setting/' + 'EMAIL-NAME';
				MyFetchGet( APIUrl2, 10)
						.then( response => response.json() )
						.then( apiDataValue => {
								if (this._isMounted)
										this.setState( { _shopName: apiDataValue } );
						})
						.catch( /* Should try again */ );

		}
	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	// This is the Stripe CallBack
	onToken (_token) {

		console.log(_token);

		var _data = {
			token: _token,
			email: this.props.userEmail,
			cartID: this.props.cartID,
			amount: this.props.cartValue * 100 + this.props.deliveryCharge * 100 + this.props.VATCharge * 100,
			currency: this.state._currency
		};

		MyFetchPost( process.env.API + '/payments/stripe', _data, 1)
			.then( response => response.json() )
			.then( stripe => {
					console.log(stripe);
					if ( stripe.response == 1 ) {
						this.setState( { _payment: true } );
						console.log(stripe.message);
					}
	    });
	}

	render () {

		{/* _payment is a state flag which triggers <Redirect to PaymentReceived ../>. This is
			set when the user has paid and the onToken() shows a payment status of 1 */}

		if (this.state._payment) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-received/${_amount}`} push />);
		}

		// We must have a Stripe Key and an eMail address to process
		if ( _.isEmpty(this.state._stripeKeyPublic) ) return (<div></div>);
		// if ( _.isEmpty(this.props.userEmail) ) return (<div></div>);

		return (
			<ErrorBoundary>

				<StripeCheckout
					email={this.props.userEmail}
					allowRememberMe={false}
	        stripeKey={this.state._stripeKeyPublic}
					amount={this.props.cartValue * 100 + this.props.deliveryCharge * 100 + this.props.VATCharge * 100}
					locale={this.state._locale}
					currency={this.state._currency}
					name={this.state._shopName}
					token={this.onToken.bind(this)}
					>
					<button className="button large no-select hollow alert large-mobile-button strong-button">
						<Translate text="Pay | Card" />
					</button>
	      </StripeCheckout>

			</ErrorBoundary>
		);
	}

}

// Set the default values
PayStripe.defaultProps = {
	userEmail: reduxStore.getState().userEmail,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userEmail: state.userEmail,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

export default connect(mapStateToProps)(PayStripe);
