// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import IPLog from 'IPLog';

import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';
import PleaseWait from 'PleaseWait';

import ShowProductsOnOrderHistory from 'ShowProductsOnOrderHistory';
import ShowConsignmentOnOrderHistory from 'ShowConsignmentOnOrderHistory';

import Moment from 'react-moment';
import 'moment-timezone';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class MyOrders extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					customerOrders: [],
					_cartViewID: 0,
          _openWindowID: 0,         /* 0 - N/A, 1 - Products, 2 - Consignment , 3 - Tracker */
					_goToNewCart: false,
					_pleaseWait: true
      };
  }

	componentDidMount() {

    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		this._isMounted = true;

		// Get Customer Orders list]
		MyFetchGet(process.env.API + '/orders/getbycustomer/' + this.props.userID, 3)
		    .then( response => response.json() )
				.then( apiOrdersData => {
					if ( !_.isEmpty(apiOrdersData) && this._isMounted ) {
							this.setState( { customerOrders: apiOrdersData } );
					}
				})
				.then( () => {
						// Scroll Top
						window.scrollTo(0, 0);
						// this._isMounted = false;
				})
				.then( () => {
					if ( this._isMounted )
						this.setState( { _pleaseWait: false } );
				})
				.catch( error => {
					if ( this._isMounted )
						this.setState( { _pleaseWait: false } );
				});

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	// Make THIS the current Cart
	makeThisTheCart (evt, _orderID) {

		// Establish this Cart ID in R E D U X as the Current Cart
		setReduxValue(reduxStore, 'SET_CART_ID', _orderID );
		var actionCart = {
				type: 'SET_CART_ID',
				cartID: _orderID
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(actionCart);

		// Update The Items and running Total for THIS reactivated Cart

		var APIUrl = process.env.API + '/cart/get/' + _orderID;
		MyFetchGet(APIUrl, 3)
				.then( response => response.json() )
				.then( apiCartData => {

					var _newItems = apiCartData.items.length;
					var _newTotal = 0.00;

					(apiCartData.items).map( (item, key) => {
								_newTotal += item.quantity_ordered * item.unit_price;
					});

					//
					//		Update R E D U X values
					//

					// Items in cart
					setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', _newItems );
					var action1 = {
							type: 'SET_ITEMS_IN_CART',
							itemsInCart: _newItems
					};

					setReduxValue(reduxStore, 'SET_CART_VALUE', _newTotal );
					var action2 = {
							type: 'SET_CART_VALUE',
							cartValue: _newTotal
					};

					this.props.dispatch(action1);
					this.props.dispatch(action2);

				})
				.then( () => {

						// Set trigger flag -> go to New Cart
						this.setState( { _goToNewCart: true } );

				})
				.catch( /* Should try again */ );

	}

	// Slider Window
	showOrderedProducts (evt, _orderID) {

		// Set the current Cart view
		this.setState( { _cartViewID: _orderID } );
		// Open which Window slider ?
		this.setState( { _openWindowID: 1 } );

		// Activate Show Ordered Products via Slider Window
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: true
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
	}

	// Slider Window
	showConsignment (evt, _orderID) {

		// Set the current Cart
		this.setState( { _cartViewID: _orderID } );
		// Open which Window slider ?
		this.setState( { _openWindowID: 2 } );

		// Activate Show Consignment via Slider Window
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: true
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
	}

	// Slider Window
	trackConsignment (evt, _orderID) {

		// Set the current Cart
		this.setState( { _cartViewID: _orderID } );
		// Open which Window slider ?
		this.setState( { _openWindowID: 3 } );

		// Activate Show Consignment via Slider Window
    setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: true
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
	}

	render () {

		// State based redirect, see above, where we set the state trigger _goToNewCart to true
		// which in effect then causes the below Redirect. This is one of the designs I find rather
		// cumbersome in React JS - takes getting used to....
		if (this.state._goToNewCart) {
			{/* This is just a page refresh - React JS style */}
			var _random = Math.random();
			return (<Redirect to={`/cart/${_random}`} push />);
		}

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		return (
			<div className="flex-site">
				<IPLog URL="/#/myorders" />
				<div className="site-content">

					<Nav />
					<MegaMenu />
					<ProductSearch />

					<div className="page-title highlight-colour black text-center">Order History<br />&nbsp;</div>

					{/* Order List */}
						{ (this.state.customerOrders).map( (order, index) => {

							return(
								<div key={index} className="grid-x">

									{/* Order ID */}
									<div className="cell small-2 small-offset-1 medium-1 medium-offset-1 row-padding align-vertical-middle">
												<span className="white-background">
													# {order.details.id}
												</span>
									</div>
									{/* ----------------------------------------------------------------- */}

									{/* Last Updated */}
									<div className="cell small-8 medium-3 row-padding light-grey-background center align-vertical-middle">
												<Moment format="dddd DD MMM YYYY HH:mm">
													{order.details.updated_at}
												</Moment>
									</div>
									{/* ----------------------------------------------------------------- */}

									{/* Current Status */}
									<div className="cell small-6 small-offset-1 medium-2 medium-offset-0 row-padding center align-vertical-middle">
												{order.details.status_name}
									</div>
									{/* ----------------------------------------------------------------- */}

									{/* Products in Cart */}
									<div className="cell small-4 medium-1 row-padding light-grey-background center align-vertical-middle">
												{order.details.products_ordered} items
									</div>
									{/* ----------------------------------------------------------------- */}

									{/* Cart Total [Products Only] */}
									<div className="cell small-12 medium-1 row-padding center bold align-vertical-middle">
												{this.props.__currencySymbol}	{Intl.NumberFormat('en-US', { minimumFractionDigits:2 })
													.format(order.details.order_total*1.0) }
									</div>
									{/* ----------------------------------------------------------------- */}

									{/* Actions */}
									<div className="cell small-12 medium-2 row-padding center no-select">

										{ order.details.id == this.props.cartID && <FontAwesomeIcon icon="exclamation" size="2x"
	                    className="protip" data-pt-position="left" color="red" data-pt-auto-hide="2000"
	                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Current Cart" /> }

										{ order.details.status_key == '#NEW' && order.details.id != this.props.cartID &&
											<FontAwesomeIcon icon="redo" size="2x"
	                    className="pointer protip" data-pt-position="left" color="PaleGreen" data-pt-auto-hide="2000"
	                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Make THIS the current Order"
											onClick={evt => this.makeThisTheCart(evt, order.details.id)} /> }

										&nbsp;&nbsp;&nbsp;
										<FontAwesomeIcon icon="shopping-cart" size="2x"
	                    className="glass highlight-colour protip" data-pt-position="above" data-pt-auto-hide="2000"
	                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Show order"
											onClick={evt => this.showOrderedProducts(evt, order.details.id)} />

										&nbsp;&nbsp;&nbsp;
										<FontAwesomeIcon icon="truck" size="2x" color="grey"
	                    className="glass protip" data-pt-position="above" data-pt-auto-hide="2000"
	                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Show Consignment"
											onClick={evt => this.showConsignment(evt, order.details.id)} />

										&nbsp;&nbsp;&nbsp;
										<FontAwesomeIcon icon="search-location" size="2x" color="black"
	                    className="pointer protip" data-pt-position="right" data-pt-auto-hide="2000"
	                    data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Track Order"
											onClick={evt => this.trackConsignment(evt, order.details.id)} />

									</div>
									{/* ----------------------------------------------------------------- */}

									{/* Blank Line */}
									<div className="cell small-12">
											&nbsp;
									</div>
									{/* ----------------------------------------------------------------- */}
								</div>
							)

						}) }

						<br /><br />

				</div>

				{/* ----------------------------- Slider Windows ------------------------------ */}

				<div id="ShowOrderedProducts" className="grid-x grid-margin-x">
						<div className="cell small-10 small-offset-1">
		            {/* Conditional on active SlideWindow */}
		            {this.props.slideWindow && this.state._openWindowID == 1
									&& <ShowProductsOnOrderHistory CART_ID={this.state._cartViewID} />}
          	</div>
				</div>

				<div id="ShowConsignment" className="grid-x grid-margin-x">
						<div className="cell small-10 small-offset-1">
		            {/* Conditional on active SlideWindow */}
		            {this.props.slideWindow && this.state._openWindowID == 2
									&& <ShowConsignmentOnOrderHistory CART_ID={this.state._cartViewID} />}
          	</div>
				</div>

				{/* ----------------------------- End of Sliders  ----------------------------- */}

				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values = current Redux state values
MyOrders.defaultProps = {
	__currencySymbol: reduxStore.getState().__currencySymbol,
	cartID: reduxStore.getState().cartID,
  userID: reduxStore.getState().userID,
	cartValue: reduxStore.getState().cartValue,
	itemsInCart: reduxStore.getState().itemsInCart,
	slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
				__currencySymbol: state.__currencySymbol,
				cartID: state.cartID,
        userID: state.userID,
				cartValue: state.cartValue,
				itemsInCart: state.itemsInCart,
				slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(MyOrders);
