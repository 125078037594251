import React from 'react';
import ReactDOM from 'react-dom';

import VShopBootStrap from 'VShopBootStrap';

import { CookiesProvider } from 'react-cookie';
// v4 React Router DOM - not to everyone's liking ! # in route URLs
import { HashRouter, Switch, Route, withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

// Load Zurb Foundation which is a jQuery based CSS Library
require('style-loader!css-loader!foundation-sites/dist/css/foundation.min.css');
require('style-loader!css-loader!protip/css/protip.css');
$(document).foundation();

// ProTip
$.protip({
    /** @type Boolean   Should we observe whole document for assertions and removals */
    observer:           true,
    /** @type Number    Default time for onResize event timeout. */
    delayResize:        50,
});

// Lightbox
require('style-loader!css-loader!react-image-lightbox/style.css');
// Slick Carousel
require('style-loader!css-loader!slick-carousel/slick/slick.css');

// Animate CSS
require('style-loader!css-loader!animate.css/animate.min.css');
// React Awesome CSS
require('style-loader!css-loader!react-awesome-slider/dist/styles.css');
// React Credit Cards CSS
require('style-loader!css-loader!react-credit-cards/es/styles-compiled.css');

// appCSS is the main Style or my CSS to extend Foundation
require('style-loader!css-loader!appCSS');
// My megaMenu
require('style-loader!css-loader!megamenuCSS');
// supercede appCSS with overrides and some component CSS
require('style-loader!css-loader!overrideCSS');
require('style-loader!css-loader!modalCSS');

// React Select
require('style-loader!css-loader!selectCSS');

// For aSync and await [Babel or ES7]
require('babel-polyfill');

// FontAwesome nonsense...
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab);
library.add(fas);

// ------------------- LOCAL COMPONENTS --------------------

import IPLog from 'IPLog';
import Login from 'Login';
import RemoteLogin from 'RemoteLogin';
import Register from 'Register';
import Logout from 'Logout';
import ResetPassword from 'ResetPassword';
import MySettings from 'MySettings';
import MyOrders from 'MyOrders';

import Home from 'Home';
import CMSWebPage from 'CMSWebPage';
import CMSPreview from 'CMSPreview';
import NoMatch from 'NoMatch';
import ChangedLocale from 'ChangedLocale';

// Bespoke example
// import WineTasting from 'WineTasting';

import Products from 'Products';
import ProductsByClass from 'ProductsByClass';
import ProductFullDisplay from 'ProductFullDisplay';
import ProductsWithSearch from 'ProductsWithSearch';
import Favourites from 'Favourites';
import MediaStore from 'MediaStore';
import MediaPlay from 'MediaPlay';
import Cart from 'Cart';
import Checkout from 'Checkout';
import PaymentReceived from 'PaymentReceived';
import PaymentPromised from 'PaymentPromised';

// Meta Data is held in the .env
var __appName = process.env.NAME;
var __appContent = process.env.NAME;
var __appIcon = process.env.ICON;

// ------------------ R E D U X ----------------------------

import ReduxState from 'ReduxState';
import { createReduxStore, setReduxValue, reduxReducer } from 'ReduxStore';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
// Our default Store is `reduxStore` which is used by the <Provider /> Wrapper see below
var reduxStore = createReduxStore();
var persistor = persistStore(reduxStore);   // See <PersistGate /> below


// ------------------ Render App ----------------------------

ReactDOM.render(

	/*

  Comments:
  -----------------------------------------------------------

	FYI: HashRouter replaced the old Router {hashHistory}. In effect Routing can be a major pain with
	React because it is all client side. Frankly I am waiting for an improved version, but this works
	and it's very clean as long as you can stand the /#/ in the URL ! In case you are wondering why you
  need the #, it's because it's not a server-route but a client one [JS App running on your local browser]

  -----------------------------------------------------------

	General Style Note: With redux-persist we don't need cookies:
		Not required -> cookies.set('name', data), cookies.get('name')

	REDUX with react-redux, react-persist is non-trivial. React-persist negates cookies.

  -----------------------------------------------------------

	*/
  <div className="wrapper">

    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <MetaTags>
          <div>
            <title>vShop</title>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"/>
            <meta id="meta-description" name="description"
              content={__appContent} />
            <meta id="og-title" property="og:title" content={__appName} />
            <meta id="og-image" property="og:image" content={__appIcon} />
          </div>
        </MetaTags>

        <VShopBootStrap />

        {/* React Router Dom 5 - `the moving feast` now uses render= or component= f()
          which I am also using here - see /product/: .... routes as an example

            pre-themeing...
      			<Route path="/home" render={props => <Home {...props} />} />

          */}

      	<HashRouter>
      		<div>
            <Switch>
          			<Route exact path="/" render={props => <Home {...props} />} />
                <Route path="/home" component={selectHomePage} />

                {/* :id  and /:category are parameters and we use stub f()s below to call the
                    real components with a prop - React Router DOM 5 */}

                <Route path="/page/:title" component={GenericSingleCMSPage} />
                <Route path="/preview/:block" component={CMSPagePreview} />

                <Route path="/class/:class" component={ProductsClassDisplay} />
                <Route path="/products/:category/:priceOrder/:refresh" component={ProductsCategoryDisplayByPrice} />
                <Route path="/products/:category" component={ProductsCategoryDisplay} />

                <Route path="/product/:id" component={ProductPageDisplay} />
                <Route path="/redirect-product/:id" component={ReloadProduct} />

                <Route path="/search/:searchText" component={ProductSearchText} />
          			<Route exact path="/favourites" render={props => <Favourites {...props} />} />
          			<Route exact path="/media-store" render={props => <MediaStore {...props} />} />
                <Route path="/media-play/:id" component={ProductMediaPlay} />

          			<Route exact path="/cart" render={props => <Cart {...props} />} />
                <Route path="/cart/:refresh" component={ReloadCart} />
          			<Route path="/checkout" render={props => <Checkout {...props} />} />

          			<Route path="/login" render={props => <Login {...props} />} />
                <Route path="/remotelogin/:email/:hashpassword" component={APILogin} />
          			<Route path="/register" render={props => <Register {...props} />} />
                <Route path="/logout" render={props => <Logout {...props} />} />
                <Route path="/reset-password/:user" component={ResetUserPassword} />

          			<Route path="/mysettings" render={props => <MySettings {...props} />} />
          			<Route path="/myorders" render={props => <MyOrders {...props} />} />

                <Route path="/payment-received/:amount" component={PaymentReceivedRedirect} />
                <Route path="/payment-promised/:amount" component={PaymentPromisedRedirect} />

                <Route path="/changedlocale" render={props => <ChangedLocale {...props} />} />
                <Route path="/redux" render={props => <ReduxState {...props} />} />
                <Route path="*" render={props => <NoMatch {...props} />} />

            </Switch>
      		</div>
      	</HashRouter>
      </PersistGate>
    </Provider>
  </div>,

	document.getElementById('app')
);

//
//  f()s for the above Component routes
//

function selectHomePage ( ) {
  return (
    <Home />
  )
}

function GenericSingleCMSPage( {match} ) {
  return (
    <CMSWebPage title={match.params.title} />
  )
}

function CMSPagePreview( {match} ) {
  return (
    <CMSPreview CMS={match.params.block} />
  )
}

function ReloadCart({ match }) {
  return (
    <div>
      <Cart REFRESH_ID={match.params.refresh} />
    </div>
  );
}

function ProductPageDisplay({ match }) {
  return (
    <div>
      <ProductFullDisplay PRODUCT_ID={match.params.id} />
    </div>
  );
}

function ReloadProduct({ match }) {
  return (
    <div>
      <ProductFullDisplay PRODUCT_ID={match.params.id} HAS_BEEN_ROUTED={true} />
    </div>
  );
}

function ProductsCategoryDisplay({ match }) {
  return (
    <div>
      <Products CATEGORY_KEY={match.params.category} />
    </div>
  );
}

function ProductsCategoryDisplayByPrice({ match }) {
  return (
    <div>
      <Products
        CATEGORY_KEY={match.params.category}
        PRICE_ORDER={match.params.priceOrder}
        REFRESH_ID={match.params.refresh}
      />
    </div>
  );
}

function ProductsClassDisplay({ match }) {
  return (
    <div>
      <ProductsByClass CLASS_KEY={match.params.class} />
    </div>
  );
}

function ProductSearchText({ match }) {

  return (
    <div>
      <IPLog URL={"/#/search/"+match.params.searchText} />
      <ProductsWithSearch SEARCH_TEXT={match.params.searchText} />
    </div>
  );
}

function ProductMediaPlay({ match }) {
  return (
    <div>
      <MediaPlay PRODUCT_ID={match.params.id} />
    </div>
  );
}

function PaymentReceivedRedirect({ match }) {
  return (
    <div>
      <PaymentReceived AMOUNT={match.params.amount} />
    </div>
  );
}

function PaymentPromisedRedirect({ match }) {
  return (
    <div>
      <PaymentPromised AMOUNT={match.params.amount} />
    </div>
  );
}

function ResetUserPassword( {match} ) {
  return (
    <ResetPassword user={match.params.user} />
  )
}

function APILogin( {match} ) {
  console.log( match );
  return (
    <RemoteLogin email={match.params.email} hashpassword={match.params.hashpassword} />
  )
}
