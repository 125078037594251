// Use ES6 Class

import React from 'react';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoBack from 'GoBack';

import Nav from 'Nav';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import ReactPlayer from 'react-player';

class MediaPlay extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
						_videoURL : ''
        };
	}

	componentDidMount () {

		// Scroll Top after render() - same as a jQuery Doc Ready
	  window.scrollTo(0, 0);

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
				var APIUrl = process.env.API + '/product/get/' + this.props.PRODUCT_ID;
				MyFetchGet( APIUrl, 3 )
				    .then( response => response.json() )
				    .then( (apiProductData) => {

									// console.log( apiProductData );
									// Find the `URL` key
									apiProductData.attributes.map( (attribute, key) => {
										if ( attribute.key_name == 'URL' ) {
											this.setState( { _videoURL : attribute.key_value } );
										}
									});

				    });
		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

			// Wait for the product to load
			// if ( _.isEmpty(this.state._videoURL) ) return (<div></div>);

			return (
				<div className="flex-site">

						<Nav />

						<div className="grid-x grid-margin-x">

							<div className="cell small-12 center"><GoBack /></div>

							{/* Force Blank line */}
  						<div className="cell small-12">&nbsp;</div>

							<div className="cell small-10 small-offset-1">

									<ReactPlayer
										url={this.state._videoURL}
										playing={ true }
										loop={ false }
										controls={ true }
										width="100%"
										height="100%"
									/>

							</div>

							{/* Force Blank line */}
  						<div className="cell small-12"><br />&nbsp;</div>

						</div>

						<Footer />
						<ScrollTop />

				</div>
			);
	}

}

export default MediaPlay;
