// Use ES6 Class

import React from 'react';
import { Link, HashRouter, Route, Redirect } from 'react-router-dom';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import * as EmailValidator from 'email-validator';
import LoginMessages from 'LoginMessages';

import IPLog from 'IPLog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class RemoteLogin extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					customerEmail: this.props.email,
					customerPassword: this.props.hashpassword,
					_tryLogin: false,
					_showReplyMessage: false,
					_replyMessage: '',
					_pleaseWait: false,
					_clickRedirectCheckout: false
      };
			// Apply the f()s which update these:
			this.customerEmail = this.updateCustomerEmailValue.bind(this);
			this.customerPassword = this.updateCustomerPasswordValue.bind(this);
  }

	componentDidMount() {

		// Scroll Top after render()
	  window.scrollTo(0, 0)
	}

	updateCustomerEmailValue(evt) {

			// As a nice touch show the eMail address in green if the syntax is valid else in red
			if ( EmailValidator.validate(evt.target.value) )
				$('#LoginEmail').addClass('green').removeClass('red');
			else
				$('#LoginEmail').addClass('red').removeClass('green');

		  this.setState( { customerEmail: evt.target.value } );
			this.setState( { _tryLogin: false } );
			this.setState( { _showReplyMessage: false } );

	}

	updateCustomerPasswordValue(evt) {
	  	this.setState( { customerPassword: evt.target.value } );
			this.setState( { _tryLogin: false } );
			this.setState( { _showReplyMessage: false } );
	}

	checkEmailPassword (evt) {

		console.log( this.state.customerEmail + ' | ' + this.state.customerPassword );

		var _data = { email: this.state.customerEmail, password: this.state.customerPassword };

		MyFetchPost( process.env.API + '/customer/loginhash', _data, 3)
		    .then( response => response.json() )
				.then( customer => {

						  this.setState( { _tryLogin: true } );

							if ( !_.isEmpty(customer) ) {

								// Login has been successful
								setReduxValue(reduxStore, 'SET_USER_ID', customer.header.id);
								setReduxValue(reduxStore, 'SET_USER_EMAIL', customer.header.email);
								// Optional User Name
								setReduxValue(reduxStore, 'SET_USER_NAME', customer.attributes.FULL_NAME.value);
								// Set or dispatch [REDUX] action for prop
						    var action1 = {
						        type: 'SET_USER_ID',
						        userID: customer.header.id
						    };
								var action2 = {
						        type: 'SET_USER_EMAIL',
						        userEmail: customer.header.email
						    };
								var action3 = {
						        type: 'SET_USER_NAME',
						        userName: customer.attributes.FULL_NAME.value
						    };
						    // Note that we apply the dispatch to the props: mapStateToProps(state)
						    this.props.dispatch(action1);
								this.props.dispatch(action2);
								this.props.dispatch(action3);

					} else {

								// Login Failed
								setReduxValue(reduxStore, 'SET_USER_ID', -1);
								// Optional User Name
								setReduxValue(reduxStore, 'SET_USER_NAME', '');
								// Set or dispatch [REDUX] action for prop
						    var action1 = {
						        type: 'SET_USER_ID',
						        userID: -1
						    };
								var action2 = {
						        type: 'SET_USER_NAME',
						        userName: ''
						    };
						    // Note that we apply the dispatch to the props: mapStateToProps(state)
						    this.props.dispatch(action1);
								this.props.dispatch(action2);
					}
		    })

	}

	componentDidMount() {
		// Scroll Top after render()
	  window.scrollTo(0, 0)
	}

	render () {

		return (
			<div className="flex-site">
				<IPLog URL="/#/login" />
				<div className="site-content">
						<Nav />
						{/* Only show menu and search when logged in */}
						{ this.props.userID > 0 && <MegaMenu /> }
						{ this.props.userID > 0 && <ProductSearch /> }

						<div className="page-title text-center">
							<Translate text="Remote Customer Login" />
						</div>

						{/* eMail */}
						<div className="grid-x">
							<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

								<div className="input-group">
								  <span className="input-group-label"><FontAwesomeIcon icon="at" size="1x" color="grey" /></span>
								  <input id="LoginEmail" defaultValue={this.state.customerEmail}
										onChange={evt => this.updateCustomerEmailValue(evt)}
										className="input-group-field" type="email" placeholder="eMail Address" />
								</div>

							</div>
							<div className="cell small-1 medium-3 large-4"></div>
						</div>

						{/* password */}
						<div className="grid-x">
							<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

								<div className="input-group">
								  <span className="input-group-label"><FontAwesomeIcon icon="key" size="1x" className="highlight-colour" /></span>
								  <input id="HashPassword" defaultValue={this.state.customerPassword}
										onChange={evt => this.updateCustomerPasswordValue(evt)}
										className="input-group-field" type="password" placeholder="Hash Password" />
								</div>

							</div>
							<div className="cell small-1 medium-3 large-4"></div>
						</div>

						{/* buttons */}
						<div className="grid-x">
							<div className="cell flex-container align-center auto small-offset-1 medium-offset-3 large-offset-4">

								<button className="hollow button secondary large-mobile-button"
									onClick={evt => this.checkEmailPassword(evt)}>
									<Translate text="Remote Login" />
								</button>

							</div>
							<div className="cell flex-container small-1 medium-3 large-4"></div>
						</div>

						<br />
						{ this.state._tryLogin && <LoginMessages email={this.state.customerEmail} /> }

						{ this.state._pleaseWait && <div className="force-center"><img src="images/authorising.gif" /></div> }						}

						<br />
						{ this.state._showReplyMessage &&	<div className="message-alert">
								<FontAwesomeIcon icon="exclamation-triangle" size="2x" color="gold" />&nbsp;&nbsp; {this.state._replyMessage}
							</div> }
						<br /><br />

					</div>

				<Footer />
				<ScrollTop />

			</div>
		)
	}

}

// Set the default values = current Redux state values
RemoteLogin.defaultProps = {
  // userID: reduxStore.getState().userID,
  // userName: reduxStore.getState().userName,
	// userEmail: reduxStore.getState().userEmail
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        // userID: state.userID,
        // userName: state.userName,
				// userEmail: state.userEmail
    }
}

export default connect(mapStateToProps)(RemoteLogin);
