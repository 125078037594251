// Use ES6 Class

import React from 'react';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Logout extends React.Component {

	componentDidMount() {
		// Scroll Top after render()
	  window.scrollTo(0, 0)
	}

	signOut (evt) {

		setReduxValue(reduxStore, 'SET_USER_ID', 0);
		setReduxValue(reduxStore, 'SET_USER_EMAIL', '');
		setReduxValue(reduxStore, 'SET_USER_APPROVED', 0);
		// Optional User Name
		setReduxValue(reduxStore, 'SET_USER_NAME', '');
		// Clear any Cart
		setReduxValue(reduxStore, 'SET_CART_ID', 0);
		setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', 0);
		setReduxValue(reduxStore, 'SET_CART_VALUE', 0.00);

		// Set or dispatch [REDUX] action for prop
		var action1 = {
				type: 'SET_USER_ID',
				userID: 0
		};
		var action2 = {
				type: 'SET_USER_NAME',
				userName: ''
		};
		var action3 = {
				type: 'SET_USER_EMAIL',
				userEmail: ''
		};
		var action4 = {
				type: 'SET_CART_ID',
				cartID: 0
		};
		var action5 = {
				type: 'SET_ITEMS_IN_CART',
				itemsInCart: 0
		};
		var action6 = {
				type: 'SET_CART_VALUE',
				cartValue: 0.00
		};
		var action7 = {
				type: 'SET_USER_APPROVED',
				userApproved: false
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
		this.props.dispatch(action2);
		this.props.dispatch(action3);
		this.props.dispatch(action4);
		this.props.dispatch(action5);
		this.props.dispatch(action6);
		this.props.dispatch(action7);

	}

	render () {
		return (
			<div className="flex-site">
				<div className="site-content">
					<Nav />
					<MegaMenu />
					<ProductSearch />

					<div className="page-title text-center">
						<Translate text="Thanks for Shopping with us" />
					</div>

					{/* Sign Out button */}
					<div className="grid-x">
						<div className="cell flex-container align-center auto">

							{ this.props.userID > 0 && <button className="hollow button alert large-mobile-button"
								onClick={evt => this.signOut(evt)}>
								<Translate text="Sign Out" />
							</button> }

						</div>
					</div>

					<br />
					{ this.props.userID == 0 && <div className="page-title text-center">
							<Translate text="Bye for now..." />
					</div> }
					<br />

				</div>

				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values = current Redux state values
Logout.defaultProps = {
  userID: reduxStore.getState().userID,
  userName: reduxStore.getState().userName,
  cartID: reduxStore.getState().cartID,
	itemsInCart: reduxStore.getState().itemsInCart,
	cartValue: reduxStore.getState().cartValue,
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        userID: state.userID,
        userName: state.userName,
        cartID: state.cartID,
				itemsInCart: state.itemsInCart,
				cartValue: state.cartValue
    }
}

export default connect(mapStateToProps)(Logout);
