// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import ProductImage from 'ProductImage';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- Show past or existing Order Products ---
  Props are:
	CART_ID		The ID of the order
*/


class ShowProductsOnOrderHistory extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
          productsOrdered: []
      };

  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
    if (this._isMounted) {

      var APIUrl = process.env.API + '/cart/get/' + this.props.CART_ID;
  		MyFetchGet(APIUrl, 3)
  		    .then( response => response.json() )
  		    .then( apiProductsOrderedData => {
            console.log(apiProductsOrderedData);
            if (this._isMounted)
                this.setState( {productsOrdered : apiProductsOrderedData} );
          })
          .then( () => {
             this._isMounted = false;
          })
          .catch( /* Should try again */ );

    }
	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

  closeShowProductsOnOrderHistory () {

        setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
        var action1 = {
            type: 'SET_SLIDE_WINDOW',
            slideWindow: false
        };
        // Note that we apply the dispatch to the props: mapStateToProps(state)
		    this.props.dispatch(action1);

  }

	render () {

      if ( _.isEmpty(this.state.productsOrdered) )
        return(<div></div>);

      var _order_total = 0.00;

      return (
        <div className="slide-in-page-from-right">

            <div className="right-hand-side-close">
              <FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
              data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
                  onClick={evt => this.closeShowProductsOnOrderHistory(evt)} />
            </div>

          <div className="page-title black">Products Ordered</div>

			        { (this.state.productsOrdered.items).map( (productOrdered, key) => {
                  _order_total += (productOrdered.quantity_ordered * productOrdered.unit_price);
                  return(
                    <div key={key}>
                      <div className="grid-x grid-margin-x">

                        <div className="cell small-4 medium-3 large-2">
                          <ProductImage PRODUCT_ID={productOrdered.product_id} />
                          <br /><br />
                        </div>

                        <div className="cell small-8 medium-9 large-10">
                          Shipped { parseInt(productOrdered.quantity_shipped) ? productOrdered.quantity_shipped : 'None' }
                          &nbsp;of&nbsp;
                          {productOrdered.quantity_ordered} x {productOrdered.product_name}
                          <br />
                          {productOrdered.quantity_ordered} @ {this.props.__currencySymbol}{productOrdered.unit_price} =
                          &nbsp;
                          {this.props.__currencySymbol}{ (productOrdered.quantity_ordered * productOrdered.unit_price).toFixed(2) }
                          <br /><br />
                        </div>

                      </div>

                    </div>
                  )
              })}

              <div className="center bold"><hr />{this.props.__currencySymbol}{_order_total.toFixed(2)}<hr /></div>

        </div>
      );

	}

}

// Set the default values
ShowProductsOnOrderHistory.defaultProps = {
  	__currencySymbol: reduxStore.getState().__currencySymbol,
    slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
      	__currencySymbol: state.__currencySymbol,
        slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(ShowProductsOnOrderHistory);
