// Use new ES6 Class

import React from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Cards from 'react-credit-cards';
import PayNummus from 'PayNummus';

//
// Required ....modal.css
//
// This component v1.0 is written with the light-weight Zurb Foundation UI
//


class NummusCheckout extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);

				this.paymentReceived = this.paymentReceived.bind(this);
				this.paymentDeclined = this.paymentDeclined.bind(this);

        this.state = {
					_cardNumber: this.props.cardNumber || '',
					_expiryDate: this.props.expiryDate || '',
					_CVC: this.props.CVC || '',
					_name: this.props.name || '',
					_address: this.props.address || '',
					_zip: this.props.zip || '',
					_locale: 'auto',
					_currency: this.props.currency || 'GBP',		// Should always be a Prop, but...
					_currencySymbol: '',
					_focus: '',																	// for the graphic Display Card
					_paymentAuthorising: false,									// Please Wait job....
					_paymentSuccess: false,											// trigger for YES
					_paymentDeclined: false,										// trigger for DECLINED
					_showTestCards: false
        };
	}

	// ---------------------------------------------------------------------------

	componentDidMount () {

		this._isMounted = true;

		// console.log( 'Sandbox: ' + this.props.sandbox );

		if ( this._isMounted ) {

				switch ( this.state._currency ) {

					case 'CNY': 	this.setState( { _currencySymbol: '₹' } );
												break;
					case 'INR': 	this.setState( { _currencySymbol: '₹' } );
												break;
					case 'RUB': 	this.setState( { _currencySymbol: '₽' } );
												break;
					case 'JPY': 	this.setState( { _currencySymbol: '¥' } );
												break;
					case 'EUR': 	this.setState( { _currencySymbol: '€' } );
												break;
					case 'USD': 	this.setState( { _currencySymbol: '$' } );
												break;
					default:			this.setState( { _currencySymbol: '£' } );

				}

		}

	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	// ---------------------------------------------------------------------------

	makeCreditCard ( _cardNumberDigits ) {

		// Cryptic REGEX Style... which I hate
		return _cardNumberDigits.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');

 	}

	makeExpiry ( _expiryDate ) {

			// Although this is long-winded, I found this rather elegant vs. cyptic regex !

			// Make the format mm/yy Stripe-Style

			// if no year then remove / slashes
			if ( _expiryDate.length <= 3 )
				_expiryDate = _expiryDate.replace(/\//gi, '');

		 switch (_expiryDate.length) {

			 case 1:	return _expiryDate;
			 			 		break;
			 case 2:	return _expiryDate
			 					break;
								// and here is the issue, so add in a / after the first 2 digits
			 case 3:	return _expiryDate.substring(0, 2) + '/' + _expiryDate.substring(2, 1);
			 					break;
			 case 4:	return _expiryDate;
			 					break;
			 case 5:	return _expiryDate;
			 					break;
			 default: return _expiryDate;

		 }

  }

	// Just ensure the user is John Doe and not just John
	firstLastNames ( _name ) {

		var _words = _name.split(' ');

		if ( _words.length < 2 ) return false;

		// Just for references
		var firstName = _words[0];
		var lastName = _words[_words.length - 1];

		// H Davison or Henry D not allowed !
		if ( firstName.length < 2 || lastName.length < 2 ) return false;

		return true;

	}


	// ---------------------------------------------------------------------------

	updateCardNumber(evt) {
		$('#CardNumber').removeClass('missing-data-field');
	  this.setState( { _cardNumber: evt.target.value } );
		this.setState( { _focus: 'number' } );
	}

	updateName(evt) {
		$('#NameOnCard').removeClass('missing-data-field');
	  this.setState( { _name: evt.target.value } );
		this.setState( { _focus: 'name'} );
	}

	updateAddress(evt) {
		$('#BillingAddress').removeClass('missing-data-field');
	  this.setState( { _address: evt.target.value } );
	}
	updateZip(evt) {
		$('#BillingZip').removeClass('missing-data-field');
	  this.setState( { _zip: evt.target.value } );
	}

	updateExpiryDate(evt) {
		$('#ExpiryDate').removeClass('missing-data-field');
	  this.setState( { _expiryDate: evt.target.value } );
		this.setState( { _focus: 'expiry' } );
	}

	updateCVC(evt) {
		$('#CVCOnCard').removeClass('missing-data-field');
	  this.setState( { _CVC: evt.target.value } );
		this.setState( { _focus: 'cvc' } );
	}

	showTestCards(evt) {
	  this.setState( { _showTestCards: true } );
	}

	clickPay (evt) {

		/* Basic data check before we try to authorise and
			some subtle jQuery focus() !!! [in React - I know].... */

		// Card numbers are 16 digits + 3 spaces inbetween 4 - 4 - 4 - 4
		if ( (this.makeCreditCard(this.state._cardNumber)).length < 19 ) {
			$('#CardNumber').focus().addClass('missing-data-field');
			return false;
		}
		// We check this - see above
		if ( !this.firstLastNames(this.state._name) ) {
			$('#NameOnCard').focus().addClass('missing-data-field');
			return false;
		}
		// Format is mm/yy i.e. 5 chars
		if ( this.state._expiryDate.length < 5 ) {
			$('#ExpiryDate').focus().addClass('missing-data-field');
			return false;
		}
		// Relaxed on CVC normally 3 digits
		if ( _.isEmpty(this.state._CVC) ) {
			$('#CVCOnCard').focus().addClass('missing-data-field');
			return false;
		}
		// Very relaxed
		if ( _.isEmpty(this.state._address) ) {
			$('#BillingAddress').focus().addClass('missing-data-field');
			return false;
		}
		// Very relaxed
		if ( _.isEmpty(this.state._zip) ) {
			$('#BillingZip').focus().addClass('missing-data-field');
			return false;
		}

		this.setState( { _paymentAuthorising: true } );
		this.setState( { _paymentDeclined: false } );
		this.setState( { _paymentSuccess: false } );

	}

	paymentReceived (token) {

		console.log( 'Nummus Checkout received: ' + token);

		this.setState( { _paymentAuthorising: false } );
		this.setState( { _paymentDeclined: false } );
		this.setState( { _paymentSuccess: true } );

		// We are now good to chain-callback OnSuccess this....
		this.props.onSuccess(token);

	}

	paymentDeclined () {
		this.setState( { _paymentAuthorising: false } );
		this.setState( { _paymentDeclined: true } );
	}


	render () {

		return (
			<div className="checkout-box-page-from-right">

					<div className="checkout-box-right-hand-side-close">
						<FontAwesomeIcon icon="window-close" size="2x" color="grey"
								onClick={evt => this.props.onClose(evt)} />
					</div>

					{ this.props.sandbox && <div className="checkout-box-left-hand-side"
						onClick={evt => this.showTestCards(evt)} >
						<FontAwesomeIcon icon="cube" size="2x" color="goldenrod" /> &nbsp; Test Cards
						</div> }

			    <div className="checkout-box">
						<hr />
						{ this.state._paymentAuthorising && <div><img src="images/authorising.gif" /></div> }

						{ this.state._paymentDeclined && <FontAwesomeIcon icon="exclamation-triangle"
								size="3x" color="darkred" className="checkout-box-icon-spacing" /> }

						{ this.state._paymentSuccess && <FontAwesomeIcon icon="check"
								size="3x" color="darkcyan" className="checkout-box-icon-spacing" /> }

						<div className="button-container">
								<button className="button large no-select alert hollow button-color always-large-button"
									onClick={evt => this.clickPay(evt)}>
									Pay {this.state._currencySymbol} {this.props.amount.toFixed(2)}
								</button>
						</div>

						<span className="smaller-text center">{this.props.email}</span>
						<br />&nbsp;
					</div>

					<div className="grid-x">

							<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 large-6 large-offset-3">

										<div className="input-group">
											<span className="input-group-label"><FontAwesomeIcon icon="credit-card" size="1x"
											className="checkout-box-icon-color" /></span>
										<input id="CardNumber" value={this.makeCreditCard(this.state._cardNumber)}
												onChange={evt => this.updateCardNumber(evt)}
												className="input-group-field cc-number" type="tel" pattern="\d" maxLength="19"
												placeholder="Card number" autoFocus />
										</div>

							</div>

							<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 large-6 large-offset-3">

										<div className="input-group">
											<span className="input-group-label"><FontAwesomeIcon icon="pencil-alt" size="1x"
											className="checkout-box-icon-color" /></span>
										<input id="NameOnCard" value={this.state._name} onChange={evt => this.updateName(evt)}
												className="input-group-field" type="text" placeholder="Name on card"/>
										</div>

							</div>

							<div className="cell small-5 small-offset-1 medium-4 medium-offset-2 large-3 large-offset-3">

										<div className="input-group">
											<span className="input-group-label"><FontAwesomeIcon icon="calendar-alt" size="1x"
											className="checkout-box-icon-color" /></span>
										<input id="ExpiryDate" value={this.makeExpiry(this.state._expiryDate)}
												onChange={evt => this.updateExpiryDate(evt)}
												className="input-group-field cc-exp" type="tel" pattern="\d" maxLength="5" />
										</div>

							</div>

							<div className="cell small-5 medium-4 large-3">

										<div className="input-group">
											<span className="input-group-label"><FontAwesomeIcon icon="lock" size="1x"
											className="checkout-box-icon-color" /></span>
										<input id="CVCOnCard" value={this.state._CVC} onChange={evt => this.updateCVC(evt)}
												className="input-group-field cc-cvc" type="tel" pattern="\d" maxLength="3"
												placeholder="CVC"/>
										</div>

							</div>

					</div>

					<Cards
		          cvc={this.state._CVC}
							name={this.state._name}
		          expiry={this.state._expiryDate}
		          number={this.state._cardNumber}
							focused={this.state._focus}
					/>

					<br />
					{/* Get Address and/or ZIP code - hopefully passed in */}
					<div className="grid-x">

							<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 large-6 large-offset-3">

										<div className="input-group">
											<span className="input-group-label"><FontAwesomeIcon icon="address-card" size="1x"
											className="checkout-box-icon-color" /></span>
										<textarea id="BillingAddress" onChange={evt => this.updateAddress(evt)}	className="input-group-field"
											placeholder="Billing address" value={this.state._address} />
										</div>

										<div className="input-group">
											<span className="input-group-label"><FontAwesomeIcon icon="mail-bulk" size="1x"
											className="checkout-box-icon-color" /></span>
										<input id="BillingZip" value={this.state._zip} onChange={evt => this.updateZip(evt)}
												className="input-group-field cc-cvc" type="text" placeholder="ZIP"/>
										</div>

							</div>

					</div>

					{ this.state._paymentAuthorising &&

						<PayNummus
							publicKey={this.props.publicKey}
							subDomain={this.props.subDomain}
							amount={(this.props.amount *1.0).toFixed(2)}
							currency={this.state._currency}
							email={this.props.email}
							name={this.state._name}
							address={this.state._address}
							zip={this.state._zip}
							cardNumber={this.state._cardNumber}
							cvc={this.state._CVC}
							expiry={this.state._expiryDate}
							onSuccess={this.paymentReceived}
							onFail={this.paymentDeclined}
						/>

					}

					{ this.props.sandbox && this.state._showTestCards && <div className="checkout-box">

						<table>
							<tbody>
								<tr>
									<td><b>Copy and Paste</b></td>
									<td></td>
								</tr>
								<tr>
									<td>4242 4242 4242 4242</td>
									<td>Visa - OK</td>
								</tr>
								<tr>
									<td>5555 5555 5555 4444</td>
									<td>Mastercard - OK</td>
								</tr>

								<tr>
									<td>4000 0025 0000 3155</td>
									<td>3D Secure - Auth First Time</td>
								</tr>
								<tr>
									<td>4000 0027 6000 3184</td>
									<td>3D Secure - Auth Every Time</td>
								</tr>
								<tr>
									<td>4000 0082 6000 3178</td>
									<td>3D Secure - Insufficient Funds</td>
								</tr>

								<tr>
									<td>4000 0000 0000 0010</td>
									<td>Fails Address | Postcode</td>
								</tr>
								<tr>
									<td>4000 0000 0000 0101</td>
									<td>Fails CVC</td>
								</tr>
								<tr>
									<td>4000 0000 0000 9235</td>
									<td>Ok, but High Risk</td>
								</tr>
								<tr>
									<td>4100 0000 0000 0019</td>
									<td>Fails as Fraudulent</td>
								</tr>
								<tr>
									<td>4000 0000 0000 9995</td>
									<td>Fails Insufficient Funds</td>
								</tr>

							</tbody>
						</table>

						<br />&nbsp;

					</div>}

				</div>
		);

	}

}

export default NummusCheckout;
