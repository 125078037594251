// Use ES6 Class

import React from 'react';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';

/*
  --- Product Image [sub component] ---
  Props are:
	PRODUCT_ID			The product ID [or its name]
*/

class ProductImage extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
				productData : null
      };
  }

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
				var APIUrl = process.env.API + '/product/get/' + this.props.PRODUCT_ID;
				MyFetchGet( APIUrl, 3 )
				    .then( response => response.json() )
				    .then( apiProductData => {
							// console.log(apiProductData);
							if (this._isMounted)
								this.setState( {productData : apiProductData} );
				    })
						.then( () => {
								this._isMounted = false;
						});
		}
	}

	componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		var product = this.state.productData;
		// Wait for the product to load
		if ( _.isEmpty(product) ) return (<div></div>);

		//
		//	Custom Option where .IMAGE.PRIMARY_PHOTO is from the EAV of the product
		//

		return (
			<img src={product.IMAGE.PRIMARY_PHOTO} className="image-responsive" />
		);
	}

}

export default ProductImage;
