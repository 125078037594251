// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from 'Translate';

import Nav from 'Nav';
import MegaMenu from 'MegaMenu';

import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';


// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class ReduxState extends React.Component {

	componentDidMount() {

	}

	updateReduxValue( e, _command, _value, _isNumber ) {

		if ( _isNumber )
			var resetTo = parseInt(e.target.value);
		else
			var resetTo = e.target.value;

		setReduxValue(reduxStore, _command, resetTo);
		var action = {
				type: _command,
				_value: resetTo
		};

		this.props.dispatch(action);
	}

	render () {

		return (

			<div className="flex-site">

					<div className="site-content">
							<Nav />
							<MegaMenu />
							<ProductSearch />

							<div className="grid-x grid-margin-x">
								<div className="cell small-12 larger-text bold text-center">
									This is a system page to reset any `out of sync` settings
									<br /><br />
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Active Locale' />
										</span>

										<textarea defaultValue={this.props.locale}
											onChange={evt => this.updateReduxValue(evt, 'SET_LOCALE', 'locale', false)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='System VAT Rate Applied' />
										</span>

										<textarea defaultValue={this.props.__VATrate}
											onChange={evt => this.updateReduxValue(evt, 'INIT_VAT-RATE', '__VATRate', false)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Active Show popups' />
										</span>

										<textarea defaultValue={this.props.showPopup}
											onChange={evt => this.updateReduxValue(evt, 'SET_POPUP', 'showPopup', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Invitation Only Shop' />
										</span>

										<textarea defaultValue={this.props.__invitationOnly}
											onChange={evt => this.updateReduxValue(evt, 'SET_INVITATION-ONLY', '__invitationOnly', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='System API Product Price Key' />
										</span>

										<textarea defaultValue={this.props.__productPriceKey}
											onChange={evt => this.updateReduxValue(evt, 'SET_PRODUCT-PRICE-KEY', '__productPriceKey', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Show product matches' />
										</span>

										<textarea defaultValue={this.props.__showProductMatches}
											onChange={evt => this.updateReduxValue(evt, 'SET_SHOW-PRODUCT-MATCHES', '__showProductMatches', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Active User ID' />
										</span>

										<textarea defaultValue={this.props.userID}
											onChange={evt => this.updateReduxValue(evt, 'SET_USER_ID', 'userID', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Approved Invitation User' />
										</span>

										<textarea defaultValue={this.props.userApproved}
											onChange={evt => this.updateReduxValue(evt, 'SET_USER_APPROVED', 'userApproved', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Active Cart ID' />
										</span>

										<textarea defaultValue={this.props.cartID}
											onChange={evt => this.updateReduxValue(evt, 'SET_CART_ID', 'cartID', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Items in active cart' />
										</span>

										<textarea defaultValue={this.props.itemsInCart}
											onChange={evt => this.updateReduxValue(evt, 'SET_ITEMS_IN_CART', 'itemsInCart', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
									<div className="input-group height-75px normal-text">

										<span className="input-group-label width-40 full-text-flow">
											<Translate text='Active Cart value' />
										</span>

										<textarea defaultValue={this.props.cartValue}
											onChange={evt => this.updateReduxValue(evt, 'SET_CART_VALUE', 'cartValue', true)}
											className="input-group-field height-75px" />

									</div>
								</div>

								<div className="cell small-12 larger-text bold text-center">
									<br />
									Refresh when done
									<br /><br />
								</div>

							</div>

					</div>

					<Footer />
					<ScrollTop />

			</div>


		);
	}

}

// Set the default values
ReduxState.defaultProps = {
	__currencySymbol: reduxStore.getState().__currencySymbol,
	__showProductMatches: reduxStore.getState().__showProductMatches,
	__VATrate: reduxStore.getState().__VATrate,
	__invitationOnly: reduxStore.getState().__invitationOnly,
	__productPriceKey: reduxStore.getState().__productPriceKey,
	locale: reduxStore.getState().locale,
	showPopup: reduxStore.getState().showPopup,
	userID: reduxStore.getState().userID,
  userName: reduxStore.getState().userName,
	userApproved: reduxStore.getState().userApproved,
	cartID: reduxStore.getState().cartID,
	itemsInCart: reduxStore.getState().itemsInCart,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			__currencySymbol: state.__currencySymbol,
			__showProductMatches: state.__showProductMatches,
			__VATrate: state.__VATrate,
			__invitationOnly: state.__invitationOnly,
			__productPriceKey: state.__productPriceKey,
			locale: state.locale,
			showPopup: state.showPopup,
			userID: state.userID,
			userName: state.userName,
			userApproved: state.userApproved,
			cartID: state.cartID,
			itemsInCart: state.itemsInCart,
			cartValue: state.cartValue
    }
}

export default connect(mapStateToProps)(ReduxState);
