// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';

import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


class ChangedLocale extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        };
	}

	// For a New Load which is always fine
	componentDidMount() {

		this._isMounted = true;


	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		return (
			<div className="flex-site">
				<div className="site-content">

						<Nav />
						<MegaMenu />
						<ProductSearch />

							<div className="grid-x grid-margin-x" >

								<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 large-8 large-offset-2">
										<div className="flow-text center darkred">
												<h4><Translate text="You have switched to locale" /> `{this.props.locale}`</h4>
												<br /><br />
										</div>
										<div className="flow-text center">

											{ this.props.locale != 'en' &&
												<Translate text="All source text originates in English; we apologise for any errors." /> }

											<br /><br />

											<Link to="/home">
				                <button className="no-select hollow button large secondary large-mobile-button">
													Refresh
												</button>
				              </Link>

											<br /><br />

										</div>

						    </div>

							</div>

				</div>
				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values
ChangedLocale.defaultProps = {
    locale: reduxStore.getState().locale
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        locale: state.locale
    }
}

export default connect(mapStateToProps)(ChangedLocale);
