// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { Parallax, Background } from 'react-parallax';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import CMSBlock from 'CMSBlock';

/*
	Displays an image of `imageName` from the CMS Media Store as a Parallax.
	This component is responsive, which is no small feat in React JS compared to good ole jQuery !

	Props are: from the Media-Store 'imageName': e.g. Photos/amazing-image.jpg
	and 'content': block-name from the CMS, which is generally centered inside the parallax image.

	This is a great feature for the home-page and loved by all web-designers. Note that the
	parallax image is kept proportional to its original size and height is adjusted depending on the
	browser width.

	The optional inner content [from the CMS] can be anything, but a very common example is:

	<div class="inner-div-center">		...centers inner-div vertically and horizontally
		<div class="...some CSS from app.css">
			Message, button or an anchor-link
		</div>
	</div>

*/

class CMSParallax extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					parallaxHeightAdjustment: 0.75,					// Parallax Images require a fiddle !!
        	imageURL: '',
					parallaxInnerContent: '',
					imageWidthOriginal: 0,
					imageHeightOriginal: 0,
					imageHeight: 0
        }
	}

	updateParallax () {
		// refactor the Parallax image to new dimensions
		if (this._isMounted)
				this.setState( {imageHeight: this.state.imageHeightOriginal
					* ($(window).width()/this.state.imageWidthOriginal) * this.state.parallaxHeightAdjustment } );

	}

	componentDidMount () {

		this._isMounted = true;
		var img = new Image();

		if (this._isMounted)
				window.addEventListener("resize", this.updateParallax.bind(this));

		// Load initial API Data
		var _data = { mediaURL: this.props.imageName };
		MyFetchPost( process.env.API + '/cms/getimage', _data, 5)
	    	.then( response => response.json() )
		    .then( apiImageURL => {
						if (this._isMounted)
								this.setState( { imageURL : apiImageURL } );
				})
				.then ( () => {

						var scriptPromise = new Promise((resolve, reject) => {

							img.src = this.state.imageURL;
							img.onload = resolve;
			  			img.onerror = reject;

						});

						scriptPromise.then(() => {

							if (this._isMounted) {

									// Image has now loaded...
									this.setState( { imageWidthOriginal: img.width } );
									this.setState( { imageHeightOriginal: img.height } );

									// Original Image:  			img.width x img.height
									// Ratio Image:  					img.width / img.height
									// Adjust Image height:  	$(window).width() / img.width

									this.setState( {imageHeight: img.height * ($(window).width()/img.width) * this.state.parallaxHeightAdjustment } );

							}

						});

				})
				.catch( /* It should retry !! */ )

		// We can do this aSync
		if ( !_.isEmpty(this.props.content) ) {

					// Load initial API Data
					var APIUrl = process.env.API + '/cms/getblock/' + this.props.content;
					MyFetchGet( APIUrl, 5)
							.then( response => response.json() )
							.then( apiBlockData => {
								if (this._isMounted)
									this.setState( { parallaxInnerContent : apiBlockData } );
							})
							.catch( /* It should retry !! */ )

			}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		if (this._isMounted)
				window.removeEventListener("resize", this.updateParallax.bind(this));

		this._isMounted = false;
  }

	render () {

		// Waiting for Image
		if ( _.isEmpty( this.state.imageURL) )  return (<div></div>);

		// Waiting for optional content and the DIV to load it into
		if ( ( _.isEmpty( this.state.parallaxInnerContent ) || this.state.imageHeight == 0 )
					&& !_.isEmpty(this.props.content)  )
				return (<div></div>);

		return (

			<Parallax
	      blur={0}
	      bgImage={this.state.imageURL}
	      bgImageAlt=""
				strength={this.state.imageHeight}
	      >
			  <div style={{ position: 'relative', height: this.state.imageHeight+'px', width: '100%' }}>
						{ !_.isEmpty(this.props.content)  &&
								<div dangerouslySetInnerHTML={{__html: this.state.parallaxInnerContent.content }} /> }
				</div>
			</Parallax>

		);
	}

}

export default CMSParallax;
