// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import Moment from 'react-moment';
import 'moment-timezone';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- Show Consignment for Order ---
  Props are:
	CART_ID		The ID of the order
*/


class ShowConsignmentOnOrderHistory extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
          consignment: null
      };
  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
    if (this._isMounted) {

      var APIUrl = process.env.API + '/logistics/consignment/get/' + this.props.CART_ID;
  		MyFetchGet(APIUrl, 3)
  		    .then( response => response.json() )
  		    .then( apiConsignmentData => {
            console.log(apiConsignmentData);
            if (this._isMounted)
                this.setState( {consignment : apiConsignmentData} );
          })
          .then( () => {
             this._isMounted = false;
          })
          .catch( /* Should try again */ );

    }
	}

  //
  //  Using old-school duplication of DidMount() and DidUpdate() here....
  //

  componentDidUpdate() {

    this._isMounted = true;

    // Load initial API Data
    if (this._isMounted) {

      var APIUrl = process.env.API + '/logistics/consignment/get/' + this.props.CART_ID;
  		MyFetchGet(APIUrl, 3)
  		    .then( response => response.json() )
  		    .then( apiConsignmentData => {
            // console.log(apiConsignmentData);
            if (this._isMounted)
                this.setState( {consignment : apiConsignmentData} );
          })
          .then( () => {
             this._isMounted = false;
          })
          .catch( /* Should try again */ );

    }
	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

  closeShowConsignmentOnOrderHistory () {

        setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
        var action1 = {
            type: 'SET_SLIDE_WINDOW',
            slideWindow: false
        };
        // Note that we apply the dispatch to the props: mapStateToProps(state)
		    this.props.dispatch(action1);

  }

	render () {

      if ( _.isEmpty(this.state.consignment) )
        return(<div></div>);

      return (
        <div className="slide-in-page-from-right">

            <div className="right-hand-side-close">
              <FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
              data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
                  onClick={evt => this.closeShowConsignmentOnOrderHistory(evt)} />
            </div>

            <div className="page-title black">Consignment #{this.state.consignment.id}</div>

            <br />
            <div className="message-alert center">{this.state.consignment.courier_name}</div>
            <div className="smaller-text orange bold center">{this.state.consignment.courier_service}</div>
            <br />

            <div>

              <div className="grid-x grid-margin-x">

                <div className="cell small-4 small-padding white-background">
                  Tracking Ref.
                </div>
                <div className="cell small-8 small-padding transparent-white-background">
                  { _.isEmpty(this.state.consignment.tracking_reference) ? 'N/A' :
                      this.state.consignment.tracking_reference }
                </div>

                <div className="cell small-12 small-text">&nbsp;</div>

                    <div className="cell small-4 small-padding white-background">
                      Packed @
                    </div>
                    <div className="cell small-8 small-padding transparent-white-background">
                      { _.isEmpty(this.state.consignment.packed_at) ? 'Not Yet' :
                        <Moment format="dddd DD MMM YYYY HH:mm">
													{this.state.consignment.packed_at}
												</Moment> }
                    </div>

                    <div className="cell small-12 small-text">&nbsp;</div>

                    <div className="cell small-4 small-padding white-background">
                      Shipped @
                    </div>
                    <div className="cell small-8 small-padding transparent-white-background">
                      { _.isEmpty(this.state.consignment.shipped_at) ? 'Not Yet' :
                        <Moment format="dddd DD MMM YYYY HH:mm">
													{this.state.consignment.shipped_at}
												</Moment> }
                    </div>

                    <div className="cell small-12 small-text">&nbsp;</div>

                    <div className="cell small-4 small-padding white-background">
                      Received @
                    </div>
                    <div className="cell small-8 small-padding transparent-white-background">
                      { _.isEmpty(this.state.consignment.received_at) ? 'Not Yet' :
                        <Moment format="dddd DD MMM YYYY HH:mm">
													{this.state.consignment.received_at}
												</Moment> }
                    </div>

              </div>

            </div>

        </div>
      );

	}

}

// Set the default values
ShowConsignmentOnOrderHistory.defaultProps = {
    slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(ShowConsignmentOnOrderHistory);
