// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import ReactPlayer from 'react-player';

/*
	Props are: same as for an normal <video> tag

	Plays a `hero` video of `videoName` from the CMS Media Store. While this might seem an
	extreme case for using an API, it insulates the front-end from the media-library

*/

class CMSVideo extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	videoURL : ''
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		var _data = { mediaURL: this.props.videoName };
		MyFetchPost( process.env.API + '/cms/getimage', _data, 3)
	    	.then( response => response.json() )
		    .then( apiVideoURL => {
						if (this._isMounted)
							this.setState( {videoURL : apiVideoURL} );
						})
						.catch( /* It should retry !! */ )

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

render () {

		if ( _.isEmpty( this.state.videoURL) )  return (<div></div>);

		return (
				<ReactPlayer
					url={this.state.videoURL}
					playing={ this.props.play || false }
					loop={ this.props.loop || false }
					controls={true}
					width="100%"
					height="100%"
				/>
		);
	}

}

export default CMSVideo;
