// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashRouter, Route, Redirect } from 'react-router-dom';

import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';
import PleaseWait from 'PleaseWait';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class MySettings extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					customerAttributes: null,
					_customerEmail: this.props.userEmail,
					_international: null,
					_clickRedirectCheckout: false,
					_clickRedirectHome: false,
					_confirmChanges: false,
					_pleaseWait: true
      };
  }

	componentDidMount() {

		this._isMounted = true;

		// Get Customer Settings made so far [may be a partial list]
		if ( this._isMounted ) {

				MyFetchGet(process.env.API + '/customer/get/' + this.props.userID, 3)
				    .then( response => response.json() )
						.then( customer => {
							if ( !_.isEmpty(customer) && this._isMounted ) {
									this.setState( { _customerEmail: customer.header.email } );
									this.setState( { _international: customer.header.international } );
									this.setState( { customerAttributes: customer.attributes } );
							}
						})
						.then( () => {

								this.setState( { _pleaseWait: false } );
								// Scroll Top
								window.scrollTo(0, 0);

						})
						.catch( error => {
								this.setState( { _pleaseWait: false } );
						});

			}

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	updateTextarea (evt, keyName) {
		$('#'+keyName).val(evt.target.value);
		this.setState( { _confirmChanges: false } );
	}

	updateCheckbox(evt, keyName) {
		// React checkboxes are rubbish
		var isChecked = $('#'+keyName).is(':checked');

		if ( isChecked )
				this.setState({ _international: 1 });
		else
				this.setState({ _international: 0 });

		this.setState( { _confirmChanges: false } );
	}

	commitSettings (evt) {

		// Happy to do this async
		this.setState( { _confirmChanges: true } );

		// Loop thru' all the settings
		{ Object.keys(this.state.customerAttributes).map( (keyName, index) => {
			this.updateSetting(this.props.userID, keyName, $('#'+keyName).val() )
		})};

		var _data = { key: 'international', value: this.state._international };
		MyFetchPost( process.env.API + '/customer/header/' + this.props.userID, _data, 3)
			.then( response => response.json() )
			.then( customer => {

					// if /settings was called during a checkout then return there after an update
					if ( this.props.redirectToCheckout ) {
						// clear this flag as the customer is now registered
						setReduxValue(reduxStore, 'SET_REDIRECT_TO_CHECKOUT', false);
				    var action1 = {
				        type: 'SET_REDIRECT_TO_CHECKOUT',
				        redirectToCheckout: false
				    };
				    this.props.dispatch(action1);

						// force redirect to /checkout
						this.setState({ _clickRedirectCheckout: true });
					} else {
						// force redirect to /home
						this.setState({ _clickRedirectHome: true });
					}
			})

	}

	newAddress (evt) {

		// Force a page clear
		this.setState( { customerAttributes: null } );

		// This API adds the next available address to the Customer EAV
		MyFetchGet(process.env.API + '/customer/addresses/new/' + this.props.userID, 3)
		    .then( response => response.json() )
				.then( customer => {
						// update Customer's new attributes
						this.setState( { customerAttributes: customer.attributes } );
				})

	}

	updateSetting (_customerID, _attributeKey, _value) {

		// console.log('Customer ' + _customerID + ' attribute ' + _attributeKey + ' set to ' + _value);
		var _data = { key: _attributeKey, value: _value };
		MyFetchPost( process.env.API + '/customer/post/' + _customerID, _data, 3)
			.then( response => response.json() )
			.then( response => {

					// console.log(response);

					// FULL_NAME is a special case as it's ALSO in the REDUX Store
					if ( _attributeKey == 'FULL_NAME' ) {
						setReduxValue(reduxStore, 'SET_USER_NAME', _value);
						// Set or dispatch [REDUX] action for prop
						var action = {
								type: 'SET_USER_NAME',
								userName: _value
						};
						this.props.dispatch(action);
					}

			})
			.catch( /* It should retry !! */ )

	}

	render () {

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		// Wait for the Fetch...
		if ( _.isEmpty(this.state.customerAttributes) )
			return (<div></div>);

		if ( this.state._clickRedirectCheckout ) {
			return (<Redirect to='/checkout' push />);
		}

		if ( this.state._clickRedirectHome ) {
			return (<Redirect to='/' push />);
		}

		return (
			<div className="flex-site">
				<div className="site-content">

					<Nav />
					<MegaMenu />
					<ProductSearch />

					<div className="page-title highlight-colour text-center">
						<Translate text="Settings" /><br />&nbsp;
					</div>

					{/* eMail */}
					<div className="grid-x">
						<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
							<div className="input-group height-75px normal-text">

								<span className="input-group-label bold">
									<Translate text="eMail" />
								</span>

								<textarea id="CustomerEmail" defaultValue={this.state._customerEmail}
									onChange={evt => this.updateTextarea(evt, 'CustomerEmail')}
									className="input-group-field height-75px disabled-input" disabled />

							</div>
						</div>
					</div>

					{/* International Shipping required | note onClick not onChange ! */}
					<div className="grid-x">
						<div className="center cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
								<input id="InternationalCustomer" type="checkbox" defaultChecked={this.state._international}
									onClick={evt => this.updateCheckbox(evt, 'InternationalCustomer')} />
								<label htmlFor="InternationalCustomer">
									<Translate text="I require shipping outside of the UK" />
								</label>
						</div>
					</div>

					{/* Customer EAVs */}
						{ Object.keys(this.state.customerAttributes).map( (keyName, index) => {

							return(
								<div key={index} className="grid-x">

									<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 row-padding">
										<div className="input-group height-75px normal-text">

										  <span className="input-group-label width-40 full-text-flow">
												<Translate text={ this.state.customerAttributes[keyName].displayName } />
											</span>

											{/* Only use `defaultValue` with textarea not `value` ! */}
										  <textarea id={keyName} defaultValue={this.state.customerAttributes[keyName].value}
												onChange={evt => this.updateTextarea(evt, keyName)}
												className="input-group-field height-75px" />

										</div>
									</div>

								</div>
							)

						}) }

						<br />
						<div className="grid-x grid-margin-x">
							<div className="cell small-6 medium-3 medium-offset-3 text-center">
								<button className="hollow button alert large-mobile-button"
									onClick={evt => this.commitSettings(evt)}>
									<Translate text="Confirm Details" />
								</button>
								&nbsp;&nbsp;
								{ this.state._confirmChanges && <FontAwesomeIcon id="ConfirmedChanges" icon="check" size="2x" color="lime" /> }
								<br />
								{ this.props.redirectToCheckout &&
										<div className="small-text black bold text-center">
											<Translate text="..and Checkout" />
										</div>}
							</div>

							<div className="cell small-6 medium-3 text-center">
								<button className="hollow button secondary large-mobile-button"
									onClick={evt => this.newAddress(evt)}>
									<Translate text="New Address" />
								</button>
							</div>

						</div>

						<br /><br />

				</div>

				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values = current Redux state values
MySettings.defaultProps = {
  userID: reduxStore.getState().userID,
	userName: reduxStore.getState().userName,
	userEmail: reduxStore.getState().userEmail,
	redirectToCheckout: reduxStore.getState().redirectToCheckout
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        userID: state.userID,
				userName: state.userName,
				userEmail: state.userEmail,
				redirectToCheckout: state.redirectToCheckout
    }
}

export default connect(mapStateToProps)(MySettings);
