// Use ES6 Class

import React from 'react';
import { Link, HashRouter, Route, Redirect } from 'react-router-dom';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';
import { translatePlaceholders, translateTooltips } from 'Useful';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import * as EmailValidator from 'email-validator';
import RegistrationMessages from 'RegistrationMessages';

import IPLog from 'IPLog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Register extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
					customerEmail: '',
					customerPassword: '',
					customerName: '',
					_whenRegistered: false
      };

			this.customerEmail = this.updateCustomerEmailValue.bind(this);
			this.customerPassword = this.updateCustomerPasswordValue.bind(this);
			this.customerName = this.updateCustomerNameValue.bind(this);
  }

	componentDidMount() {

		translatePlaceholders(this.props.locale);

		// Scroll Top after render()
	  window.scrollTo(0, 0)
	}


	updateCustomerEmailValue(evt) {

		// As a nice touch show the eMail address in green if the syntax is valid else in red
		if ( EmailValidator.validate(evt.target.value) )
			$('#RegistrationEmail').addClass('green').removeClass('red');
		else
			$('#RegistrationEmail').addClass('red').removeClass('green');

		this.setState( { customerEmail: evt.target.value } );
	}

	updateCustomerPasswordValue(evt) {
	  this.setState( { customerPassword: evt.target.value } );
	}

	updateCustomerNameValue(evt) {
	  this.setState( { customerName: evt.target.value } );
	}

	registerUser (evt) {

		// Ignore if this isn't a valid eMail
		if ( !( EmailValidator.validate(this.state.customerEmail) ) ) return false;

		MyFetchGet( process.env.API + '/customer/get/' + this.state.customerEmail, 3)
		    .then( response => response.json() )
				.then( customer => {
					if ( _.isEmpty(customer) ) {

						// Create new customer
						MyFetchGet( process.env.API + '/customer/new/' + this.state.customerEmail, 3)
							.then( response => response.json() )
							.then( newCustomer => {

									if ( !_.isEmpty(newCustomer) ) {

										// Somewhat belt and braces here...
										// Customer's new ID is newCustomer.id - confirm email and password if supplied

										var _data = {email: this.state.customerEmail, password: this.state.customerPassword};
										MyFetchPost( process.env.API + '/customer/update/' + newCustomer.id, _data, 3)
										    .then( response => response.json() )
										    .then( updateCustomer => {

														// R E D U X
														setReduxValue(reduxStore, 'SET_USER_ID', updateCustomer.header.id);
														setReduxValue(reduxStore, 'SET_USER_EMAIL', this.state.customerEmail);
														// Optional User Name
														setReduxValue(reduxStore, 'SET_USER_NAME', this.state.customerName);
														// Set or dispatch [REDUX] action for prop
												    var action1 = {
												        type: 'SET_USER_ID',
												        userID: updateCustomer.header.id
												    };
														var action2 = {
												        type: 'SET_USER_EMAIL',
												        userEmail: this.state.customerEmail
												    };
														var action3 = {
																type: 'SET_USER_NAME',
																userName: this.state.customerName
														};
												    // Note that we apply the dispatch to the props: mapStateToProps(state)
												    this.props.dispatch(action1);
														this.props.dispatch(action2);
														this.props.dispatch(action3);

														// Optionally update FULL_NAME [ reserved-name ] in the E A V
														var _data = { key: 'FULL_NAME', value: this.state.customerName };
														MyFetchPost( process.env.API + '/customer/post/' + updateCustomer.header.id, _data, 3)
														    .then( response => response.json() )
														    .then( updatedCustomerPost => {

																	// Send registration eMail [ async ]
																	var _data = {email: 'Registration', customer: updatedCustomerPost.header.id, subject: 'Welcome' };
																	MyFetchPost( process.env.API + '/email/send', _data, 3)
																    .then( response => response.json() )
																    .then( response => {

																    });

																	return updatedCustomerPost;

														    })
																.then( updatedCustomerPost => {

																		// Update the current cart [ if applicable ] to this new customer
																		var _cartdata = { id: this.props.cartID, key: 'customer_id', value: updatedCustomerPost.header.id};
																		// console.log('Update Cart ' + this.props.cartID + ' to customer ' + updatedCustomerPost.header.id);
																		if ( this.props.cartID > 0) {
																			MyFetchPost( process.env.API + '/cart/update', _cartdata, 3)
																					.then( response => response.json() )
																					.then( cart => {
																							// Returns `cart` = structured cart-object with products
																							// console.log(cart);
																					})
																		}

																})
																.then( () => {
																		// Ask the user to fill in their details/settings post successful registration
																		this.setState( { _whenRegistered: true } );
																})

								  				})	// End of Update

									}	 // If closure for newCustomer validation

							})	// End of New Customer

					} else {

						// Nope, customer eMail already registered

						// R E D U X
						setReduxValue(reduxStore, 'SET_USER_ID', -1);
						// Optional User Name
						setReduxValue(reduxStore, 'SET_USER_NAME', '');
						// Set or dispatch [REDUX] action for prop
						var action1 = {
								type: 'SET_USER_ID',
								userID: -1
						};
						var action2 = {
								type: 'SET_USER_NAME',
								userName: ''
						};
						// Note that we apply the dispatch to the props: mapStateToProps(state)
						this.props.dispatch(action1);
						this.props.dispatch(action2);

					}
		    })
				.then( customer => {
					// Possibly translate any new toolips which might appear after a login
					translateTooltips(this.props.locale);
				})

	}

	render () {

		// State based redirect, see above, where we set the state trigger _whenRegistered to true
		// which in effect then causes the below Redirect.
		if (this.state._whenRegistered) {
			return (<Redirect to='/mysettings' push />);
		}

		return (
			<div className="flex-site">
				<IPLog URL="/#/register" />
				<div className="site-content">
					<Nav />
					{/* Only show menu and search when registered */}
					{ this.props.userID > 0 && <MegaMenu /> }
					{ this.props.userID > 0 && <ProductSearch /> }

					<div className="page-title text-center">
						<Translate text="Customer Details" />
					</div>

						{/* eMail */}
						<div className="grid-x">
							<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

								<div className="input-group">
								  <span className="input-group-label"><FontAwesomeIcon icon="at" size="1x" color="grey" /></span>
								  <input id="RegistrationEmail" value={this.state.customerEmail} onChange={evt => this.updateCustomerEmailValue(evt)}
										className="input-group-field" type="email" placeholder="eMail Address" />
								</div>

							</div>
							<div className="cell small-1 medium-3 large-4"></div>
						</div>

						{/* password */}
						<div className="grid-x">
							<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

								<div className="input-group">
								  <span className="input-group-label"><FontAwesomeIcon icon="key" size="1x" className="highlight-colour" /></span>
								  <input onChange={evt => this.updateCustomerPasswordValue(evt)}
										className="input-group-field" type="password" placeholder="Optional password" />
								</div>

							</div>
							<div className="cell small-1 medium-3 large-4"></div>
						</div>

						{/* Name */}
						<div className="grid-x">
							<div className="cell auto small-offset-1 medium-offset-3 large-offset-4">

								<div className="input-group">
								  <span className="input-group-label"><FontAwesomeIcon icon="user-edit" size="1x" color="grey" /></span>
								  <input value={this.state.customerName} onChange={evt => this.updateCustomerNameValue(evt)}
										className="input-group-field" type="text" placeholder="Your name [also optional]" />
								</div>

							</div>
							<div className="cell small-1 medium-3 large-4"></div>
						</div>

						{/* buttons */}
						<div className="grid-x">

							<div className="cell flex-container align-center auto small-offset-1 medium-offset-3 large-offset-4">
								<button className="hollow button secondary large-mobile-button"
									onClick={evt => this.registerUser(evt)}>
									<Translate text="Sign Up" />
								</button>

								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

								<Link to={`/login`}>
									<button className="hollow button secondary large-mobile-button protip"
										data-pt-position="right" data-pt-scheme="black"
										data-pt-classes="my-tooltip" data-pt-title="Go to login instead">
										<Translate text="Already registered?" />
									</button>
								</Link>

							</div>
							<div className="cell flex-container small-1 medium-3 large-4"></div>
						</div>

						<br />
						<RegistrationMessages />
						<br />

					</div>

				<Footer />
				<ScrollTop />
			</div>
		);
	}

}

// Set the default values = current Redux state values
Register.defaultProps = {
	locale: reduxStore.getState().locale,
  userID: reduxStore.getState().userID,
	userEmail: reduxStore.getState().userEmail,
  userName: reduxStore.getState().userName,
	cartID: reduxStore.getState().cartID
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
				locale: state.locale,
        userID: state.userID,
				userEmail: state.userEmail,
        userName: state.userName,
				cartID: state.cartID
    }
}

export default connect(mapStateToProps)(Register);
