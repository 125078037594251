// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import LookingGlass from "react-looking-glass";			// Good
// import Magnifier from "react-magnifier";						// Tested - Weak

//
//		Simple wrapper for a Looking Glass [ see above options ]
//
//		v1.0 This is NOT Reactive [ just for Native Mobile users ]
//

class MyLookingGlass extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	_mobile: false
        };
	}

	componentDidMount () {

			this._isMounted = true;

			if ( $(window).width() <= 640 ) {
					if (this._isMounted)
							this.setState( { _mobile: true } );

			} else {
					if (this._isMounted)
							this.setState( { _mobile: false } );

			}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		return(

			<div>
							{ !this.state._mobile && <LookingGlass
								src={this.props.src}
								className={this.props.className}
								zoomFactor={2}
								squareMagnifier={true}
								size={300}
							/> }

							{ this.state._mobile && <img
								src={this.props.src}
								className={this.props.className}
							/> }

			</div>
		);
	}

}

export default MyLookingGlass;
