// Use ES6 Class

import React from 'react';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import _ from 'lodash';
import { formatEAV, translateTooltips } from 'Useful';
import Translate from 'Translate';
import { Link, HashRouter, Route, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyLookingGlass from "MyLookingGlass";
import GoBack from 'GoBack';
import IPLog from 'IPLog';

import Nav from 'Nav';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import ProductImageCarousel from 'ProductImageCarousel';
import MatchingProducts from 'MatchingProducts';
import ProductReviews from 'ProductReviews';

import AddToCart from 'AddToCart';
import MakeAuctionBid from 'MakeAuctionBid';

import MySelect from 'MySelect';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- A full page product display ---
  Props are:
	PRODUCT_ID			The product ID [or its name]
*/

class ProductFullDisplay extends React.Component {

	constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
				productData : null,
				_PRODUCT_ID: props.PRODUCT_ID,		// Initial catch to check for a change in the product code
				_detectedNewProduct: false,				// see Component Did Update() to mark new product refresh-page
				_matchingProducts : '',						// trigger State var
				_productReviews : '',							// trigger State var
				_quantity: 1,
				_auction: false,
				_makeBid: false,
				_showTick: false,									// indicates that added to Cart
				_showCross: false,								// add-to-cart failed [normally no stock]
				_callAddToCart: false,
				_showPrimaryImage: true, 					// Default
				_gallery: [],
				_galleryImageToDisplay: '',
				_validatedImage: '',
				_options: [],
				_bought: false
      };

  }

	// ---------------------------------------------------------------------------

	// Note that this must be static
	static getDerivedStateFromProps(props, state) {
	    // Clear out previously-loaded data (so we don't render stale stuff).
	    if (props.PRODUCT_ID !== state._PRODUCT_ID ) {
					// console.log('Detected change in the product');
					return {
		        productData: null,
		        _PRODUCT_ID: props.PRODUCT_ID,
						_detectedNewProduct: true
		      };
	    }

	    // No state update necessary
	    return null;
	}

	loadAPIData () {

		// Load initial API Data
		if (this._isMounted) {
			// console.log( 'Load API Data for ' + this.state._PRODUCT_ID );
				var APIUrl = process.env.API + '/product/get/' + this.state._PRODUCT_ID;
				MyFetchGet( APIUrl, 5 )
				    .then( response => response.json() )
				    .then( (apiProductData) => {

							// Debug
							// console.log( apiProductData );

							if (this._isMounted)
								this.setState( {productData : apiProductData} );

							return apiProductData;
				    })
						.then( (productData) => {

								// This is an odd one in that we need a `state` here rather than just testing
								// this.props.product.header.auction in the render() or we get a `0` displayed ?
								if ( this._isMounted && productData.header.auction) {
										this.setState( { _auction : true } );
								}

								// validate Primary Image
								var img = new Image();

								if ( !_.isEmpty(productData.IMAGE.PRIMARY_PHOTO) ) {

											var scriptPromise = new Promise((resolve, reject) => {

												img.src = productData.IMAGE.PRIMARY_PHOTO;
												img.onload = resolve(img);
												img.onerror = reject;

											});

											scriptPromise.then( (img) => {
												if (this._isMounted) {
													this.setState( { _validatedImage : productData.IMAGE.PRIMARY_PHOTO } );
												}
												return img;
											})
											.then( (image) => {
												// if you want to test the f out of this image....
												// image.onload = function() {}
											})
											.catch( () => {
												if (this._isMounted)
													this.setState( { _validatedImage : 'images/no-image.png' } );
											})

								} else {
											if (this._isMounted)
												this.setState( { _validatedImage : 'images/no-image.png' } );
								}

						})
						.then( () => {
							// console.log('loadAPI Data should now be blocked');
							this._isMounted = false;		// IMPORTANT !! to stop memory leaks
						});
		}

	}

	componentDidMount () {

		this._isMounted = true;

		setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		this.loadAPIData();

		//
		//		Tooltip Translation
		//

		translateTooltips(this.props.locale);

	}

	componentDidUpdate () {

		setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );

    var action1 = {
        type: 'SET_SLIDE_WINDOW',
        slideWindow: false
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		// console.log('Product Full Display Component says it has been updated');

		if ( !this.state._detectedNewProduct ) {
			// console.log('Detected a new product state already managed');
			return false;
		}

		// OK so proceed...
		this._isMounted = true;
		// Say that we have now detected a New Product to stop the above from repeating
		// console.log('We have now detected a New Product');
		this.setState( { _detectedNewProduct : false } );
		this.loadAPIData();

		//
		//		Tooltip Translation
		//

		translateTooltips(this.props.locale);

	}

	componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

	// ---------------------------------------------------------------------------

	setQuantity (e) {

		// Catch the weird input error which allows the user to delete the number which they shouldn't
		// really be able to do but they can ?
		if ( e.target.value < 1 ) return false;

		this.setState( {_quantity : e.target.value} );
	}

	addStandardProductToCart (evt) {

		// User has clicked BUY.

		// First collect any <MySelect /> attributes of this product. I am using jQuery here
		// which might seem odd in a React env, but it's a very simple way to `get` the options
		// We do have to remove any spaces though that might have been used in the DIV id

		var _selectableAttributes = [];
		this.state.productData.attributes.map( (attribute, key) => {

			var attributeKeyNameWithUnderscores = (attribute.key_name).replace(/ /g,"_");
			// console.log( attributeKeyNameWithUnderscores );

			var _selectedOption = $('#'+attributeKeyNameWithUnderscores).text().trim();
			if ( !_.isEmpty(_selectedOption) ) {
				_selectableAttributes.push([attribute.key_name, _selectedOption]);
			}

		});
		// console.log(_selectableAttributes);
		this.setState( {_options : _selectableAttributes} );

		// Add this `standard` product x Quantity to the cart
		// console.log( this.state._quantity, 'x', this.state.productData.header.name );
		this.setState( {_callAddToCart : true} );
	}

	makeStandardProductBid ( evt ) {

		setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', true );
		var action1 = {
				type: 'SET_SLIDE_WINDOW',
				slideWindow: true
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);

		this.setState( {_makeBid : true} );

	}

	// ---------------------------------------------------------------------------

	showFlipPhoto () {
		this.setState( {_showPrimaryImage : false} );
	}

	showPrimaryPhoto () {
		this.setState( {_showPrimaryImage : true} );
	}

	// ---------------------------------------------------------------------------

	showBuySuccess () {
		this.setState( {_showCross : false} );
		this.setState( {_showTick : true} );
		this.setState( {_bought : true} );
	}

	showBuyFail () {
		this.setState( {_showTick : false} );
		this.setState( {_showCross : true} );
	}

	// ---------------------------------------------------------------------------

	loadGallery (_folder) {

		if ( !this._isMounted ) return false;

		// Load initial API Data
		var _data = { folder: _folder };
		MyFetchPost( process.env.API + '/media/filesinfolder', _data, 3)
	    	.then( response => response.json() )
		    .then( apiFiles => {

						if ( apiFiles.length ) {
							this.setState( { _gallery : apiFiles} );
							this.setState( {_galleryImageToDisplay : apiFiles[0] } );
						}

				})
				.then( () => {
						// Unblock this...
						// console.log('Unblock Product Gallery');
						this._isMounted = false;
				})
				.catch( /* It should retry !! */ )

	}

	clickOnGalleryCarouselPhoto ( _url ) {
		this.setState( {_galleryImageToDisplay : _url } );
	}

	// ---------------------------------------------------------------------------

	productMatches (evt) {
		this.setState( { _matchingProducts: <MatchingProducts PRODUCT_ID={this.props.PRODUCT_ID} /> } );
		if ( evt !== null )
			$('html, body').animate({scrollTop: $('#MatchingProductsList').offset().top-150}, 2000);
	}

	productReviews (evt) {
		this.setState( { _productReviews: <ProductReviews PRODUCT_ID={this.props.PRODUCT_ID} /> } );
		$('html, body').animate({scrollTop: $('#ProductReviews').offset().top-150}, 2000);
	}

	// ---------------------------------------------------------------------------

	checkProductRouting( _keyname, _selectedValue ) {

		var product = this.state.productData;

		if ( product.header.on_select == 1 || this.props.HAS_BEEN_ROUTED ) {

			// Check to see if this `select` might trigger a redirect ?
			var _data = {
				product_id: product.header.id,
				select_key_name_1: _keyname,
				selected_value_1: _selectedValue
			};

			MyFetchPost( process.env.API + '/product/checkredirect', _data, 3)
		    	.then( response => response.json() )
			    .then( product => {

						if ( !_.isEmpty(product) ) {
							// console.log( 'Redirect to on-select product #' + product.header.id + ' | '+ product.header.name);
							// Old School as too many weird react Nested errors...
							// Also use Product ID not the name as on-select products often have the same name
							window.location.assign('/#/redirect-product/' + product.header.id);
						}
					})
					.then( () => {

					})
					.catch( /* It should retry !! */ )

		}
	}

	render () {

		var product = this.state.productData;

		// Wait for the product to load
		if ( _.isEmpty(product) ) return (<div></div>);

		// Do we have a folder of Images ?
		if ( !_.isEmpty(product.IMAGE.GALLERY) ) {
				this.loadGallery(product.IMAGE.GALLERY);
		}

		return (
			<div className="flex-site">
				<IPLog URL={"/#/product/" + product.header.name} />
				<Nav />

				<br />
				<div className="grid-x grid-margin-x">

					{/* Photo to the left or `full screen-ish` was of class `image-responsive` now `max-photo` */}
					<div className="cell small-6 small-offset-3 medium-4 medium-offset-0 large-5 large-offset-0">


						{/* ----------------------------

								By far the most common option is to have TWO photos, often front and back and this basic
								below option satisfies this need for v1.2

							 ---------------------------- */}

						<div id="ProductImageContainer" className="outer-div">

								<div className="center-image-container">

									{/* ---------------------------- PRIMARY with Thumbnail Flip ---------------------------- */}

									{ _.isEmpty(product.IMAGE.GALLERY) && this.state._showPrimaryImage && !_.isEmpty(product.IMAGE.PRIMARY_PHOTO)  &&
										<MyLookingGlass src={this.state._validatedImage} className="responsive-image max-photo" /> }

									{/* ----------------------------- FLIP with Thumbnail Primary --------------------------- */}

									{ _.isEmpty(product.IMAGE.GALLERY) && !this.state._showPrimaryImage && !_.isEmpty(product.IMAGE.FLIP_PHOTO)  &&
										<MyLookingGlass src={product.IMAGE.FLIP_PHOTO} className="responsive-image max-photo" /> }

								</div>

								<div className="inner-div-thumbnail">

									{ _.isEmpty(product.IMAGE.GALLERY) && this.state._showPrimaryImage && !_.isEmpty(product.IMAGE.FLIP_PHOTO)  &&
										<img src={product.IMAGE.FLIP_PHOTO} className="thumbnail-swap"
											onClick={evt => this.showFlipPhoto(evt)} /> }

									{ _.isEmpty(product.IMAGE.GALLERY) && !this.state._showPrimaryImage && !_.isEmpty(product.IMAGE.PRIMARY_PHOTO)  &&
										<img src={product.IMAGE.PRIMARY_PHOTO} className="thumbnail-swap"
											onClick={evt => this.showPrimaryPhoto(evt)} /> }

								</div>

								{/* ----------------------------- Full Gallery --------------------------- */}

								{ !_.isEmpty(product.IMAGE.GALLERY) &&
										<MyLookingGlass src={this.state._galleryImageToDisplay} className="responsive-image max-photo" /> }

								{/* -------------------------  S O L D   O U T  -------------------------- */}

								{ product.header.sold_out_sign == 1 &&
									<img src="images/sold.png" className="sold-out-overlay" /> }

								{/* ------------------  R E S E R V E   N O T   M E T  ------------------- */}

								{ product.header.auction_reserve > product.header.price &&
									<img src="images/reserve-not-met.png" className="sold-out-overlay" /> }

						</div>

						{/* ----------------------------

								Gallery as a Carousel, a click will change: this.state._galleryImageToDisplay

							 ---------------------------- */}

						{ !_.isEmpty(product.IMAGE.GALLERY) && <div>
										<br /><br />
										<ProductImageCarousel
											className="product-images-carousel-container"
											images={this.state._gallery}
											onClickImage={this.clickOnGalleryCarouselPhoto.bind(this)}
										/>
										</div>}

					</div>

					<br />

					{/* Details to the right */}
					<div id="ProductDataContainer" className="cell small-10 small-offset-1 medium-7 medium-offset-0 large-6 large-offset-0">

						{/* List Style */}
						<br />

						<div className="grid-x align-middle">

							<div className="cell small-3 medium-3 large-2"><GoBack /></div>

							<div className="cell small-4 medium-4 large-3">
								<button className="button no-select hollow secondary protip"
									data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
									data-pt-title="From verified customers"
									onClick={evt => this.productReviews(evt)}>
										<Translate text="Reviews" />
								</button>
							</div>

							{ this.props.__showProductMatches == 0 && <div className="cell small-4 medium-3 large-5">
								<button className="button no-select hollow secondary"
									onClick={evt => this.productMatches(evt)}>
										<Translate text="Matched Products" />
								</button>
							</div> }

							<div className="cell large-2">
									{ (product.header.on_select == 1 || this.props.HAS_BEEN_ROUTED ) &&
										<FontAwesomeIcon icon="list-ul" size="2x"
												className="protip highlight-colour not-tablet not-mobile" data-pt-position="above"
												data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Product has price options"
										/> }
							</div>

						</div>

						<br /><br />
						<div className="product-name"><Translate text={product.header.name} /></div>
						<br />
						<div className="product-description">
							<div dangerouslySetInnerHTML={{__html: product.header.description }} />
						</div>
						<br />

								{product.attributes.map( (attribute, key) => {

										{/* Ignore those attributes marked as not required */}
										if ( !(attribute.include_in_full_list) ) return null;

										{/* Ignore any blank attributes [optional] */}
										if ( attribute.key_value == null ) return null;

								    return (
											<div key={key} className="grid-x align-middle">

												<div className="cell small-12 medium-12 large-4 light-grey-background bold product-attribute-box">
													<Translate text={attribute.key_name} />
												</div>

												{/*
													-----------------
													Normal plain text
													-----------------
												*/}

												{ attribute.content_is_HTML == 0 && <div className="cell small-12 medium-12 large-7 large-offset-1">

														{ _.isArray( attribute.key_value ) &&
																<MySelect keyName={attribute.key_name} attributeOptions={attribute.key_value}
																	onChangeValue={this.checkProductRouting.bind(this)}/> }

														{/* Price Key Only -- as above -- Invitation Store - but the user is NOT approved -
															There is a highlight option for an attribute */}
															{ !_.isArray( attribute.key_value )
																	&& this.props.__invitationOnly == 1
																	&& this.props.userApproved == 0
																	&& attribute.key_name == this.props.__productPriceKey
																	&& attribute.highlight == 1
																	&& <span className="highlight-attribute highlight-colour eav-value mobile-center tablet-center"><br />P.O.A.</span> }


														{/* Open Store - There is a highlight option for an attribute */}
														<span className="highlight-attribute highlight-colour eav-value nudge-down-2 mobile-center tablet-center">
															{ !_.isArray( attribute.key_value )
																	&& (this.props.__invitationOnly == 0
																		|| this.props.userApproved == 1
																		|| attribute.key_name != this.props.__productPriceKey)
																	&& attribute.highlight == 1
																	&& formatEAV(attribute.key_value, attribute.data_type, this.props.__currencySymbol) }
														</span>

														<span className="eav-value nudge-up-1 mobile-center tablet-center">
															{/* <div className="cell small-12 not-desktop">&nbsp;</div> */}
															{ !_.isArray( attribute.key_value )
																	&& attribute.highlight == 0
																	&& formatEAV(attribute.key_value, attribute.data_type, this.props.__currencySymbol) }
														</span>

													</div> }

													{/*
														---------------------------------------------------------------
														Content is HTML - catch user error if a select Option is ticked
														---------------------------------------------------------------
														*/}

													{ attribute.content_is_HTML == 1 && <div className="cell small-12 medium-12 large-7 large-offset-1">

															{ !_.isArray( attribute.key_value ) &&
																	<div dangerouslySetInnerHTML={{__html: attribute.key_value}} /> }

													</div> }

												{/* Creates a small line break */}
												<div className="cell small-12 small-text">&nbsp;</div>

											</div>
								    );

								})}

						{/*
							Below - Product attributes
						*/}

						<div className="cell small-12">
							<div className="grid-x">

								<div className="cell small-6 text-center">
									<br />
									<div className="input-group">

									  <span className="input-group-label"><Translate text="No." /></span>

									  { !this.state._auction && <input className="input-group-field width-200px" type="number" min="1"
											defaultValue={this.state._quantity} onChange={evt => this.setQuantity(evt)} /> }

										{ this.state._auction && <input className="input-group-field" type="number" min="1"
											defaultValue={this.state._quantity}	disabled /> }

									</div>

								</div>

								<div className="cell small-1 text-center">
									<br />
									{ this.state._showTick && <FontAwesomeIcon icon="check" size="1x" color="black" /> }
									{ this.state._showCross && <FontAwesomeIcon icon="times" size="1x" color="red" /> }
								</div>

								<div className="cell small-5">
									<br />

									{/* Standard B U Y button - Open Shop */}

									{ !(this.state._auction) &&
										this.props.__invitationOnly == 0 &&
										<button className="button large rounded bordered warning warning-colour large-mobile-button"
											onClick={evt => this.addStandardProductToCart(evt)}>
											{ this.state._bought == false && <Translate text="BUY" /> }
											{ this.state._bought == true && <FontAwesomeIcon icon="shopping-cart" /> }
										</button> }

									{/* Standard B U Y button - Invitation Shop with User Approved */}

									{ !(this.state._auction) &&
										this.props.__invitationOnly == 1 &&
										this.props.userApproved == 1 &&
										<button className="button large rounded bordered warning warning-colour large-mobile-button"
											onClick={evt => this.addStandardProductToCart(evt)}>
											{ this.state._bought == false && <Translate text="BUY" /> }
											{ this.state._bought == true && <FontAwesomeIcon icon="shopping-cart" /> }
										</button> }


									{ (this.state._auction) && <FontAwesomeIcon className="not-mobile" icon="gavel" size="2x" color="grey" /> }
									&nbsp; &nbsp;

									{/* User OK to make a Bid */}
									{ this.props.userID > 0 && this.state._auction && <button className="button large rounded bordered warning auction-colour large-mobile-button"
											onClick={evt => this.makeStandardProductBid(evt)}>
											<Translate text="BID" />
										</button> }

									{/* User must be active to make a Bid */}
									{ this.props.userID <= 0 && this.state._auction &&
										<Link to="/register">
											<button className="button large rounded bordered warning auction-colour large-mobile-button">
												<Translate text="BID" />
											</button>
											<div className="small-text black bold"><Translate text="Register | Login" /></div>
										</Link> }

								</div>

							</div>
						</div>

					</div>

				</div>

				<br />
				<div id="MatchingProductsList" className="grid-x grid-margin-x">
					<div className="cell small-10 small-offset-1">
						{this.state._matchingProducts}
						{ this.props.__showProductMatches == 1 &&
							<MatchingProducts PRODUCT_ID={this.props.PRODUCT_ID} /> }
					</div>
				</div>

				<br />
				<div id="ProductReviews" className="grid-x grid-margin-x">
					<div className="cell small-10 small-offset-1">
						{this.state._productReviews}
					</div>
				</div>
				<br />

					{/* _callAddToCart is a state flag which triggers <AddToCart ../>. This is
						set when the user clicks the above addStandardProductToCart() onClick */}

					{this.state._callAddToCart && this.state._quantity >= 1 &&
						<AddToCart
							PRODUCT_ID={product.header.id}
							QUANTITY={this.state._quantity}
							OPTIONS={this.state._options}
							BUNDLE={false}
							onSuccess={this.showBuySuccess.bind(this)}
							onFail={this.showBuyFail.bind(this)}
							/>}

					{/* ----------------------------- Slider Window ------------------------------ */}

					<div className="grid-x grid-margin-x">
							<div className="cell small-10 small-offset-1">
			            {this.state._makeBid &&
										<MakeAuctionBid
											PRODUCT={product}
											DISPLAY={this.state._validatedImage}
											/>}
	          	</div>
					</div>

				<Footer />
				<ScrollTop />
			</div>
		);
	}

}

// Set the default values
ProductFullDisplay.defaultProps = {
		__currencySymbol: reduxStore.getState().__currencySymbol,
		__showProductMatches: reduxStore.getState().__showProductMatches,
		__invitationOnly: reduxStore.getState().__invitationOnly,
		__productPriceKey: reduxStore.getState().__productPriceKey,
		userID: reduxStore.getState().userID,
		userApproved: reduxStore.getState().userApproved,
		slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			__currencySymbol: state.__currencySymbol,
			__showProductMatches: state.__showProductMatches,
			__invitationOnly: state.__invitationOnly,
			__productPriceKey: state.__productPriceKey,
			userID: state.userID,
			userApproved: state.userApproved,
			slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(ProductFullDisplay);
