// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Slider from "react-slick";
import Masonry from 'react-masonry-css';

/*
	S L I C K    C A R O U S E L
*/

class SlickCarousel extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_images: [],
					_maxImagesOnScreen: props.slides,
					_isCarousel: true 											// default is a normal carousel
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
			var APIUrl = process.env.API + '/cms/getcarousel/' + this.props.carousel;
			MyFetchGet(APIUrl, 3)
			    .then( response => response.json() )
			    .then( apiCarouselData => {
								if ( this._isMounted) {
											if ( apiCarouselData.images.length < 4 )
													this.setState( {_maxImagesOnScreen : apiCarouselData.images.length} );

											this.setState( {_images : apiCarouselData.images} );
											this.setState( {_isCarousel : apiCarouselData.header.is_carousel } );
								}
			    })
					.then( () => {
							// this._isMounted = false;
					})
					.catch( /* It should retry !! */ )
		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	onClickImage(url) {
		// Open | Replace in the same tab...
		window.location.replace(url);
	}

	render () {

		// Not ready yet...
		if ( _.isEmpty( this.state._images) )
				return (<div></div>);

		/* A single image carousel ! - assumed work in progress */
		// if ( this.state._maxImagesOnScreen < 2 ) {
		// 	return (
		// 		<img src={this.state._images[0].image_url} style={{ 'height': '300px' }} />
		// 	)
		// }

		{/* You are welcome to customise this - just see
			Props and Methods https://www.npmjs.com/package/react-slick */}

		var settings = {
			slidesToShow: this.props.slides,
			arrows: true,
			dots: false,
			autoplay: true,
			autoplaySpeed: 3000,
			responsive: [
							{
								breakpoint: 2540,
								settings: {
									slidesToShow: this.props.slides
								}
							},
			        {
			          breakpoint: 640,
			          settings: {
			            slidesToShow: 1
			          }
			        }
			      ]
		}

		/* The below is for its sister react-masonry */

		const breakpointColumnsObj = {
		  default: 4,
		  1280: 3,
		  800: 2,
		  640: 1
		};

		if ( this.state._isCarousel )
			return (
				<Slider {...settings}>

						{this.state._images.map( (image, key) => {

							if ( _.isEmpty(image.click_thru) )

								return(
									<div key={key}>
										<img src={image.image_url} className={'image-responsive ' + this.props.className} />
									</div>
								)

							else

								return(
									<div key={key}>
											<img src={image.image_url} className={'image-responsive pointer ' + this.props.className}
												onClick={ evt => this.onClickImage(image.click_thru) } />
									</div>
								)

						})}

				</Slider>
			);

		/* .... or a Masonry Grid */

		if ( !this.state._isCarousel )
				return (

						<Masonry
						  breakpointCols={breakpointColumnsObj}
						  className="my-masonry-grid"
						  columnClassName="my-masonry-grid_column">

								{this.state._images.map( (image, key) => {

									if ( _.isEmpty(image.click_thru) )

										return(
											<div key={key}>
												<img src={image.image_url} className={'image-responsive ' + this.props.className} />
											</div>
										)

									else

										return(
											<div key={key}>
													<img src={image.image_url} className={'image-responsive pointer ' + this.props.className}
														onClick={ evt => this.onClickImage(image.click_thru) } />
											</div>
										)


								})}

						</Masonry>

				);
	}

}

export default SlickCarousel;
