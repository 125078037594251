// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import ProductDisplay from 'ProductDisplay';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Display all the products matching this Search ---
	searchText from R E D U X
*/

class ProductSearchList extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	productsData : [],
					_stateSearchText: ''
        };
	}

	//
	//	In line with new React V17 and stability, we now use getDerivedStateFromProps () using a state flag
	//	as above and componentDidUpdate () replaces componentDidMount () - this is much better
	//	because it stops the duplication of the API load or code in the old componentWillReceiveProps ()
	//

	// Note that this must be static
	static getDerivedStateFromProps(props, state) {
	    // Clear out previously-loaded data (so we don't render stale stuff).
	    if (props.searchText !== state._stateSearchText) {
					return {
		        productsData: [],
		        _stateSearchText: props.searchText,
		      };
	    }

	    // No state update necessary
	    return null;
	}

	componentDidUpdate () {

		this._isMounted = true;

		// Load initial API Data
		// console.log('Calling API get products search ' + this.props.searchText);
		if (this._isMounted) {
			var _data = { keywords: this.props.searchText };
			MyFetchPost( process.env.API + '/product/search', _data, 3)
			    .then( response => response.json() )
			    .then( apiProductsData => {
						if (this._isMounted)
							this.setState( {productsData : apiProductsData} );
			    })
					.then( () => {
							this._isMounted = false;
					})
		}

	}

	componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		// Change this to a GIF
		if ( _.isEmpty(this.state.productsData) ) {
			return (
				<div className="grid-x grid-margin-x">
					<div className="cell small-12 centered page-title">
						...
					</div>
				</div>
			);
		}

		return (
			<div className="grid-x grid-margin-x">

				{this.state.productsData.map( (product, key) => {

					switch(this.props.displayGrid) {

						// 3x3
						case 0:
							return(
								<div key={key} className="cell small-12 medium-6 large-4">
					    		<ProductDisplay product={product} truncate='MAX' />;
					    	</div>)

						// 2x2
						case 1:
							return(
								<div key={key} className="cell small-12 medium-6 large-6">
					    		<ProductDisplay product={product} truncate='MIN' />;
					    	</div>)

						// 1x1
				    case 2:
							return(
								<div key={key} className="cell small-12 medium-12 large-12">
					    		<ProductDisplay product={product} truncate='NONE' />;
					    	</div>)

						// unknown - should never happen ?
				    default:
							return (<div key={key}></div>)

				  }

				})}

			</div>
		);
	}

}

// Set the default values
ProductSearchList.defaultProps = {
  searchText: reduxStore.getState().searchText
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        searchText: state.searchText
    }
}

export default connect(mapStateToProps)(ProductSearchList);
