// Use ES6 Class

import React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
  This is the best I could do with `react-router-dom`
*/

const GoBack = ({ history }) => <FontAwesomeIcon icon="arrow-circle-left" className="protip pointer highlight-colour"
  size="3x" data-pt-position="right"
  data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Previous page"
  onClick={() => history.goBack()} alt="Go back" />;

export default withRouter(GoBack);
