// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';

import FeaturedProducts from 'FeaturedProducts';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';
import ReactPixel from 'react-facebook-pixel';
import MySelect from 'MySelect';

// Animations if required
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class CMSPreview extends React.Component {

	componentDidMount() {

		// Scroll Top after render() - same as a jQuery Doc Ready
	  window.scrollTo(0, 0);

	}

	render () {

		return (
			<div className="flex-site">
				<IPLog URL="/#/" />
				<div className="site-content">
						<Nav />
						<MegaMenu />
						<ProductSearch />

						<div className="grid-x grid-margin-x">

							{/*
											*********************
													P R E V I E W
											*********************
							*/}

							<div className="cell small-10 small-offset-1">
									<div className="larger-text center">
										<Translate text="This is a preview of the CMS Block" />&nbsp;
										<span className="bold">{this.props.CMS}</span>
										<br />
										<span className="small-text">
											<Translate text="There are side margins on this preview" />
										</span>
									</div>
									<br />
									<CMSBlock blockTitle={this.props.CMS} className="" />
									<br />
							</div>

						</div>
				</div>
				<Footer />
				<ScrollTop />
			</div>
		);
	}

}

// Set the default values
CMSPreview.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(CMSPreview);
