// Use ES6 Class

import React from 'react';
import CMSImage from 'CMSImage';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

class AnimateCard extends React.Component {

  constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
            showFront : true
        };
	}

  mouseOver (e) {
    this.setState( { showFront: false } );
  }

  mouseAway (e) {
    this.setState( { showFront: true } );
  }

  onLoadFrontImage () {
    // Calculate the height of the container as the first image; best we can do really
    // This should be enhanced to make this FULLY responsive, i.e. recalc on window resize()
    var _container =  $('#'+this.props.id).height();
    $('#'+this.props.id+'Container').height(_container);
  }

  render () {
    {/*

      We need to hold the front and back-images in a container to avoid flicker
      I am using Fade as the animation Style but choose from the above Fade, Zoom Slide.
      There are more options in react-reveal, but too gimmky for my liking....

    */}
    return (
      <div id={this.props.id+'Container'}>
        {this.state.showFront &&
          <Fade top duration={1000}><CMSImage id={this.props.id} imageName={this.props.front}
            onMouseEnter={(e) => this.mouseOver(e)} onMouseLeave={(e) => this.mouseAway(e)}
            onLoad={(e) => this.onLoadFrontImage(e)}
            /></Fade> }
        {!this.state.showFront &&
          <Fade bottom duration={1000}><CMSImage imageName={this.props.back}
            onMouseEnter={(e) => this.mouseOver(e)} onMouseLeave={(e) => this.mouseAway(e)}
            /></Fade> }
      </div>
    )
  }

}

export default AnimateCard;
