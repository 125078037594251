// Use ES6 Class   

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import CMSBlock from 'CMSBlock';
import Lightbox from 'react-image-lightbox';

/*
	Props are: same as for an normal <img> tag

	Displays an image of `imageName` from the CMS Media Store. While this might seem an
	extreme case for using an API, it insulates the front-end from the media-library

*/

class CMSImage extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	imageURL : '',
					_showLightBox: false,
					_cursor: ''
        };
	}

	componentDidMount () {

		this._isMounted = true;

		if ( this.props.lightbox && this._isMounted)
			this.setState( {_cursor: 'zoom-in'} );

		// Load initial API Data
		var _data = { mediaURL: this.props.imageName };
		MyFetchPost( process.env.API + '/cms/getimage', _data, 5)
	    	.then( response => response.json() )
		    .then( apiImageURL => {
						if (this._isMounted)
							this.setState( {imageURL : apiImageURL} );
						})
						.catch( /* It should retry !! */ )

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	onClickImage (evt) {
		if (this.props.lightbox) {
			this.setState( {_showLightBox : true} );
			console.log(this.state.imageURL);
		}
	}

	closeLightbox (evt) {
		this.setState( {_showLightBox : false} );
	}

	render () {

		if ( _.isEmpty( this.state.imageURL) )  return (<div></div>);

		return (
			<div>
				{ this.state._showLightBox &&
					<Lightbox mainSrc={this.state.imageURL}
						onCloseRequest={(evt) => this.closeLightbox(evt)} /> }

				<div style={{ position: 'relative' }}>
						<img src={this.state.imageURL}
							id={this.props.id}
							className={this.props.className}
							style={ {cursor: this.state._cursor} }
							width={this.props.width}
							height={this.props.height}
							onMouseEnter={this.props.onMouseEnter}
							onMouseLeave={this.props.onMouseLeave}
							onDragStart={this.props.onDragStart}
							onLoad={this.props.onLoad}
							onClick={this.onClickImage.bind(this)}
						/>
					<CMSBlock blockTitle={this.props.content} />
				</div>

			</div>
		);
	}

}

export default CMSImage;
