// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import CMSBlock from 'CMSBlock';
import ProductDisplay from 'ProductDisplay';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Display all the featured products ---
*/

class FeaturedProducts extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	productsData : []
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
			var APIUrl = process.env.API + '/product/featured';
			MyFetchGet(APIUrl, 10)
			    .then( response => response.json() )
			    .then( apiProductsData => {
						if (this._isMounted)
							this.setState( {productsData : apiProductsData} );
			    })
					.then( () => {
							this._isMounted = false;
					})
					.catch( /* Should try again */ );
		}

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		// We don't display empty lists !
		if ( !this.state.productsData.length ) return (<div></div>);

		return (

			<div className="grid-x grid-margin-x">

						{/* Small line break to clear grid */}
						<div className="cell small-12 small-text">&nbsp;</div>

						<div className="cell small-1"></div>

						<div className="cell auto">
							<div className="grid-x grid-margin-x">
								{this.state.productsData.map( (product, key) => {

											return(
												<div key={key} className="cell small-12 medium-6 large-4">
									    		<ProductDisplay product={product} truncate='MAX' />;
									    	</div>)

								})}
							</div>

						</div>
						<div className="cell small-1"></div>

			</div>

		);
	}

}

// Set the default values
FeaturedProducts.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(FeaturedProducts);
