// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';

class NavMenuMobile extends React.Component {

  constructor(props, defaultProps) {
    super(props, defaultProps);
    this.state = {
        _menu: []
    };
	}

  componentDidMount() {

    this._isMounted = true;

    var APIUrl = process.env.API + '/cms/getmenu';

    MyFetchGet( APIUrl, 3)
        .then( response => response.json() )
        .then( apiDataValues => {
          if (this._isMounted)
            this.setState( { _menu: apiDataValues } );
        })
        .catch( /* It should retry !! */ )

  }

  componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

    if ( _.isEmpty(this.state._menu) )	return (<div></div>);

		return(

      <div className="slide-in-page-from-right">

        <div className="right-hand-side-close">
          <FontAwesomeIcon icon="window-close" size="3x" color="grey"
              onClick={evt => this.props.onClose(evt)} />
        </div>

        <ul className="">

              {this.state._menu.map( (option, key) => {

                  return (
                    <li key={key} className="mobile-li-item">
                      <Link to={"/page/"+option.title}>
                        <span className="navigation-menu-item">
                          <Translate text={option.title} />
                        </span>
                      </Link>
                    </li>
                  )
                })}

        </ul>

      </div>

		);
	}

}

export default NavMenuMobile;
