// Use ES6 Class

import React from 'react';
import CMSImage from 'CMSImage';
import Fade from 'react-reveal/Fade';

class FlipPhoto extends React.Component {

  constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
            _frontImage: this.props.front || '',
            _flipImage: this.props.back || '',
            _effect: this.props.effect || 'Slide',
            _id: this.props.id || 'FlipPhoto',
            showFront : true
        };
	}

  mouseOver (e) {
      this.setState( { showFront: false } );
  }

  mouseAway (e) {
      this.setState( { showFront: true } );
  }

  onClickImage (e) {
      // Callback
  }

  onLoadFrontImage () {
    // Calculate the height of the container as the first image; best we can do really
    // This should be enhanced to make this FULLY responsive, i.e. recalc on window resize()
    var _container =  $('#'+this.props.id).height();
    $('#'+this.props.id+'Container').height(_container);
  }

  render () {

    {/*

      We need to hold the front and back-images in a container to avoid flicker
      I am using Slide as the animation Style but choose from the above Slide.

      There are more options in react-reveal, but too gimmky for my liking....

    */}

    return (

      <div id={this.props.id+'Container'} className="flip-photo-container">

      {/* Fade */}

          { this.state.showFront &&
            <div onClick={this.props.onClick}>
              <Fade left duration={2000}>
                <img
                  id={this.props.id}
                  src={this.props.front}
                  onMouseEnter={(e) => this.mouseOver(e)} onMouseLeave={(e) => this.mouseAway(e)}
                  onLoad={(e) => this.onLoadFrontImage(e)}
                />
              </Fade>
            </div>
          }

          { !this.state.showFront &&
            <div onClick={this.props.onClick}>
              <Fade right duration={2000}>
                <img
                  src={this.props.back}
                  onMouseEnter={(e) => this.mouseOver(e)} onMouseLeave={(e) => this.mouseAway(e)}
                />
              </Fade>
            </div>
          }


      </div>

    )
  }

}

export default FlipPhoto;
