// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import ProductSearch from 'ProductSearch';

import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);


class PaymentPromised extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        };
	}

	componentDidMount () {

		// Scroll Top after render() - same as a jQuery Doc Ready
	  window.scrollTo(0, 0);

		this._isMounted = true;

		// Clear current Cart
    setReduxValue(reduxStore, 'SET_CART_ID', 0 );
		setReduxValue(reduxStore, 'SET_CART_VALUE', 0 );
		setReduxValue(reduxStore, 'SET_ITEMS_IN_CART', 0 )
    var action1 = {
        type: 'SET_CART_ID',
        cartID: 0
    };
		var action2 = {
        type: 'SET_CART_VALUE',
        cartValue: 0
    };
		var action3 = {
        type: 'SET_ITEMS_IN_CART',
        itemsInCart: 0
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
		this.props.dispatch(action2);
		this.props.dispatch(action3);
	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {
		return (
			<div className="flex-site">
				<div className="site-content">

						<Nav />
						<MegaMenu />
						<ProductSearch />

						<div className="page-title text-center no-select">
							Payment Promised { this.props.__currencySymbol + ' ' + Intl.NumberFormat('en-US', { minimumFractionDigits:2 })
								.format(this.props.AMOUNT)}
						</div>

						<br />
						<div className="grid-x grid-margin-x">

								<div className="cell small-8 small-offset-2 center">
										<p>
											We've dropped you an eMail to confirm your <b>promise of payment</b>. Please note that you can
											review and track your order[s] at any time with the above <b>History</b> button
											<em>- on the top menu</em>
										</p>
										<br /><br />
								</div>

						</div>

				</div>

				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

// Set the default values
PaymentPromised.defaultProps = {
		__currencySymbol: reduxStore.getState().__currencySymbol,
    cartID: reduxStore.getState().cartID
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
				__currencySymbol: state.__currencySymbol,
        cartID: state.cartID
		}
}

export default connect(mapStateToProps)(PaymentPromised);
