// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import MediaDisplay from 'MediaDisplay';
import Translate from 'Translate';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
	--- Display all the user's Media products  ---
	userID from R E D U X
*/

class CustomerMedia extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
        	mediaData : []
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
			var APIUrl = process.env.API + '/customer/media/' + this.props.userID;
			MyFetchGet(APIUrl, 3)
			    .then( response => response.json() )
			    .then( apiMediaData => {
								if ( this._isMounted)
										this.setState( {mediaData : apiMediaData} );
			    })
					.then( () => {

					})
					.catch( /* It should retry !! */ )
		}

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		return (
			<div>

					<div className="page-title highlight-colour text-center">
						<Translate text="My Media Store" />
						<br />&nbsp;
					</div>

					<div className="grid-x grid-margin-x">

						{this.state.mediaData.map( (media, key) => {

							switch(this.props.displayGrid) {

								// 3x3
								case 0:
									return(
										<div key={key} className="cell small-12 medium-6 large-4">
							    		<MediaDisplay media={media} />
							    	</div>)

								// 2x2
								case 1:
									return(
										<div key={key} className="cell small-12 medium-6 large-6">
							    		<MediaDisplay media={media} />
							    	</div>)

								// 1x1
						    case 2:
									return(
										<div key={key} className="cell small-12 medium-12 large-12">
							    		<MediaDisplay media={media} />
							    	</div>)

								// unknown - should never happen ?
						    default:
									return (<div key={key}></div>)

						  }

						})}

					</div>

			</div>
		);
	}

}

// Set the default values
CustomerMedia.defaultProps = {
  userID: reduxStore.getState().userID
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        userID: state.userID
    }
}

export default connect(mapStateToProps)(CustomerMedia);
