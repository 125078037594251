// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Iframe from 'Iframe';

class GoogleMap extends React.Component {

  constructor(props, defaultProps) {
    super(props, defaultProps);
    this.state = {
        GoogleURLValue: '',
    };
	}

  componentDidMount() {

    this._isMounted = true;

    var APIUrl = process.env.API + '/setting/' + 'GOOGLE-MAP-URL';

    MyFetchGet( APIUrl, 3)
        .then( response => response.json() )
        .then( apiDataValue => {
          if (this._isMounted)
            this.setState( { GoogleURLValue: apiDataValue } );
        })
        .catch( /* It should retry !! */ )

  }

  componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

    if ( _.isEmpty(this.state.GoogleURLValue) )	return (<div></div>);

    // We could make height a prop, but honestly for home-page maps 300px is fine
    // and just click for full screen if that's what you want; as a /dev you could update this
    // as height={this.props.height} as then use <GoogleMap height="300px" />

		return(
			<div>
        <Iframe src={this.state.GoogleURLValue} width="100%" height="300px" />
			</div>
		);
	}

}

export default GoogleMap;
