// Use ES6 Class

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- Select Locale ---
*/


class SelectLocale extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
        _locales: null,
        _refreshPage: false
      };
  }

	componentDidMount() {

    this._isMounted = true;

    // Load initial API Data
		if (this._isMounted) {
			MyFetchGet(process.env.API + '/locales', 3)
			    .then( response => response.json() )
			    .then( locales => {
						if (this._isMounted)
							this.setState( {_locales : locales} );
			    })
					.catch( /* Should try again */ );
		}


	}

  componentWillUnmount () {
		// Cancel any services to stop memory issues
		this._isMounted = false;
  }

  closeSelectLocale () {

        setReduxValue(reduxStore, 'SET_SLIDE_WINDOW', false );
        var action1 = {
            type: 'SET_SLIDE_WINDOW',
            slideWindow: false
        };
        // Note that we apply the dispatch to the props: mapStateToProps(state)
		    this.props.dispatch(action1);

  }

  onChangeLocale (e) {

    // Update REDUX with the new hard-data values
    setReduxValue(reduxStore, 'SET_LOCALE', e.target.value );
    var action1 = {
        type: 'SET_LOCALE',
        locale: e.target.value
    };

    // Note that we apply the dispatch to the props: mapStateToProps(state)
    this.props.dispatch(action1);

    this.setState( {_refreshPage : true} );

  }

	render () {

    if (this.state._refreshPage) {
			return (<Redirect to='/changedlocale' push />);
		}

    if ( _.isEmpty(this.state._locales) ) return(<div></div>);

        return (
          <div className="slide-in-page-from-right">

              <div className="right-hand-side-close">
                <FontAwesomeIcon icon="window-close" size="3x" color="grey" className="protip" data-pt-position="left"
                data-pt-scheme="black" data-pt-classes="my-tooltip" data-pt-title="Close"
                    onClick={evt => this.closeSelectLocale(evt)} />
              </div>

            <div className="page-title black">Select Language</div>

            <select value={this.props.locale} onChange={ evt => this.onChangeLocale(evt) }>

                    {this.state._locales.map( (language, key) => {

                      return <option key={key} value={language.value} >
                        {language.name}
                      </option>;
                    })}

            </select>

          </div>
        );

	}

}

// Set the default values
SelectLocale.defaultProps = {
    locale: reduxStore.getState().locale,
    slideWindow: reduxStore.getState().slideWindow
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
        locale: state.locale,
        slideWindow: state.slideWindow
    }
}

export default connect(mapStateToProps)(SelectLocale);
