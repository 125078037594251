// ES6 or 7! using async await

// Currently requires a Babel Polyfill which is just in webpack,
// but of no concern to this Component as it assumes that await is JS feature

// Await has fiddly error handling

export const MyFetchGet = async (url, n) => {
    try {
        return await fetch(url)
        .then( (response) => {
              if ( response.status >= 400 && response.status < 600 ) {
                throw new Error('Server ' + response.status + ' | Retry');
              }
              return response;
        })
    } catch(err) {
        console.log( err );
        if (n === 1) throw err;
        return await MyFetchGet(url, n - 1);
    }
};

export const MyFetchPost = async (url, data, n) => {
    try {
        return await fetch(url, {
          method: "post",
          headers: { "Content-Type": "application/json; charset=utf-8" },
          body: JSON.stringify(data)
        })
        .then( (response) => {
              if ( response.status >= 400 && response.status < 600 ) {
                throw new Error('Server ' + response.status + ' | Retry');
              }
              return response;
        })
    } catch(err) {
        console.log( err );
        if (n === 1) throw err;
        return await MyFetchPost(url, data, n - 1);
    }
};

export const MyFetchPostWithHeader = async (url, data, _header, n) => {

    try {
        return await fetch(url, {
          method: "post",
          headers: _header,
          body: JSON.stringify(data)
        })
        .then( (response) => {
              if ( response.status >= 400 && response.status < 600 ) {
                throw new Error('Server ' + response.status + ' | Retry');
              }
              return response;
        })
    } catch(err) {
        console.log( err );
        if (n === 1) throw err;
        return await MyFetchPostWithHeader(url, data, _header, n - 1);
    }
};
