// Use ES6 Class

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import ProductSearch from 'ProductSearch';
import ProductListByClass from 'ProductListByClass';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

/*
  --- CORE PRODUCTS PAGE [BY top level Class]---
  	--- This is only used on Custom Pages ---
  Props are:
	CLASS_KEY		The parent product Class
*/


class ProductsByClass extends React.Component {

  constructor(props, defaultProps) {
      super(props, defaultProps);
      this.state = {
        displayGrid: localStorage.getItem('displayGrid') || 1,
        _stateClassKey: props.CLASS_KEY
      };

      // Bind the listeners of my f()s to this class....
      this.displayGrid = this.selectDisplayGrid.bind(this);
  }

  //
	//	In line with new React V17 and stability, we now use getDerivedStateFromProps () using a state flag
	//	as above and componentDidUpdate () replaces componentDidMount () - this is much better
	//	because it stops the duplication of the API load or code in the old componentWillReceiveProps ()
	//

  // Note that this must be static
	static getDerivedStateFromProps(props, state) {
	    if (props.CLASS_KEY !== state._stateClassKey) {

					return {
		        _stateClassKey: props.CLASS_KEY
		      };
	    }

	    // No state update necessary
	    return null;
	}

  // Used as a handler for the `Display Grid` option
	selectDisplayGrid(e, _grid) {
			this.setState( { displayGrid: _grid } );
	}

  // Scroll Top after render()
	componentDidMount () {

    this._isMounted = true;

	  window.scrollTo(0, 0);

    // Non dynamically-responsive by design, but default to a full-desktop layout if width() >= 1024px
    if ( $(window).width() >= 1024 ) {
        if (this._isMounted)
          this.setState( { displayGrid: 0 } );
    } else {
      if (this._isMounted)
        this.setState( { displayGrid: 1 } );
    }

    // Ditto for mobile
    if ( $(window).width() <= 640 ) {
        if (this._isMounted)
          this.setState( { displayGrid: 2 } );
    }

	}

  componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		return (
			<div className="flex-site">
        <div className="site-content">
    				<Nav />
            <MegaMenu />
            <ProductSearch />

            <div className="text-center">

              {/* Grid layout controls or options - these will auto adjust */}
              <div className="grid-x grid-margin-x not-mobile">
      					<div className="cell small-12">

                  <FontAwesomeIcon icon="th" size="2x" color="grey" className="pointer protip"
                    data-pt-position="left" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Desktop grid"
                    onClick={ evt => this.selectDisplayGrid(evt,0) } />

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="th-large" size="2x" color="grey" className="pointer protip"
                    data-pt-position="bottom" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="Block grid"
                    onClick={ evt => this.selectDisplayGrid(evt,1) } />

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="grip-lines" size="2x" color="grey" className="pointer protip"
                    data-pt-position="right" data-pt-scheme="black" data-pt-classes="my-tooltip"
                    data-pt-title="List"
                    onClick={ evt => this.selectDisplayGrid(evt,2) } />

                </div>
                <div className="cell small-12">&nbsp;</div>
      				</div>
            </div>

            {/* Display all the products with the selected Class */}
    				<div className="grid-x grid-margin-x">
    					<div className="cell small-1"></div>
    					<div className="cell auto">
                {/* List all the products matching the Class state with the dynamic displayGrid */}
    						<ProductListByClass selectedProductClass={this.props.CLASS_KEY} displayGrid={this.state.displayGrid}/>
    					</div>
    					<div className="cell small-1"></div>
    				</div>

        </div>

				<Footer />
        <ScrollTop />
			</div>
		);
	}

}

// Set the default values
ProductsByClass.defaultProps = {

};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(ProductsByClass);
