
// ------------------- R E D U X --------------------
// Use old-school require with REDUX
var redux = require('redux');
import _ from 'lodash';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';   // defaults to localStorage for web

//
//  REDUX exported methods
//

// REDUX-state-STORE update or `reducer` as REDUX likes to call it
var reduxReducer = (state, action) => {

  // console.log( state );

  state = state || {
    userID: 0,
    userEmail: '',
    userName: '',
    userApproved: 0,
    cartID: 0,
    cartValue: 0.00,
    itemsInCart: 0,
    searchText: '',
    slideWindow: false,
    redirectToCheckout: false,
    showPopup: 0,
    locale: 'en',
    // System Settings are prefixed with an __
    __preferProductImagesAbove: 0,
    __VATrate: 0,
    __currencyCode: 'GBP',
    __currencySymbol: '&pound;',
    __mediaStore: 0,
    __multiLanguage: 0,
    __translateAPIKey: '',
    __allowCoupons: 1,
    __showProductMatches: 0,
    __invitationOnly: 0,
    __productPriceKey: ''
  };

  /*  the ... is an ES6 spread which means: include this object and any updates - neat */

  switch (action.type) {

    case 'SET_USER_ID' :
      if (_.isNumber(action.userID))
        return { ...state, userID: action.userID };

    case 'SET_USER_EMAIL' :
      if (action.userEmail != null)
        return { ...state, userEmail: action.userEmail };

    case 'SET_USER_NAME' :
      if (action.userName != null)
        return { ...state, userName: action.userName };

    case 'SET_USER_APPROVED' :
      if (action.userApproved != null)
        return { ...state, userApproved: action.userApproved };

    case 'SET_CART_ID' :
      if (_.isNumber(action.cartID))
        return { ...state, cartID: action.cartID };

    case 'SET_CART_VALUE' :
      if (_.isNumber(action.cartValue))
        return { ...state, cartValue: action.cartValue };

    case 'SET_ITEMS_IN_CART' :
      if (_.isNumber(action.itemsInCart))
        return { ...state, itemsInCart: action.itemsInCart };

    case 'SET_SEARCH_TEXT' :
      if (action.searchText != null)
        return { ...state, searchText: action.searchText };

    case 'SET_SLIDE_WINDOW' :
      if (action.slideWindow != null)
        return { ...state, slideWindow: action.slideWindow };

    case 'SET_REDIRECT_TO_CHECKOUT' :
      if (action.redirectToCheckout != null)
        return { ...state, redirectToCheckout: action.redirectToCheckout };

    case 'SET_DELIVERY_ADDRESS' :
      if (action.deliveryAddress != null)
        return { ...state, deliveryAddress: action.deliveryAddress };

    case 'SET_DELIVERY_POSTCODE' :
      if (action.deliveryPostcode != null)
        return { ...state, deliveryPostcode: action.deliveryPostcode };

    case 'SET_LOCALE' :
      if (action.locale != null)
        return { ...state, locale: action.locale };

    case 'SET_POPUP' :
      if (action.showPopup != null)
        return { ...state, showPopup: action.showPopup };

    // __ System

    case 'INIT_FRONT-END-DISPLAY-IMAGE-ABOVE' :
      if (action.__preferProductImagesAbove != null)
        return { ...state, __preferProductImagesAbove: action.__preferProductImagesAbove };

    case 'INIT_VAT-RATE' :
      if (action.__VATrate != null)
        return { ...state, __VATrate: action.__VATrate };

    case 'SET_CURRENCY-CODE' :
      if (action.__currencyCode != null)
        return { ...state, __currencyCode: action.__currencyCode };

    case 'SET_CURRENCY-SYMBOL' :
      if (action.__currencySymbol != null)
        return { ...state, __currencySymbol: action.__currencySymbol };

    case 'SET_MULTI-LANGUAGE' :
      if (action.__multiLanguage != null)
        return { ...state, __multiLanguage: action.__multiLanguage };

    case 'SET_TRANSLATE-API-KEY' :
      if (action.__translateAPIKey != null)
        return { ...state, __translateAPIKey: action.__translateAPIKey };

    case 'SET_MEDIA-STORE' :
      if (action.__mediaStore != null)
        return { ...state, __mediaStore: action.__mediaStore };

    case 'SET_ALLOW-COUPONS' :
      if (action.__allowCoupons != null)
        return { ...state, __allowCoupons: action.__allowCoupons };

    case 'SET_SHOW-PRODUCT-MATCHES' :
      if (action.__showProductMatches != null)
        return { ...state, __showProductMatches: action.__showProductMatches };

    case 'SET_INVITATION-ONLY' :
      if (action.__invitationOnly != null)
        return { ...state, __invitationOnly: action.__invitationOnly };

    case 'SET_PRODUCT-PRICE-KEY' :
      if (action.__productPriceKey != null)
        return { ...state, __productPriceKey: action.__productPriceKey };

    default :
      return state;
  }
}

// To create a Store which is persisant, i.e cookie-like
var persistConfig = {
  key: 'root',
  storage,
};
var persistedReducer = persistReducer(persistConfig, reduxReducer);

export function createReduxStore() {
  return redux.createStore( persistedReducer );   /* was just reduxReducer before persist */
}

// To set a State value or `action` as REDUX would say
export function setReduxValue(reduxStore, type, value) {

  switch( type ) {

    case 'SET_USER_ID':
      var setStoreValue = { type: 'SET_USER_ID', userID: value }
      break;

    case 'SET_USER_EMAIL':
      var setStoreValue = { type: 'SET_USER_EMAIL', userEmail: value }
      break;

    case 'SET_USER_NAME':
      var setStoreValue = { type: 'SET_USER_NAME', userName: value }
      break;

    case 'SET_USER_APPROVED':
      var setStoreValue = { type: 'SET_USER_APPROVED', userApproved: value }
      break;

    case 'SET_CART_ID':
      var setStoreValue = { type: 'SET_CART_ID', cartID: value }
      break;

    case 'SET_CART_VALUE':
      var setStoreValue = { type: 'SET_CART_VALUE', cartValue: value }
      break;

    case 'SET_ITEMS_IN_CART':
      var setStoreValue = { type: 'SET_ITEMS_IN_CART', itemsInCart: value }
      break;

      case 'SET_SEARCH_TEXT':
      var setStoreValue = { type: 'SET_SEARCH_TEXT', searchText: value }
      break;

    case 'SET_SLIDE_WINDOW':
      var setStoreValue = { type: 'SET_SLIDE_WINDOW', slideWindow: value }
      break;

    case 'SET_REDIRECT_TO_CHECKOUT':
      var setStoreValue = { type: 'SET_REDIRECT_TO_CHECKOUT', redirectToCheckout: value }
      break;

    case 'SET_DELIVERY_ADDRESS':
      var setStoreValue = { type: 'SET_DELIVERY_ADDRESS', deliveryAddress: value }
      break;

    case 'SET_DELIVERY_POSTCODE':
      var setStoreValue = { type: 'SET_DELIVERY_POSTCODE', deliveryPostcode: value }
      break;

    case 'SET_LOCALE' :
      var setStoreValue = { type: 'SET_LOCALE', locale: value }
      break;

    case 'SET_POPUP' :
      var setStoreValue = { type: 'SET_POPUP', showPopup: value }
      break;

      // __ System

    case 'INIT_FRONT-END-DISPLAY-IMAGE-ABOVE' :
      var setStoreValue = { type: 'INIT_FRONT-END-DISPLAY-IMAGE-ABOVE', __preferProductImagesAbove: value }
      break;

    case 'INIT_VAT-RATE' :
      var setStoreValue = { type: 'INIT_VAT-RATE', __VATrate: value }
      break;

    case 'SET_CURRENCY-CODE' :
      var setStoreValue = { type: 'SET_CURRENCY-CODE', __currencyCode: value }
      break;

    case 'SET_CURRENCY-SYMBOL' :
      var setStoreValue = { type: 'SET_CURRENCY-SYMBOL', __currencySymbol: value }
      break;

    case 'SET_MULTI-LANGUAGE' :
      var setStoreValue = { type: 'SET_MULTI-LANGUAGE', __multiLanguage: value }
      break;

    case 'SET_TRANSLATE-API-KEY' :
      var setStoreValue = { type: 'SET_TRANSLATE-API-KEY', __translateAPIKey: value }
      break;

    case 'SET_MEDIA-STORE' :
      var setStoreValue = { type: 'SET_MEDIA-STORE', __mediaStore: value }
      break;

    case 'SET_ALLOW-COUPONS' :
      var setStoreValue = { type: 'SET_ALLOW-COUPONS', __allowCoupons: value }
      break;

    case 'SET_SHOW-PRODUCT-MATCHES' :
      var setStoreValue = { type: 'SET_SHOW-PRODUCT-MATCHES', __showProductMatches: value }
      break;

    case 'SET_INVITATION-ONLY' :
      var setStoreValue = { type: 'SET_INVITATION-ONLY', __invitationOnly: value }
      break;

    case 'SET_PRODUCT-PRICE-KEY' :
      var setStoreValue = { type: 'SET_PRODUCT-PRICE-KEY', __productPriceKey: value }
      break;

    default:
      var setStoreValue = { type: '' }
      break

  }

  reduxStore.dispatch( setStoreValue );

}
